import cartShipping from 'dataAccess/api/cart.shipping.ts';
import shippingUtils from 'utils/shippingMethods';
import CartService from 'dataAccess/api/cart.lineItem.ts';

const correctShippingForBase = async (cart) => {
  const updatedShippingMethods = shippingUtils.getCurrentShippingMethods(cart);
  updatedShippingMethods.forEach((method) => {
    if (method.needsRemoval === true) {
      // eslint-disable-next-line no-param-reassign
      method.needsRemoval = false;
    }
  });
  const result = await cartShipping.setShippingMethods(updatedShippingMethods, cart.id);
  return result;
};

const saveChanges = async ({ cart, product, selectedVariant, setCart, setLoading, setMessage }) => {
  try {
    setLoading(true);
    const itemToRemove = cart?.lineItems.find(
      (lineItem) => lineItem.variant.sku === product.variant.sku,
    );
    const quantityToReplace = itemToRemove.quantity;
    // SUNSET(Adrian Rivera): This function fixes the issue we were having with the remove mattress selection on the smart bases but should be fixed in the future within the cart service.
    const isFinalSale = itemToRemove?.custom?.fields?.final_sale;
    const isFloorModel = itemToRemove?.custom?.fields?.floor_model;
    await CartService.removeItemFromCart(cart?.id, itemToRemove.id);
    const channelKey = itemToRemove?.distributionChannel?.obj?.key ?? null;
    const params = {
      cartId: cart?.id,
      sku: selectedVariant.sku,
      quantity: quantityToReplace,
      channelKey,
    };
    let updatedCart = await CartService.addItemToCart(params);

    const newLineItem = updatedCart?.lineItems.find(
      (lineItem) => lineItem?.variant?.sku === selectedVariant.sku,
    );
    if (isFinalSale) {
      updatedCart = await CartService.markLineItemAsFinalSale(updatedCart.id, newLineItem.id, true);
    }
    if (isFloorModel) {
      updatedCart = await CartService.markLineItemAsFloorModel(
        updatedCart.id,
        newLineItem.id,
        true,
      );
    }
    setCart(updatedCart);
  } catch (error) {
    setLoading(false);
    setMessage(error.message);
    alert('changes not saved to cart');
  } finally {
    setLoading(false);
  }
};

export { correctShippingForBase, saveChanges };
