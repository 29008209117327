const getDiscountCodes = (cart) => {
  const discountCodesArray = new Set(); // Use a Set to automatically handle duplicates
  const discountCodesIdSet = new Set(); // Use a Set to store ids

  cart?.lineItems?.forEach((lineItem) => {
    let discounts = [];
    try {
      discounts = lineItem?.custom?.fields?.discounts_json
        ? JSON.parse(lineItem?.custom?.fields?.discounts_json)
        : [];
    } catch (error) {
      discounts = [];
    }

    // only keep discount code from discounts
    discounts.forEach((item) => {
      if (!discountCodesIdSet.has(item?.id)) {
        discountCodesArray.add({
          id: item?.id,
          display_name: item?.display_name ?? 'No Name Found',
        });
        discountCodesIdSet.add(item?.id);
      }
    });
  });

  return [...discountCodesArray]; // Convert Set back to an array
};

export default getDiscountCodes;
