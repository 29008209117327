import haversine from 'utils/haversineCalc';
import storeLocationUtil from 'utils/storeLocation';
import clover from 'utils/clover';

const getClosestStore = async (storeList, setLoading) => {
  if (storeList) {
    try {
      const usersLocation = await storeLocationUtil.getLocation();
      let closest = null;
      let bestLocation = null;
      // Find closest store to users location
      storeList.forEach((store) => {
        const geoCoordinates = store.supplyChannels[0]?.obj.geoLocation?.coordinates;
        const geoCoordObj = {
          latitude: geoCoordinates[1],
          longitude: geoCoordinates[0],
        };

        // Calculate distance between geocoordinates
        const distance = haversine(geoCoordObj, usersLocation.coords);
        if (closest == null || distance < closest) {
          bestLocation = store;
          closest = distance;
        }
      });
      setLoading(false);
      return bestLocation;
    } catch (err) {
      // Return null if getting location returns an error (e.g. user denied sharing location)
      setLoading(false);
      return null;
    }
  }
  // Should not get hit but return null if store locations list is empty
  setLoading(false);
  return null;
};

const getStore = async (storeList, setLocationLoading) => {
  // Get store data from session storage
  try {
    const existingStore = await storeLocationUtil.getLocationStorage();
    if (existingStore) {
      setLocationLoading(false);
      return existingStore;
    }

    // Find closest store location
    const closestStore = await getClosestStore(storeList, setLocationLoading);
    if (closestStore) {
      setLocationLoading(false);
      return closestStore;
    }

    setLocationLoading(false);
    return null;
  } catch (err) {
    setLocationLoading(false);
    // Default to null
    return null;
  }
};

const populateStoreList = async (setStoreList, setLoading) => {
  try {
    const list = await storeLocationUtil.getStoreLocationsList();
    if (list) {
      setStoreList(list);
    }
  } catch (err) {
    setLoading(false);
  }
};

const updateSelectedStore = (store, setSelectedStore) => {
  storeLocationUtil.setLocationStorage(store);
  clover.updateCloverAccessToken({ currentStoreKey: store.key });
  setSelectedStore(store);
};

const selectStore = async ({
  storeList,
  setLocationLoading,
  setOpenModal,
  setLoading,
  setSelectedStore,
}) => {
  const foundStore = await getStore(storeList, setLocationLoading);

  if (foundStore) {
    updateSelectedStore(foundStore, setSelectedStore);
  } else {
    setOpenModal(true);
  }

  setLoading(false);
  setLocationLoading(false);
};

export default { getClosestStore, getStore, populateStoreList, selectStore, updateSelectedStore };
