import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Alert,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import FullWidth from 'components/layouts/FullWidth';
import cloverUtils from 'utils/clover';
import { copyText } from 'language';

const CloverAccounts = () => {
  const [formData, setFormData] = useState({ users: '', mid: '' });
  const [creationResults, setCreationResults] = useState([]);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [storeMID, setStoreMID] = useState(null);

  const formatRole = (role) => {
    const agentReg = /agent|employee/i;
    const managerReg = /manager/i;

    if (agentReg.test(role)) {
      return 'EMPLOYEE';
    }

    if (managerReg.test(role)) {
      return 'MANAGER';
    }

    return null;
  };

  // SUNSET: Move to endpoints
  const addUser = async ({ name, email, mid, role }) => {
    const tokenStorage = cloverUtils.getCloverAccessToken();

    if (tokenStorage) {
      try {
        const formattedRole = formatRole(role);
        const token = tokenStorage?.token;
        const url = `${process.env.REACT_APP_CLOVER_API}/v3/merchants/${mid}/employees`;

        const reqBody = {
          name,
          email,
          role: formattedRole,
        };
        const config = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };

        const resp = await axios.post(url, reqBody, config);
        return resp;
      } catch (err) {
        return err;
      }
    }
    throw new Error('No clover token found');
  };

  const updateForm = (e) => {
    const value = e.target.value;
    const key = e.target.getAttribute('name');

    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateFields = ({ name, email, role }) => {
    const nameRegex = /^[a-zA-Z\s']+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const roleRegex = /manager|employee|agent/i;

    if (!nameRegex.test(name)) {
      setOpenSnack(true);
      setSnackMessage(`${copyText.Employees.admin.invalidName}: ${name}`);
      return false;
    }
    if (!emailRegex.test(email)) {
      setOpenSnack(true);
      setSnackMessage(`${copyText.Employees.admin.invalidEmail}: ${email}`);
      return false;
    }
    if (!roleRegex.test(role)) {
      setOpenSnack(true);
      setSnackMessage(`${copyText.Employees.admin.invalidRole}: ${role}`);
      return false;
    }
    return true;
  };

  const formatUserList = (users) => {
    const lines = users.split('\n');
    const formattedData = lines
      .map((line) => {
        const [name, email, role] = line.split(', ');
        const validated = validateFields({ name, email, role });
        if (validated) {
          return { name, email, role };
        }
        return undefined;
      })
      .filter((item) => {
        return item !== undefined;
      });

    return formattedData;
  };

  const addUsers = async (userData, mid) => {
    const promises = [];
    const users = formatUserList(userData);

    users.forEach((user) => {
      const promise = addUser({ name: user.name, email: user.email, mid, role: user.role })
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          // LEAVE FOR TROUBLESHOOTING
          // eslint-disable-next-line no-console
          console.error(`Failed to add user ${user.email}: ${err.message}`);
          return err;
        });
      promises.push(promise);
    });

    const results = await Promise.all(promises);
    // LEAVE FOR TROUBLESHOOTING
    // eslint-disable-next-line no-console
    console.log('>>> Clover > Add Users > results: ', results);
    setCreationResults(results);
  };

  const populateMID = () => {
    const mid = cloverUtils.getCloverMid();
    if (mid) {
      setStoreMID(mid);
    }
  };

  useEffect(() => {
    populateMID();
  }, []);

  // --- Event handling ---
  const handleSubmit = (event) => {
    event.preventDefault();
    addUsers(formData.users, storeMID);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <FullWidth>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" component="h1">
            {copyText.Employees.admin.heading}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="body1">{copyText.Employees.admin.description}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1">{copyText.Employees.admin.format}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{copyText.Employees.admin.exampleA}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">{copyText.Employees.admin.exampleB}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={copyText.Employees.admin.listUsers}
                  multiline
                  name="users"
                  value={formData.users}
                  onChange={(event) => updateForm(event, event.target.value)}
                  required
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} justifySelf="right">
                    <Button variant="contained" type="submit">
                      {copyText.Employees.admin.submit}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        {creationResults?.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h2" component="h2">
              {copyText.Employees.admin.results}
            </Typography>
            <List>
              {creationResults.map((item, ind) => {
                const success = !!item?.data;
                const key = ind;
                // --- Successfully Added User ---
                if (success) {
                  return (
                    <>
                      <Divider component="li" />
                      <ListItem key={key}>
                        <ListItemIcon sx={{ fontSize: 20 }}>
                          <CheckCircleIcon color="success" />
                        </ListItemIcon>
                        <ListItemText
                          primary={`${item?.data?.name}, ${item?.data?.email}`}
                          secondary={copyText.Employees.admin.success}
                        />
                      </ListItem>
                    </>
                  );
                }
                // --- Error Adding User ---
                return (
                  <>
                    <Divider component="li" />
                    <ListItem key={key}>
                      <ListItemIcon sx={{ fontSize: 20 }}>
                        <ErrorOutlineIcon color="error" />
                      </ListItemIcon>
                      <ListItemText primary={item?.response?.data?.message} />
                    </ListItem>
                  </>
                );
              })}
            </List>
          </Grid>
        )}
      </Grid>
      <Snackbar open={openSnack} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error">
          {snackMessage}
        </Alert>
      </Snackbar>
    </FullWidth>
  );
};

export default CloverAccounts;
