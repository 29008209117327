export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const formattedNumber = phoneNumber.replace(/\D/g, '');
    if (formattedNumber.length > 10) {
      return formattedNumber.slice(-10);
    }
    if (formattedNumber.length === 10) {
      return formattedNumber;
    }
  }
  return null;
};

export const formatPhone = (phone) => {
  const value = phone.replace(/\D+/g, '');
  let formattedValue = '';

  if (value.length > 3 && value.length < 8) {
    formattedValue = value.replace(/(\d{3})(\d)/, '$1-$2');
  } else if (value.length >= 8 && value.length < 11) {
    formattedValue = value.replace(/(\d{3})(\d{3})(\d)/, '($1) $2-$3');
  } else if (value.length === 11) {
    formattedValue = value.replace(/(\d{1})?(\d{3})(\d{3})(\d)/, '($2) $3-$4');
  } else if (value.length === 12) {
    formattedValue = value.replace(/(\d{2})?(\d{3})(\d{3})(\d)/, '($2) $3-$4');
  } else {
    formattedValue = value;
  }
  return formattedValue;
};
