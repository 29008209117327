import React from 'react';
import { copyText } from 'language';
import { bool, string, shape, func, arrayOf } from 'prop-types';
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import ReturnReasonsMultiSelect from './components/ReturnReasonMultiSelect';

const ReturnConditionAndReasonForm = ({ formData, updateForm, readOnly }) => (
  <Box component="form" sx={readOnly ? { mt: 3, mb: 2 } : {}}>
    <FormControl>
      <FormLabel>{copyText.Orders.Returns.itemCondition}</FormLabel>
      <RadioGroup
        value={formData.condition}
        onChange={updateForm}
        sx={{ display: 'flex', flexDirection: 'row' }}
      >
        <FormControlLabel
          name="condition"
          value="Used/Opened"
          control={<Radio />}
          label={copyText.Orders.Returns.used}
          sx={{ mr: 4 }}
          disabled={readOnly}
        />
        <FormControlLabel
          name="condition"
          value="Unopened"
          control={<Radio />}
          label={copyText.Orders.Returns.unopened}
          disabled={readOnly}
        />
      </RadioGroup>
      <FormLabel sx={{ mt: 3, mb: 1.5 }}>{copyText.Orders.Returns.returnReason}</FormLabel>
      <ReturnReasonsMultiSelect
        returnReasons={formData.returnReasons}
        updateReturnReasons={updateForm}
        readOnly={readOnly}
      />
      <FormLabel sx={{ marginTop: 3 }}>{copyText.Orders.Returns.physicalReturn}</FormLabel>
      <RadioGroup
        value={formData.physicalReturn}
        onChange={updateForm}
        sx={{ display: 'flex', flexDirection: 'row' }}
      >
        <FormControlLabel
          value
          control={<Radio />}
          label={copyText.App.yes}
          name="physicalReturn"
          sx={{ mr: 4 }}
          disabled={readOnly}
        />
        <FormControlLabel
          value={false}
          control={<Radio />}
          label={copyText.App.no}
          name="physicalReturn"
          disabled={readOnly}
        />
      </RadioGroup>
    </FormControl>
  </Box>
);

export default ReturnConditionAndReasonForm;

ReturnConditionAndReasonForm.propTypes = {
  formData: shape({
    condition: string,
    returnReasons: arrayOf(string),
    otherReason: string,
    physicalReturn: bool,
    agent_name: string,
    agent_email: string,
    store_code: string,
    order_number: string,
    customer_email: string,
    customer_name: string,
    is_return: bool,
    store_address_1: string,
    store_suite: string,
    store_city: string,
    store_state: string,
    store_zip: string,
    store_country: string,
  }),
  updateForm: func,
  readOnly: bool,
};

ReturnConditionAndReasonForm.defaultProps = {
  formData: {},
  readOnly: false,
  updateForm: () => {},
};
