import React, { useEffect, useState } from 'react';
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import { copyText } from 'language';
import { shape, string } from 'prop-types';
import storeLocation from 'utils/storeLocation';
import fulfillmentStatusOptions from 'utils/fulfillmentStatusOptions';
import { useSelectedOrder } from 'contexts/orders';
import orders from 'dataAccess/api/orders.ts';
import { Close } from '@mui/icons-material';

const FulfillmentMethod = ({ customData, productData }) => {
  const currentOrder = useSelectedOrder();
  const [locationName, setLocationName] = useState(copyText.Orders.OrderData.notAvailable);
  const [fulfillmentStatus, setFulfillmentStatus] = useState(
    productData?.custom?.fields?.fulfillmentStatus || copyText.Orders.OrderData.notAvailable,
  );
  const [canChangeFulfillment, setCanChangeFulfillment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMsg, setSnackbarMsg] = useState(null);
  const populateLocationName = async () => {
    const storeKey = customData?.custom?.fields?.storeKey;
    const locationsList = await storeLocation.getStoreLocationsList();
    const storeName = storeLocation.getStoreNameByKey(storeKey, locationsList);
    setLocationName(storeName);
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const action = (
    <IconButton
      disabled={loading}
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSnackbarClose}
    >
      <Close />
    </IconButton>
  );

  const findUpdatedLineItem = (fulfillment, productId) => {
    return fulfillment?.lineItems?.find((lineItem) => lineItem.id === productId);
  };

  const handleUpdateError = (error) => {
    setSnackbarMsg(`${copyText.App.failure}: ${error.message}`);
  };

  const handleUpdatedFulfillment = (fulfillment) => {
    const updatedLineItem = findUpdatedLineItem(fulfillment, productData.id);
    if (updatedLineItem) {
      setFulfillmentStatus(updatedLineItem.custom.fields.fulfillmentStatus);
      setSnackbarMsg(copyText.App.savedUpdatesSuccess);
    } else {
      throw new Error(copyText.Orders.OrderData.fulfillmentStatusNoLineItem);
    }
  };

  const updateFulfillmentStatusInOrder = async (orderId, productId, status) => {
    const updatedFulfillment = await orders.updateFulfillmentStatus(orderId, productId, status);
    if (!updatedFulfillment) {
      throw new Error(copyText.Orders.OrderData.fulfillmentStatusUpdateFailed);
    }
    const updatedLineItem = findUpdatedLineItem(updatedFulfillment, productId);
    if (!updatedLineItem) {
      throw new Error(copyText.Orders.OrderData.fulfillmentStatusNoLineItem);
    }
    return updatedFulfillment;
  };

  const updateFulfillmentStatus = async (e) => {
    const updatedStatus = e.target.value;

    try {
      setLoading(true);
      setSnackbarMsg(copyText.App.saving);
      setOpenSnackbar(true);
      const updatedFulfillment = await updateFulfillmentStatusInOrder(
        currentOrder.id,
        productData.id,
        updatedStatus,
      );
      handleUpdatedFulfillment(updatedFulfillment);
    } catch (error) {
      handleUpdateError(error);
    } finally {
      setLoading(false);
    }
  };

  const populateCanChangeFulfillment = () => {
    if (customData?.custom?.fields?.netsuite_shipping_name === 'Pick Up') {
      return false;
    }
    return true;
  };

  useEffect(() => {
    populateLocationName();
    setCanChangeFulfillment(populateCanChangeFulfillment());
  }, []);

  return (
    <List>
      <ListItem disableGutters disablePadding>
        <ListItemText
          primary={copyText.Orders.OrderData.fulfillmentMethod}
          secondary={`${
            customData?.custom?.fields?.netsuite_shipping_name ||
            copyText.Orders.OrderData.notAvailable
          }`}
        />
      </ListItem>
      <ListItem
        disableGutters
        disablePadding
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'start',
        }}
      >
        <ListItemText
          primary={copyText.Orders.OrderData.itemStatus}
          secondary={
            <Select
              disabled={canChangeFulfillment}
              variant="standard"
              sx={{ color: 'mediumPurple', height: '10px' }}
              disableUnderline
              value={fulfillmentStatus}
              onChange={updateFulfillmentStatus}
            >
              {fulfillmentStatusOptions.map((option) => {
                return (
                  <MenuItem value={option.key} key={option.key}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          }
        />
      </ListItem>
      <ListItem disableGutters disablePadding>
        <ListItemText primary={copyText.Orders.OrderData.itemStore} secondary={`${locationName}`} />
      </ListItem>
      <Snackbar
        open={openSnackbar}
        onClose={handleSnackbarClose}
        message={snackbarMsg}
        action={action}
      />
    </List>
  );
};

FulfillmentMethod.propTypes = {
  customData: shape({
    custom: shape({ fields: shape({ netsuite_shipping_name: string, storeKey: string }) }),
  }),
  productData: shape({
    custom: shape({ fields: shape({ fulfillmentStatus: string }) }),
  }),
};

FulfillmentMethod.defaultProps = {
  customData: {},
  productData: {},
};

export default FulfillmentMethod;
