import { shape, number, func, bool } from 'prop-types';
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import LogoutButton from 'components/Header/components/UserMenu/Components/LogoutButton';
import clover from 'dataAccess/api/clover.ts';
import { useEffect, useState } from 'react';
import copyText from 'language/enUS';

const PaymentResponse = ({ response, open, loading, closeModal, resendPaymentRequest }) => {
  const [status, setStatus] = useState('Check Status');
  const [cancelLoader, setCancelLoader] = useState(false);
  const cancelCurrentTransaction = async () => {
    try {
      setCancelLoader(true);
      await clover.cancelCurrentOperation({ setMessage: setStatus });
      setCancelLoader(false);
    } catch (error) {
      setCancelLoader(false);
    }
  };

  const checkStatus = async () => {
    const result = await clover.checkDeviceStatus();
    if (result.data?.connected === true) {
      setStatus(copyText.Cart.PaymentResponse.connected);
    } else {
      setStatus(copyText.Cart.PaymentResponse.disconnected);
    }
    // setStatus(result.data?.connected);
  };
  const getResponseTips = () => {
    switch (response?.status) {
      case 200: {
        return {
          header: copyText.Cart.PaymentResponse.successfulPayment,
          body: [
            <Box
              display="flex"
              justifyContent="center"
              alignContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <CircularProgress sx={{ mb: 2 }} />
              {copyText.Cart.PaymentResponse.creatingOrder}
            </Box>,
          ],
        };
      }
      case 209: {
        return {
          header: copyText.Cart.PaymentResponse.paymentCancelled,
          body: [copyText.Cart.PaymentResponse.retryPayment],
        };
      }
      case (400, 415): {
        return copyText.Cart.PaymentResponse.invalidPaymentRequest;
      }
      case 401: {
        return copyText.Cart.PaymentResponse.invalidCloverCredentials;
      }
      case 500: {
        return copyText.Cart.PaymentResponse.verifyCloverStatus;
      }
      case 501: {
        return copyText.Cart.PaymentResponse.deviceInaccessible;
      }
      case 503: {
        return {
          header: copyText.Cart.PaymentResponse.deviceUnavailable,
          body: [copyText.Cart.PaymentResponse.deviceUnavailableBody],
        };
      }
      case 504: {
        return {
          header: copyText.Cart.PaymentResponse.paymentTimeOut,
          body: [copyText.Cart.PaymentResponse.pleaseTryAgain],
        };
      }
      default: {
        return {
          header: copyText.Cart.PaymentResponse.paymentCancelled,
          body: [copyText.Cart.PaymentResponse.retryPayment],
        };
      }
    }
  };

  const getResponseActions = () => {
    switch (response?.status) {
      case 200: {
        return [];
      }
      case 209: {
        return [
          <Button variant="contained" onClick={resendPaymentRequest}>
            {copyText.App.retry}
          </Button>,
        ];
      }
      case (400, 415): {
        return [
          <Button variant="contained" onClick={resendPaymentRequest}>
            {copyText.App.retry}
          </Button>,
        ];
      }
      case 401: {
        return [
          <Button variant="contained" onClick={resendPaymentRequest}>
            {copyText.App.retry}
          </Button>,
          <LogoutButton />,
        ];
      }
      case 500: {
        return [
          <Button variant="contained" onClick={checkStatus}>
            {copyText.Cart.PaymentResponse.checkStatus}
          </Button>,
        ];
      }
      case 501: {
        return [];
      }
      case 503: {
        return [
          <Button variant="outlined" onClick={cancelCurrentTransaction}>
            {copyText.Cart.PaymentResponse.clearDeviceOperations}
          </Button>,
          <Button variant="contained" onClick={resendPaymentRequest}>
            {copyText.App.retry}
          </Button>,
        ];
      }
      case 504: {
        return [
          <Button variant="contained" onClick={resendPaymentRequest}>
            {copyText.App.retry}
          </Button>,
        ];
      }
      default: {
        return [
          <Button onClick={cancelCurrentTransaction}>
            {copyText.Cart.PaymentResponse.clearDeviceOperations}
          </Button>,
          <Button variant="contained" onClick={resendPaymentRequest}>
            {copyText.App.retry}
          </Button>,
          <Button variant="contained" onClick={checkStatus}>
            {copyText.Cart.PaymentResponse.checkStatus}
          </Button>,
        ];
      }
    }
  };

  const getPaymentResponses = () => {
    const responses = getResponseTips().body;
    if (responses) {
      return responses.map((item) => {
        return <Typography key={item}>{item}</Typography>;
      });
    }
    return null;
  };

  useEffect(() => {
    getResponseTips();
  }, [response]);

  if (loading) {
    return (
      <Dialog fullWidth open={open}>
        <Box sm={{ width: '45vw' }}>
          <DialogTitle>{copyText.Cart.PaymentResponse.waitingForPayment}</DialogTitle>
          <DialogContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircularProgress sx={{ marginTop: 2 }} />
            <Typography marginTop={4}>{copyText.Cart.PaymentResponse.followPrompts}</Typography>
          </DialogContent>
          <DialogActions sx={{ m: 1 }}>
            <Button onClick={cancelCurrentTransaction} disabled={cancelLoader} variant="outlined">
              {copyText.App.cancel}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
  return (
    <Dialog open={open} fullWidth>
      <Box sm={{ width: '45vw' }}>
        <DialogTitle>{getResponseTips().header}</DialogTitle>
        <DialogContent>
          {getPaymentResponses()}
          {response?.status === 500 && (
            <Typography>{`${copyText.Cart.PaymentResponse.deviceStatus}: ${status}`}</Typography>
          )}
        </DialogContent>
        <DialogActions sx={{ m: 1, p: 1 }}>
          <Button onClick={closeModal} variant="outlined">
            {copyText.App.close}
          </Button>
          {getResponseActions()}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

PaymentResponse.propTypes = {
  response: shape({
    status: number,
  }),
  open: bool.isRequired,
  loading: bool.isRequired,
  closeModal: func.isRequired,
  resendPaymentRequest: func.isRequired,
};

PaymentResponse.defaultProps = {
  response: shape({
    status: null,
  }),
};

export default PaymentResponse;
