import React, { useState, useEffect } from 'react';
import {
  Button,
  DialogContent,
  Select,
  MenuItem,
  DialogActions,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { func, string, shape, bool, arrayOf } from 'prop-types';
import { copyText, lang } from 'language';
import storeLocation from 'utils/storeLocation';
import { useSetFoundOrderData } from 'contexts/orders';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import searchUtils from './utils/SearchUtils';
import filterUtils from './utils/filterUtils';

const OrderFilterModal = ({
  open,
  setOpen,
  selectedStore,
  setSelectedStore,
  closeFilterModal,
  locationList,
  orderInput,
  setOrderInput,
  setLoading,
  setFilters,
}) => {
  const setFoundOrderData = useSetFoundOrderData();
  const [allOrderTypes, setAllOrderTypes] = useState(true);
  const [exchangeFilter, setExchangeFilter] = useState('');
  const [returnFilter, setReturnFilter] = useState('');
  const [originalFilter, setOriginalFilter] = useState('');
  const [draftFilter, setDraftFilter] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    filterUtils.resetFilters({
      exchangeFilter,
      returnFilter,
      originalFilter,
      draftFilter,
      setAllOrderTypes,
    });
  }, [allOrderTypes, exchangeFilter, returnFilter, originalFilter, draftFilter]);

  return (
    <DialogTemplate
      dialogTitle={copyText.Layouts.SearchLayout.filters}
      open={open}
      setOpen={setOpen}
      noButton
      message={message}
      setMessage={setMessage}
    >
      <DialogContent>
        <InputLabel id="order-store-select">{copyText.Orders.Orders.storeSelect}</InputLabel>
        <Select
          value={selectedStore?.key || 'all'}
          id="order-store-select"
          variant="standard"
          disableUnderline
          renderValue={(selected) => {
            if (selected === 'all') return copyText.Orders.Orders.allStores;
            const foundStore = storeLocation
              .sortStoresAlphabetical(locationList)
              .find((store) => store?.key === selected);
            return foundStore ? foundStore?.name[lang] : '';
          }}
        >
          <MenuItem value="all" onClick={() => setSelectedStore('all')}>
            {copyText.Orders.Orders.allStores}
          </MenuItem>
          {storeLocation.sortStoresAlphabetical(locationList)?.map((store) => (
            <MenuItem onClick={() => setSelectedStore(store)} key={store.key} value={store.key}>
              <ListItemText primary={`${store?.key}-${store?.name[lang]}`} />
            </MenuItem>
          ))}
        </Select>
        <InputLabel id="order-store-select">{copyText.Orders.Orders.orderTypeSelect}</InputLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={allOrderTypes}
                onClick={() => {
                  filterUtils.setAllTypes({
                    setExchangeFilter,
                    setReturnFilter,
                    setOriginalFilter,
                    setDraftFilter,
                  });
                  setAllOrderTypes(!allOrderTypes);
                }}
              />
            }
            sx={{ mt: 1 }}
            label={copyText.Orders.Orders.all}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!exchangeFilter}
                onClick={() => {
                  setExchangeFilter(exchangeFilter ? '' : 'exchange');
                }}
              />
            }
            sx={{ mt: 1 }}
            label={copyText.Orders.Orders.exchangeType}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!originalFilter}
                onClick={() => {
                  setOriginalFilter(originalFilter ? '' : 'original');
                }}
              />
            }
            sx={{ mt: 1 }}
            label={copyText.Orders.Orders.hasExchange}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!returnFilter}
                onClick={() => {
                  setReturnFilter(returnFilter ? '' : 'return');
                }}
              />
            }
            sx={{ mt: 1 }}
            label={copyText.Orders.Orders.returnType}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!draftFilter}
                onClick={() => {
                  setDraftFilter(draftFilter ? '' : 'draft');
                }}
              />
            }
            sx={{ mt: 1 }}
            label={copyText.Orders.Orders.draftType}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            searchUtils.searchFilters({
              filters: { exchangeFilter, returnFilter, originalFilter, draftFilter },
              selectedStore,
              orderInput,
              setOrderInput,
              setLoading,
              setFoundOrderData,
              setFilters,
              closeFilterModal,
            });
          }}
        >
          {copyText.Orders.Orders.search}
        </Button>
      </DialogActions>
    </DialogTemplate>
  );
};

export default OrderFilterModal;

OrderFilterModal.propTypes = {
  open: bool,
  selectedStore: shape({ key: string }),
  setSelectedStore: func.isRequired,
  closeFilterModal: func.isRequired,
  locationList: arrayOf(shape({})).isRequired,
  orderInput: string,
  setOrderInput: func.isRequired,
  setLoading: func.isRequired,
  setFilters: func.isRequired,
  setOpen: func.isRequired,
};

OrderFilterModal.defaultProps = {
  open: false,
  selectedStore: { key: '' },
  orderInput: '',
};
