import bedding from './images/bedding.png';
import essentialMattressEssentialPlus from './images/essential_mattress_essential_plus.png';
import essentialPlus from './images/essential_plus.png';
import essential from './images/essential.png';
import pillows from './images/pillows.png';
import rejuvenatePlus from './images/rejuvenate_plus.png';
import rejuvenatePremier from './images/rejuvenate_premier.png';
import rejuvenate from './images/rejuvenate.png';
import restorePlus from './images/restore_plus.png';
import restorePremier from './images/restore_premier.png';
import restore from './images/restore.png';
import seatCushionsCovers from './images/seat_cushions_covers.png';
import seatCushions from './images/seat_cushions.png';

/**
 * --- v3 URLs ---
 * - PerfectStay video: ENV_URL/signage?page=perfectstay_duvet&version=v3
 * - Harmony sweepstakes: ENV_URL/signage?page=harmony&version=v3
 * - Bedding: ENV_URL/signage?page=bedding&version=v3
 * - Essential Mattress Essential Plus: ENV_URL/signage?page=essential_mattress_essential_plus&version=v3
 * - Essential Plus: ENV_URL/signage?page=essential_plus&version=v3
 * - Essential: ENV_URL/signage?page=essential&version=v3
 * - Pillows: ENV_URL/signage?page=pillows&version=v3
 * - Rejuvenate Plus: ENV_URL/signage?page=rejuvenate_plus&version=v3
 * - Rejuvenate Premier: ENV_URL/signage?page=rejuvenate_premier&version=v3
 * - Rejuvenate: ENV_URL/signage?page=rejuvenate&version=v3
 * - Restore Plus: ENV_URL/signage?page=restore_plus&version=v3
 * - Restore Premier: ENV_URL/signage?page=restore_premier&version=v3
 * - Restore: ENV_URL/signage?page=restore&version=v3
 * - Seat Cushions Covers: ENV_URL/signage?page=seat_cushions_covers&version=v3
 * - Seat Cushions: ENV_URL/signage?page=seat_cushions&version=v3
 */
const v3 = {
  bedding: {
    href: 'https://purple.com/bedding',
    imgUri: bedding,
  },
  essential_mattress_essential_plus: {
    href: 'https://purple.com/mattresses/plus',
    imgUri: essentialMattressEssentialPlus,
  },
  essential_plus: {
    href: 'https://purple.com/mattresses/plus',
    imgUri: essentialPlus,
  },
  essential: {
    href: 'https://purple.com/mattresses',
    imgUri: essential,
  },
  pillows: {
    href: 'https://purple.com/pillows',
    imgUri: pillows,
  },
  rejuvenate_plus: {
    href: 'https://purple.com/mattresses/rejuvenate-plus',
    imgUri: rejuvenatePlus,
  },
  rejuvenate_premier: {
    href: 'https://purple.com/mattresses/rejuvenate-premier',
    imgUri: rejuvenatePremier,
  },
  rejuvenate: {
    href: 'https://purple.com/mattresses/rejuvenate',
    imgUri: rejuvenate,
  },
  restore_plus: {
    href: 'https://purple.com/mattresses/restore-plus',
    imgUri: restorePlus,
  },
  restore_premier: {
    href: 'https://purple.com/mattresses/restore-premier',
    imgUri: restorePremier,
  },
  restore: {
    href: 'https://purple.com/mattresses/restore',
    imgUri: restore,
  },
  seat_cushions_covers: {
    href: 'https://purple.com/seat-cushions',
    imgUri: seatCushionsCovers,
  },
  seat_cushions: {
    href: 'https://purple.com/seat-cushions',
    imgUri: seatCushions,
  },
};

export default v3;
