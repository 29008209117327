import clover from 'dataAccess/api/clover.ts';
import storeUtils from 'utils/storeLocation';
import copyText from 'language/enUS';
import { logError } from './errorUtils';

const getCloverAccessToken = () => {
  const cloverToken = JSON.parse(window.localStorage.getItem('clover-token-storage'));
  if (cloverToken) {
    return cloverToken;
  }
  return null;
};

const tokenExpired = (cloverToken) => {
  const currentTime = Math.floor(Date.now() / 1000); // Convert to Unix timestamp
  const tokenExpiration = cloverToken.authTokenExpiration;
  return currentTime >= tokenExpiration;
};

const setCloverAccessToken = (tokenStorage) => {
  window.localStorage.setItem('clover-token-storage', JSON.stringify(tokenStorage));
};

const setSessionCloverDevice = (deviceSerial) => {
  window.localStorage.setItem('clover-device', deviceSerial);
  window.dispatchEvent(new Event('storage'));
};

const getSessionCloverDevice = () => {
  return window.localStorage.getItem('clover-device');
};

const clearCloverStorage = () => {
  localStorage.removeItem('clover-token-storage');
  window.localStorage.removeItem('clover-device');
};

/**
 * Checks if clover token exists and matches store OR if user manually selected a location
 * If false, then checks if a code for getting an access token is in the URL
 * OR checks if the user manually selected a new location.
 * If either is true, redirects to clover for authentication.
 * else, uses the code in the URL to get a new token
 * stores the new token in session storage
 * @param {*} store the selected store for comparing against the store associated with the access token
 * @param {*} manualSelect if a user manually selects a store, redirect should always occur.
 * This helps to avoid end-user store selection constraints.
 */
const updateCloverAccessToken = async ({ currentStoreKey, setMessage }) => {
  try {
    const currentCloverData = getCloverAccessToken();

    if (currentCloverData?.key !== currentStoreKey) {
      clearCloverStorage();
    }

    const storeLocation = await storeUtils.getLocationStorage();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    // get a token if you have a code from Clover
    if (code && !window?.location?.href.includes('login-okta')) {
      const result = await clover.getToken({ authCode: code, setMessage });
      if (result) {
        const tokenStorage = {
          key: storeLocation.key,
          token: result?.data?.access_token,
          refreshToken: result?.data?.refresh_token,
          // convert expiration dates to javascript date objects
          authTokenExpiration: result?.data?.access_token_expiration,
          refreshTokenExpiration: result?.data?.refresh_token_expiration,
        };
        setCloverAccessToken(tokenStorage);
      }
    }
  } catch (err) {
    if (setMessage) {
      setMessage(
        err?.response?.data?.errors[0]?.meta?.meta?.error || 'Error updating Clover token',
      );
    }
  }
};

const refreshCloverToken = async ({ setMessage }) => {
  try {
    setMessage(copyText.Clover.refreshingCredentials);
    const tokenStorage = getCloverAccessToken();
    const result = await clover.refreshCloverToken(tokenStorage);
    if (result) {
      const newTokenStorage = {
        key: tokenStorage.key,
        token: result?.data?.access_token,
        refreshToken: result?.data?.refresh_token,
        // convert expiration dates to javascript date objects
        authTokenExpiration: result?.data?.access_token_expiration,
        refreshTokenExpiration: result?.data?.refresh_token_expiration,
      };
      setCloverAccessToken(newTokenStorage);
      setMessage(copyText.Clover.credentialsRefreshed);
    }
  } catch (err) {
    logError({
      method: 'refreshCloverToken',
      errorInfo: err,
      message: err?.message,
      source: 'api/clover',
    });
    setMessage(copyText.Clover.errorRefreshingCredentials);
    setCloverAccessToken(null);
    setSessionCloverDevice(null);
  }
};

const getCloverMid = () => {
  const storeLocationData = storeUtils.getLocationStorage();
  return storeLocationData?.custom?.fields?.cloverMerchantId || null;
};

const getDevices = async ({ setMessage }) => {
  if (tokenExpired(getCloverAccessToken())) {
    await refreshCloverToken({ setMessage });
  }
  const result = await clover.getDevices();
  return result?.data?.elements;
};

const displayMessage = async (serial, name, setMessage) => {
  if (tokenExpired(getCloverAccessToken())) {
    await refreshCloverToken({ setMessage });
  }
  await clover.displayMessage(serial, name);
};

const checkCloverLoginRequired = () => {
  const location = storeUtils.getLocationStorage();
  const ccareStoreKey = 'UT-CC';
  const insideSalesStoreKey = 'UT-IS';
  if (location?.key === ccareStoreKey || location?.key === insideSalesStoreKey) return false;
  return true;
};

export default {
  setCloverAccessToken,
  getCloverAccessToken,
  updateCloverAccessToken,
  clearCloverStorage,
  getDevices,
  getCloverMid,
  displayMessage,
  setSessionCloverDevice,
  getSessionCloverDevice,
  tokenExpired,
  refreshCloverToken,
  checkCloverLoginRequired,
};
