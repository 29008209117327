import { logError } from 'utils/errorUtils';

const parseAddressValues = ({ address }) => {
  const number = address?.address_components?.find((component) =>
    component.types.includes('street_number'),
  );
  const streetAddress = address?.address_components?.find((component) =>
    component.types.includes('route'),
  );
  const city = address?.address_components?.find((component) =>
    component.types.includes('locality'),
  );
  const state = address?.address_components?.find((component) =>
    component.types.includes('administrative_area_level_1'),
  );
  const postalCode = address?.address_components?.find((component) =>
    component.types.includes('postal_code'),
  );
  const country = address?.address_components?.find((component) =>
    component.types.includes('country'),
  );
  return {
    streetAddress: `${number?.long_name ?? ''} ${streetAddress?.long_name ?? ''}`,
    city: city?.long_name ?? '',
    state: state?.short_name ?? '',
    postalCode: postalCode?.long_name ?? '',
    country: country?.short_name ?? 'US',
  };
};

const options = {
  componentRestrictions: { country: ['US'] },
  fields: ['address_components', 'name'],
  types: ['address'],
};

const handleSelect = async ({ autoCompleteRef, onSelect, setShowSearch }) => {
  try {
    const place = await autoCompleteRef.current.getPlace();
    await onSelect(place);
    setShowSearch(false);
  } catch (error) {
    logError({
      message: error.message,
      source: 'addressAutoCompleteUtils',
      errorInfo: error,
      method: 'handleSelect',
    });
  }
};

const autoCompleteStartup = (inputRef, autoCompleteRef, onSelect, setShowSearch) => {
  const updatedRef = autoCompleteRef;
  if (inputRef.current) {
    updatedRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);

    updatedRef.current.addListener('place_changed', () =>
      handleSelect({ autoCompleteRef: updatedRef, onSelect, setShowSearch }),
    );
  }
};

export default {
  parseAddressValues,
  handleSelect,
  options,
  autoCompleteStartup,
};
