import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Grid,
  Divider,
  List,
  Typography,
  Container,
  CircularProgress,
  IconButton,
  TablePagination,
} from '@mui/material';
import { Box } from '@mui/system';
import { Close, Search as SearchIcon } from '@mui/icons-material';
import { useOktaAuth } from '@okta/okta-react';

import { useHistory } from 'react-router-dom';

import { useOldCart, useSetCart, useSetOldCart } from 'contexts';
import TextLoader from 'components/TextLoader';
import { copyText } from 'language';
import ViewTransition from 'components/Transitions/ViewTransition';

import CartHistoryItem from './components/CartHistoryItem';
import CartHistoryDetails from './components/CartHistoryDetails.jsx/CartHistoryDetails';
import CartHistoryFilters from './components/CartHistoryFilters/CartHistoryFilters';
import cartHistoryUtils from './components/cartHistoryUtils';

const CartHistory = () => {
  const { oktaAuth } = useOktaAuth();
  const defaultFormData = {
    customerEmail: oktaAuth?.authStateManager?._authState?.idToken?.claims?.email,
    agentEmail: null,
    draftsFilter: false,
    activeCarts: null,
    page: 0,
    store: 'all',
  };
  const oldCart = useOldCart();
  const setOldCart = useSetOldCart();
  const setCart = useSetCart();
  const history = useHistory();
  const [foundCarts, setFoundCarts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCart, setLoadingCart] = useState(false);
  const [status, setStatus] = useState('');
  const [formData, setFormData] = useState(defaultFormData);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalResults, setTotalResults] = useState(0);
  const [selectedAgent, setSelectedAgent] = useState(null);

  useEffect(() => {
    cartHistoryUtils.submitSearchQuery({
      page: 0,
      setFoundCarts,
      setTotalResults,
      setLoading,
      setFormData,
      setCurrentPage,
      formData,
      setStatus,
    });
  }, [oktaAuth]);

  return (
    <ViewTransition>
      <Container disableGutters sx={{ width: '100%', mt: 11 }}>
        {/* --- Top Bar --- */}
        <Grid container alignItems="center" sx={{ p: 1.5 }} flexDirection="row">
          <form
            onSubmit={(event) =>
              cartHistoryUtils.handleSubmit({
                event,
                setFoundCarts,
                setTotalResults,
                setLoading,
                setCurrentPage,
                setFormData,
                formData,
                setStatus,
              })
            }
            style={{ display: 'contents' }}
          >
            <Grid item sm={4.4}>
              <TextField
                placeholder={copyText.Cart.CartTools.CartHistory.searchByEmail}
                InputProps={{
                  endAdornment: (
                    <Button
                      disabled={!formData.customerEmail}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {loading ? <CircularProgress color="secondary" size={25} /> : <SearchIcon />}
                    </Button>
                  ),
                }}
                onChange={(e) => cartHistoryUtils.updateForm(e, setFormData)}
                variant="outlined"
                name="customerEmail"
                value={formData?.customerEmail}
                fullWidth
              />
            </Grid>
            <Grid item sm={5}>
              <CartHistoryFilters
                updateForm={(e) => cartHistoryUtils.updateForm(e, setFormData)}
                submitSearchQuery={(query) =>
                  cartHistoryUtils.submitSearchQuery({
                    ...query,
                    setFoundCarts,
                    setTotalResults,
                    setLoading,
                    setCurrentPage,

                    setFormData,
                    formData,
                    setStatus,
                  })
                }
                formData={formData}
                selectedAgent={selectedAgent}
                setSelectedAgent={setSelectedAgent}
              />
            </Grid>
            <Grid item sm={2}>
              <Button
                type="reset"
                color="primary"
                variant="contained"
                onClick={() =>
                  cartHistoryUtils.handleReset({
                    setFormData,
                    setSelectedAgent,
                    formData,
                    setCurrentPage,
                    setLoading,
                    setFoundCarts,
                    setTotalResults,
                    setStatus,
                    defaultFormData,
                  })
                }
              >
                {copyText.Cart.CartTools.CartHistory.resetSearch}
              </Button>
            </Grid>
          </form>
        </Grid>
        {status && (
          <Grid
            item
            xs={16}
            sx={{ backgroundColor: 'lightAquaBG', height: '2.5rem', p: 5 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography>
                {copyText.Cart.CartTools.CartHistory.searchFailed}: {status}
              </Typography>
              <Typography>{copyText.Feedback.tryAgain}</Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setStatus()}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        )}
        <Divider />
        <Grid container columns={16}>
          {/* --- Side Bar --- */}
          <Grid item xs={16} sm={6} display="flex">
            <Grid
              container
              justifyContent="space-between"
              flexDirection="column"
              display="flex"
              mb={12}
            >
              {foundCarts.length === 0 && (
                <Box display="flex" height="50vh" justifyContent="center" alignItems="center">
                  {copyText.Cart.CartTools.CartHistory.noCartsFound}
                </Box>
              )}
              <Grid item style={{ flex: 1, overflow: 'auto' }}>
                <List
                  sx={{
                    position: 'relative',
                    overflow: 'scroll',
                    mb: 0,
                    p: 1,
                    height: { xs: '50vh', md: '60vh' },
                  }}
                >
                  {foundCarts.length > 0 &&
                    foundCarts.map((cart) => {
                      return <CartHistoryItem key={cart.id} cart={cart} />;
                    })}
                </List>
              </Grid>
              <Grid item>
                <TablePagination
                  component="div"
                  count={totalResults}
                  page={currentPage}
                  rowsPerPage={20}
                  rowsPerPageOptions={[20]}
                  onPageChange={(event, newPage) => {
                    cartHistoryUtils.submitSearchQuery({
                      page: newPage,
                      setFoundCarts,
                      setTotalResults,
                      setLoading,
                      setCurrentPage,
                      setFormData,
                      formData,
                      setStatus,
                    });
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* --- Details View --- */}
          <Grid item xs={0} sm={0.25} alignItems="center">
            <Divider orientation="vertical" />
          </Grid>
          {oldCart.id ? (
            <Grid item xs={16} sm={9.75}>
              <Grid container alignItems="center">
                <Grid
                  sx={{ p: 1, pt: 2 }}
                  item
                  sm={16}
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography sx={{ ml: 1 }} variant="h5" component="h1">
                    {copyText.Cart.CartTools.CartHistory.cartDetails}
                  </Typography>
                  <Button
                    disabled={!oldCart || loadingCart}
                    variant="contained"
                    onClick={() => {
                      cartHistoryUtils.handleSetCart({
                        setLoadingCart,
                        history,
                        setCart,
                        setOldCart,
                        oldCart,
                      });
                    }}
                  >
                    <TextLoader loading={loadingCart} text="Load this cart" size={20} />
                  </Button>
                </Grid>
              </Grid>
              <Divider />
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'scroll',
                  msOverflowStyle: 'none',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                  maxHeight: '65vh',
                  mb: 20,
                  p: 1,
                }}
              >
                <CartHistoryDetails />
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={16}
              sm={9.75}
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="75vh"
            >
              <Typography component="p">
                {copyText.Cart.CartTools.CartHistory.selectCartToSeeDetails}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </ViewTransition>
  );
};

export default CartHistory;
