import { Divider, List, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import { copyText } from 'language';
import PendingPayment from './PendingPayment';

const PendingPayments = ({
  payments,
  setDisableRepeat,
  setShowVoidError,
  setShowCancelWarning,
}) => {
  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography>{copyText.Cart.CheckoutButtons.authorizedPayments}</Typography>
      </Box>
      <Divider />
      <List>
        {payments?.map((payment) => {
          return (
            <PendingPayment
              key={payment.id}
              payment={payment}
              setDisableRepeat={setDisableRepeat}
              setShowVoidError={setShowVoidError}
              setShowCancelWarning={setShowCancelWarning}
            />
          );
        })}
      </List>
    </>
  );
};

PendingPayments.propTypes = {
  payments: arrayOf(
    shape({
      paymentId: string.isRequired,
    }),
  ).isRequired,
  setDisableRepeat: func.isRequired,
  setShowVoidError: func.isRequired,
  setShowCancelWarning: func.isRequired,
};

export default PendingPayments;
