import orderService from 'dataAccess/api/orders.ts';
import { copyText } from 'language';

const sendConfirmationEmail = async (email, orderNumber) => {
  const { status } = await orderService.sendEmail(email, orderNumber);
  const message = status?.success
    ? copyText.Orders.EmailModal.emailSent
    : copyText.Orders.EmailModal.sendError;

  return message;
};

export default sendConfirmationEmail;
