import clover from 'dataAccess/api/clover.ts';
import cloverUtils from 'utils/clover';
import storeLocation from 'utils/storeLocation';
import getDateTime from 'utils/getDateTime';
import { formatCreatedAtTime } from 'utils/time';
import { lang } from 'language';
import { logError } from 'utils/errorUtils';
import convertCentToDollar from './convertCentToDollar';
import financialCalculators from './financialCalculators/financialCalculators';

const sumPaymentsTotal = (payments) => {
  let total = 0;
  payments.forEach((payment) => {
    total += payment.amount;
  });
  return total;
};

const stringifyPayments = (payments) => {
  let paymentString = '';
  payments.forEach((payment) => {
    // Format for thermal receipt
    paymentString += `${payment.paymentType} XXXX${payment.last4}     $${payment.amount / 100}\n`;
  });
  return paymentString;
};

const getStoreAddress = async (order) => {
  let storeName = '';
  let storeAddress = '';
  const isStoreOrder = !!order?.store?.key;
  if (isStoreOrder) {
    // order contains a store key to look up the store data (the order was created previously)
    const storeData = await storeLocation.getStoreByKey(order?.store?.key);
    storeAddress = storeData?.supplyChannels[0]?.obj?.address;
    storeName = storeData?.name[lang] ?? '';
  } else {
    // use the current store address (the order was just created)
    storeAddress = storeLocation.getStoreAddress();
    storeName = storeLocation.getStoreName() ?? '';
  }
  return { storeName, storeAddress };
};

const getOrderDate = (order) => {
  let date = '';

  const isOrderCreated = order?.createdAt;
  if (isOrderCreated) {
    // order data contains createdAt date (the order was created previously)
    date = formatCreatedAtTime(order.createdAt);
  } else {
    // order data doesn't contain createdAt date. Display current time.
    date = getDateTime();
  }
  return date;
};

const sendSplitPaymentReceipts = async (order) => {
  try {
    const payments = financialCalculators.parsePendingPayments(order);
    let amount = sumPaymentsTotal(payments);
    amount = convertCentToDollar(amount);
    const paymentsToString = stringifyPayments(payments);
    const { storeName, storeAddress } = await getStoreAddress(order);
    const date = getOrderDate(order);
    const deviceSerial = cloverUtils.getSessionCloverDevice();
    const token = cloverUtils.getCloverAccessToken()?.token ?? '';
    const pos = process.env.REACT_APP_CLOVER_POS;
    await clover.printSplitPaymentReceipt({
      order,
      amount,
      stringifiedPayments: paymentsToString,
      storeName,
      storeAddress,
      date,
      pos,
      deviceSerial,
      paymentProviderToken: token,
    });
  } catch (err) {
    logError({
      method: 'sendSplitPaymentReceipts',
      errorInfo: err,
      message: err?.message,
      source: 'utils/orderCreationUtils',
    });
    throw err;
  }
};

export default {
  sendSplitPaymentReceipts,
  sumPaymentsTotal,
  stringifyPayments,
  getStoreAddress,
  getOrderDate,
};
