import React from 'react';
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
  FormGroup,
  FormControlLabel,
} from '@mui/material';
import { string, func, bool, arrayOf } from 'prop-types';

import { copyText } from 'language';

const conditionStatuses = [
  copyText.Orders.Returns.reasons.elsewhere,
  copyText.Orders.Returns.reasons.change,
  copyText.Orders.Returns.reasons.uncomfortable,
  copyText.Orders.Returns.reasons.needMoney,
  copyText.Orders.Returns.reasons.notBetter,
  copyText.Orders.Returns.reasons.expectation,
  copyText.Orders.Returns.reasons.spouse,
  copyText.Orders.Returns.reasons.upsetDelivery,
  copyText.Orders.Returns.reasons.upsetPurple,
  copyText.Orders.Returns.reasons.skuCorrection,
  copyText.Orders.Returns.reasons.other,
];

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 'auto',
      width: 380,
    },
  },
};

const ReturnReasonsMultiSelect = ({ returnReasons, updateReturnReasons, readOnly }) => {
  if (readOnly) {
    return (
      <FormGroup>
        {returnReasons.map((reason) => (
          <FormControlLabel control={<Checkbox checked disabled />} label={reason} />
        ))}
      </FormGroup>
    );
  }
  return (
    <div style={{ width: '100%' }}>
      <FormControl sx={{ width: 380 }} required>
        <InputLabel id="return-reason-multiple-checkbox-label">
          {copyText.Orders.Returns.itemCondition}
        </InputLabel>
        <Select
          labelId="return-reason-multiple-checkbox-label"
          id="return-reason-multiple-checkbox"
          multiple
          value={returnReasons}
          onChange={updateReturnReasons}
          input={<OutlinedInput label={copyText.Orders.Returns.itemCondition} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          name="returnReasons"
          sx={{ width: '100%' }}
        >
          {conditionStatuses.map((conditionStatus) => (
            <MenuItem key={conditionStatus} value={conditionStatus}>
              <Checkbox checked={returnReasons.indexOf(conditionStatus) > -1} />
              <ListItemText primary={conditionStatus} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{copyText.Orders.Returns.whatConditionItemsBeingReturned}</FormHelperText>
      </FormControl>
    </div>
  );
};

export default ReturnReasonsMultiSelect;

ReturnReasonsMultiSelect.propTypes = {
  returnReasons: arrayOf(string),
  updateReturnReasons: func,
  readOnly: bool,
};

ReturnReasonsMultiSelect.defaultProps = {
  returnReasons: [],
  readOnly: false,
  updateReturnReasons: () => {},
};
