import { Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { arrayOf, func, shape, string } from 'prop-types';

const VariantSelect = ({ label, value, options, onChange, labelId, selectId }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select labelId={labelId} id={selectId} value={value || ''} onChange={onChange}>
          {options.map((option) => (
            <MenuItem key={option?.value?.key} value={option?.value?.key}>
              {option.value?.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

VariantSelect.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
  options: arrayOf(shape({})).isRequired,
  onChange: func.isRequired,
  labelId: string.isRequired,
  selectId: string.isRequired,
};

export default VariantSelect;
