import axios from 'axios';
import { logError } from 'utils/errorUtils';
import getAccessToken from 'utils/getAccessToken';

// /**
//  * getProduct - Retrieves a product by it's id
//  * @param productId the CT id of the product to retrieve
//  * @returns A product formatted for internal use
//  * @link TODO - Swagger Doc
//  */

/**
 * getProducts - Retrieves a list of all products
 * @returns {array} - list of products
 */
const getProducts = async () => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/catalog/products`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: {},
    };
    const { data } = await axios.get(uri, config);
    return data.results;
  } catch (error) {
    logError({
      method: 'getProducts',
      errorInfo: error,
      message: error?.message,
      source: 'pimApi/product',
    });
    throw new Error(error);
  }
};

/**
 * getProductByCategoryId - Retrieves product details by category ID
 * @param {string} categoryId
 * @returns {object} - product details
 */
const getProductByCategoryId = async (categoryId) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/catalog/categories/${categoryId}/products`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: {},
    };
    const { data } = await axios.get(uri, config);
    return data.results;
  } catch (error) {
    logError({
      method: 'getProductByCategoryId',
      errorInfo: error,
      message: error?.message,
      source: 'pimApi/product',
    });
    throw new Error(error);
  }
};

/**
 * getProductById - Retrieves product details by product ID
 * @param {string} productId
 * @returns {object} - product details
 */
const getProductById = async (productId) => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/catalog/products/${productId}`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: {},
    };
    const { data } = await axios.get(uri, config);
    return data;
  } catch (error) {
    logError({
      method: 'getProductById',
      errorInfo: error,
      message: error?.message,
      source: 'pimApi/product',
    });
    throw new Error(error);
  }
};

/**
 * getCategoryPromises - builds an array of promises to run through
 * @param {array} categories - array of objects
 * @returns {array} - array of promises
 */
const getCategoryPromises = (categories) => {
  return categories.map((category) => {
    return new Promise((res, rej) => {
      try {
        const data = getProductByCategoryId(category.id);
        res(data);
      } catch (error) {
        logError({
          method: 'getCategoryPromises',
          errorInfo: error,
          message: error?.message,
          source: 'pimApi/product',
        });
        rej(error);
      }
    }).then((data) => {
      // Associate category ID with data
      return {
        id: category.id,
        data,
      };
    });
  });
};

/**
 * getAllProducts - retrieves all products associated with a list of categories
 * @param {array} categories - array of objects
 * @returns {object} - object/map of all products by their category ID
 */
const getAllProducts = async (categories) => {
  const categoryPromises = await getCategoryPromises(categories);

  const resp = await Promise.all(categoryPromises).then((res) => {
    // Build the object
    return res.reduce((newObj, curr) => {
      return {
        ...newObj,
        [curr.id]: curr.data,
      };
    }, {});
  });
  return resp;
};

/**
 * getCategories - Retrieves categories related to navigation
 * @returns Categories belonging to navigation formatted for internal use
 */
const getCategories = async () => {
  try {
    const accessToken = getAccessToken();
    const uri = `${process.env.REACT_APP_MS_URL}/catalog/categories/${process.env.REACT_APP_CATALOG_PARENT_ID}/children`;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      params: {},
    };
    const { data } = await axios.get(uri, config);
    return data.results;
  } catch (error) {
    logError({
      method: 'getProducts',
      errorInfo: error,
      message: error?.message,
      source: 'pimApi/product',
    });
    throw Error('Problem getting shop categories');
  }
};

export default {
  getProducts,
  getProductByCategoryId,
  getProductById,
  getAllProducts,
  getCategories,
};
