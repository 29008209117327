import { Alert, Button, Typography } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import SplitPaymentReceiptModal from 'components/ReceiptModal/SplitPaymentReceiptModal';
import TextLoader from 'components/TextLoader';
import { useCart, useSetCart } from 'contexts';
import { copyText } from 'language';
import { func, bool, shape, number, string } from 'prop-types';
import { getAgentEmail } from 'utils/agentUtils';
import multiPayUtils from '../utils/multiPayUtils';

const CreateOrder = ({
  setStatus,
  setShow,
  onError,
  loadingOrder,
  setLoadingOrder,
  showReceipt,
  setShowReceipt,
  disableRepeat,
  setDisableRepeat,
  newOrder,
  setNewOrder,
}) => {
  const cart = useCart();
  const setCart = useSetCart();
  const { oktaAuth } = useOktaAuth();

  return (
    <>
      <Alert
        severity="success"
        sx={{ pt: 1, pb: 1 }}
        action={
          <Button
            variant="contained"
            disabled={loadingOrder || disableRepeat}
            onClick={() =>
              multiPayUtils.handleOrderCreation({
                setLoadingOrder,
                setStatus,
                cart,
                setCart,
                setNewOrder,
                setDisableRepeat,
                setShowReceipt,
                setShow,
                onError,
                salesAssociateId: getAgentEmail(
                  oktaAuth?.authStateManager?._authState?.idToken?.claims?.email,
                ),
              })
            }
          >
            <TextLoader
              text={copyText.Cart.CheckoutButtons.placeOrder}
              loading={loadingOrder}
              size={20}
            />
          </Button>
        }
      >
        <Typography textAlign="center">
          {copyText.Cart.CheckoutButtons.paymentsCompleted}
        </Typography>
      </Alert>
      <SplitPaymentReceiptModal
        open={showReceipt}
        order={newOrder}
        closeModal={() => multiPayUtils.handleCloseReceipt({ setShowReceipt, setShow })}
        showHeader
      />
    </>
  );
};

CreateOrder.propTypes = {
  setStatus: func.isRequired,
  setShow: func.isRequired,
  onError: func.isRequired,
  loadingOrder: bool,
  setLoadingOrder: func.isRequired,
  showReceipt: bool,
  setShowReceipt: func.isRequired,
  disableRepeat: bool,
  setDisableRepeat: func.isRequired,
  newOrder: shape({
    id: string,
    orderNumber: string,
    totalPrice: shape({
      centAmount: number,
    }),
  }).isRequired,
  setNewOrder: func.isRequired,
};

CreateOrder.defaultProps = {
  loadingOrder: false,
  showReceipt: false,
  disableRepeat: false,
};

export default CreateOrder;
