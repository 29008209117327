import { Alert } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DeviceSelect from 'components/DeviceSelect/DeviceSelect';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';
import cloverWarningUtils from './cloverWarningUtils';

const CloverWarning = () => {
  const [cloverStatus, setCloverStatus] = useState(null);
  const [message, setMessage] = useState('');
  useEffect(() => {
    cloverWarningUtils.checkCloverStatus({ setCloverStatus });

    // Listen for storage event to trigger the status check
    window.addEventListener('storage', cloverWarningUtils.checkCloverStatus({ setCloverStatus }));

    // Clean up the event listener when the component is unmounted
    return () =>
      window.removeEventListener(
        'storage',
        cloverWarningUtils.checkCloverStatus({ setCloverStatus }),
      );
  }, []);

  return (
    <>
      <Alert
        data-testid="missing-clover-warning"
        sx={{ mb: 2, alignItems: 'center' }}
        severity="warning"
        action={<DeviceSelect hideIcon setMessage={setMessage} />}
      >
        {cloverStatus}
      </Alert>
      <SnackbarMessage message={message} setMessage={setMessage} />
    </>
  );
};

export default CloverWarning;
