import { Alert, Button, DialogActions, DialogContent, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { copyText, lang } from 'language';
import stores from 'dataAccess/api/stores.ts';
import {
  useAddToCartLoading,
  useCart,
  useSelectedShop,
  useSetAddToCartLoading,
  useSetCart,
} from 'contexts';
import { addToCart, checkForOutletDuplicates } from 'utils/cart';
import TextLoader from 'components/TextLoader';
import { shape, string } from 'prop-types';
import InventoryUpdateBtn from 'components/InventoryUpdateBtn/InventoryUpdateBtn';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import { useOktaAuth } from '@okta/okta-react';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';

const InventoryDialog = ({ product }) => {
  const cart = useCart();
  const setCart = useSetCart();
  const loading = useAddToCartLoading();
  const setLoading = useSetAddToCartLoading();
  const [open, setOpen] = useState(false);
  const [inventory, setInventory] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [disableAddToCart, setDisableAddToCart] = useState(false);
  const [message, setMessage] = useState('');
  const selectedShop = useSelectedShop();
  const { oktaAuth } = useOktaAuth();

  const getAvailableQuantity = (item) => {
    if (item.availableQuantity) {
      return `${copyText.App.available}: ${item.availableQuantity}`;
    }
    return copyText.Cart.CartTools.outOfStock;
  };

  const handleAddToCart = async () => {
    try {
      const salesAssociateId = oktaAuth.authStateManager?._authState?.idToken?.claims?.email;
      setLoading(true);
      const updatedCart = await addToCart(product, cart, salesAssociateId, selectedShop);
      setCart(updatedCart);
      setLoading(false);
      setMessage(copyText.Shop.PDP.addToCart.success);
    } catch (error) {
      setLoading(false);
    }
  };

  const mapChannelToStore = async () => {
    const storeData = await stores.getStores();
    if (product && product?.availability?.channels) {
      let storeInventory = storeData?.map((store) => {
        const channelId = store?.supplyChannels?.[0]?.id;
        // TODO: Account for multiple supply channels
        const channel = product.availability.channels[channelId];
        return { ...store, ...channel };
      });

      storeInventory = storeInventory.sort(
        (a, b) => (b.availableQuantity ?? 0) - (a.availableQuantity ?? 0),
      );
      setInventory(storeInventory);
    }
  };

  const populateDisable = () => {
    const hasOutletDuplicates = checkForOutletDuplicates(cart, product, selectedShop);
    if (hasOutletDuplicates) {
      setDisableAddToCart(true);
    }
    if (!hasOutletDuplicates) {
      setDisableAddToCart(false);
    }
  };

  useEffect(() => {
    mapChannelToStore();
    populateDisable();
  }, [product]);

  return (
    <DialogTemplate
      openText={copyText.Cart.CartTools.viewPickupOptions}
      open={open}
      setOpen={setOpen}
      dialogTitle={copyText.Cart.CartTools.pickupAvailability}
      subtitle={copyText.Cart.CartTools.selectLocation}
    >
      <DialogContent>
        <Alert severity="warning" sx={{ mb: 2, mt: 2 }}>
          {copyText.Cart.CartTools.alwaysCallAhead}
        </Alert>
        <InventoryUpdateBtn disableAddToCart={disableAddToCart} />
        <Box sx={{ maxHeight: '300px', overflow: 'scroll' }} data-testid="inventory_availability">
          {inventory?.map((item) => {
            return (
              <MenuItem
                selected={item.key === selectedStore.key}
                key={item.id}
                value={item.key}
                onClick={() => setSelectedStore(item)}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Box>
                  <Typography fontWeight="bold">{item.name?.[lang]}</Typography>
                  {item.key === selectedStore.key && (
                    <Typography variant="subtitle2">
                      {item.phone ?? copyText.Cart.CartTools.phoneNumberMissing}
                    </Typography>
                  )}
                  <Typography variant="subtitle2">{item.key}</Typography>
                </Box>
                <Typography variant="subtitle2">{getAvailableQuantity(item)}</Typography>
              </MenuItem>
            );
          })}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="contained"
          disabled={!selectedStore || loading || disableAddToCart}
          onClick={handleAddToCart}
          data-testid="add_to_cart_button"
        >
          <TextLoader loading={loading} size={20} text={copyText.Cart.CartTools.addPickupToCart} />
        </Button>
      </DialogActions>
      <SnackbarMessage message={message} setMessage={setMessage} />
    </DialogTemplate>
  );
};

InventoryDialog.propTypes = {
  product: shape({
    sku: string.isRequired,
  }).isRequired,
};

export default InventoryDialog;
