import React, { useState, useEffect } from 'react';
import { copyText, lang } from 'language';
import { Typography, Box, ListItem, Divider, Button } from '@mui/material';
import { arrayOf, func, number, shape } from 'prop-types';
import convertCentToDollar from 'utils/convertCentToDollar';

const ExchangeItems = (props) => {
  const { order, loadOrder } = props;
  const { returnInfo, lineItems } = order;

  const receiptStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    mt: 2,
    p: 0,
  };
  const [exchangeItems, setExchangeItems] = useState([]);
  const [exchangeItemData, setExchangeItemData] = useState([]);
  const [exchangeOrderLineItems, setExchangeOrderLineItems] = useState([]);
  const formatExchangeItems = (returnData) => {
    if (returnData?.length) {
      const itemReturnDetails = returnData?.map((returns) => {
        return returns.items.filter((returnLineItems) => {
          return returnLineItems?.custom?.fields?.type === 'Exchange';
        });
      });
      return itemReturnDetails?.flat();
    }
    return [];
  };

  const populateExchangeItems = () => {
    setExchangeItems(formatExchangeItems(returnInfo));
  };

  const filterLineItems = (exchangeArr, lineItemArr) => {
    const filteredItemData = exchangeArr?.map((exchangeItem) => {
      return lineItemArr.map((item) => {
        if (item.id === exchangeItem.lineItemId) {
          const itemObj = { exchangeInfo: exchangeItem };
          itemObj.itemInfo = item;
          return itemObj;
        }
        return null;
      });
    });
    return filteredItemData.flat().filter(Boolean);
  };

  const populateExchangeItemData = () => {
    const filteredItems = filterLineItems(exchangeItems, lineItems);
    setExchangeItemData(filteredItems);
  };

  const formatExchangeOrderLineItems = (itemsJson) => {
    const exchangeLineItems = JSON.parse(itemsJson);
    return exchangeLineItems;
  };

  const populateExchangeOrderLineItems = () => {
    if (order?.custom?.fields?.exchange_order_line_items || order.is_exchange_order) {
      setExchangeOrderLineItems(
        formatExchangeOrderLineItems(
          order?.custom?.fields?.exchange_order_line_items || order.exchange_order_line_items,
        ),
      );
    }
  };

  const displayExchangeFields = () => {
    return (
      <>
        {/* <Divider sx={{ mt: 2 }} /> */}
        <ListItem sx={receiptStyles}>
          <Typography variant="p" component="strong" align="left">
            {copyText.Cart.receipt.exchangeItems}
          </Typography>
          <Typography variant="p" component="strong" align="right">
            {copyText.Cart.receipt.exchangeCredit}
          </Typography>
        </ListItem>
        <Divider sx={{ mt: 2 }} />
      </>
    );
  };

  useEffect(() => {
    if (order?.custom?.fields?.is_exchange_order || order.is_exchange_order) {
      populateExchangeOrderLineItems();
    } else {
      populateExchangeItems();
    }
  }, []);

  useEffect(() => {
    populateExchangeItemData();
  }, [exchangeItems]);

  return (
    <>
      {returnInfo?.length !== 0 && displayExchangeFields()}
      {returnInfo?.length !== 0 &&
        exchangeItemData?.map((item) => {
          return (
            <ListItem key={item?.id} sx={receiptStyles}>
              <Box>
                <Typography variant="p" component="strong" align="left">
                  {item?.itemInfo?.name[lang]}
                </Typography>
                <br />

                <Typography align="left" display="flex">
                  {item?.itemInfo?.name[lang]} x {item?.exchangeInfo?.quantity}
                </Typography>

                <Typography variant="p" display="flex">
                  {`${copyText.Cart.receipt.sku}: ${item?.itemInfo?.variant?.sku}`}
                </Typography>
                <Typography variant="p">
                  {`${copyText.Cart.receipt.exchangeOrderNumber}: `}
                </Typography>
                <Button
                  size="large"
                  onClick={() => {
                    loadOrder(item?.exchangeInfo?.custom?.fields?.exchange_order_number);
                  }}
                >
                  {item?.exchangeInfo?.custom?.fields?.exchange_order_number}
                </Button>
              </Box>
              <Typography align="right">
                ({convertCentToDollar(item?.itemInfo?.taxedPrice?.totalGross?.centAmount)})
              </Typography>
            </ListItem>
          );
        })}
      {(order.is_exchange_order || order?.custom?.fields?.is_exchange_order) &&
        displayExchangeFields()}
      {(order.is_exchange_order || order?.custom?.fields?.is_exchange_order) &&
        exchangeOrderLineItems?.map((item) => {
          return (
            <ListItem key={item?.id} sx={receiptStyles}>
              <Box>
                <Typography variant="p" component="strong" align="left">
                  {item?.name}
                </Typography>
                {item?.custom?.fields?.final_sale && (
                  <Typography color="error" variant="p" ml={1} component="strong" align="left">
                    {copyText.Cart.CartTools.finalSale}
                  </Typography>
                )}
                {item?.custom?.fields?.floor_model && (
                  <Typography variant="p" color="error" ml={1} component="strong" align="left">
                    {copyText.Cart.CartTools.floorModel}
                  </Typography>
                )}
                <br />

                <Typography align="left" display="flex">
                  {item?.name} x {item?.quantity}
                </Typography>
                <Typography variant="p" display="flex">
                  {`${copyText.Cart.receipt.sku}: ${item?.sku}`}
                </Typography>
                <Typography variant="p">
                  {`${copyText.Cart.receipt.originalOrderNumber}: `}
                </Typography>
                <Button
                  size="large"
                  onClick={() => {
                    loadOrder(
                      order?.custom?.fields?.original_order_number || order.original_order_number,
                    );
                  }}
                >
                  {order?.custom?.fields?.original_order_number || order.original_order_number}
                </Button>
              </Box>
              <Typography align="right">
                ({convertCentToDollar(item?.exchangeValue?.centAmount)})
              </Typography>
            </ListItem>
          );
        })}
    </>
  );
};

ExchangeItems.propTypes = {
  order: shape({
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    lineItems: arrayOf(shape({})).isRequired,
    returnInfo: arrayOf(shape({})).isRequired,
  }).isRequired,
  loadOrder: func.isRequired,
};

export default ExchangeItems;
