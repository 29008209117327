import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import {
  useCart,
  useSetCart,
  useSetShowPendingPaymentWarning,
  useShowPendingPaymentWarning,
  useOpenCheckoutStepper,
} from 'contexts';
import { clearCartNoStatusUpdate } from 'utils/cart';
import TextLoader from 'components/TextLoader';
import { copyText } from 'language';
import { getAgentEmail } from 'utils/agentUtils';
import pendingPaymentUtils from './utils/pendingPaymentUtils';

const PendingPaymentAlert = () => {
  const cart = useCart();
  const setCart = useSetCart();
  const showPendingPaymentWarning = useShowPendingPaymentWarning();
  const [loading, setLoading] = useState(false);
  const setShowPendingPaymentWarning = useSetShowPendingPaymentWarning();
  const openCheckoutStepper = useOpenCheckoutStepper();
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    pendingPaymentUtils.checkCartPendingPayments({
      cart,
      setShowPendingPaymentWarning,
      openCheckoutStepper,
    });
  }, [cart]);

  return (
    <Dialog open={showPendingPaymentWarning}>
      <DialogTitle>{copyText.App.warning}</DialogTitle>
      <DialogContent>{copyText.App.pendingPaymentWarning}</DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="outlined"
          sx={{ mr: 3 }}
          onClick={() => {
            clearCartNoStatusUpdate({
              setCart,
              setLoading,
              salesAssociateId: getAgentEmail(
                oktaAuth?.authStateManager?._authState?.idToken?.claims?.email,
              ),
            });
          }}
        >
          <TextLoader
            loading={loading}
            disabled={loading}
            text={copyText.App.notRightNowLoadNewCart}
            size={20}
          />
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          onClick={() => setShowPendingPaymentWarning(false)}
        >
          {copyText.App.continue}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PendingPaymentAlert;
