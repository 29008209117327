import { List, ListItem } from '@mui/material';
import copyText from 'language/enUS';
import ordersService from 'dataAccess/api/orders.ts';
import storeLocation from 'utils/storeLocation';

// Once we have controls for this we can dynamically change the sort order
const sortBy = 'createdAt';
const sortOrder = 'desc';

const legend = [
  { icon: 'RET', label: copyText.Orders.OrderSearch.types.return },
  { icon: 'EX', label: copyText.Orders.OrderSearch.types.exchange },
  { icon: 'DRAFT', label: copyText.Orders.OrderSearch.types.completedDraft },
  { icon: 'OEX', label: copyText.Orders.OrderSearch.types.originalExchange },
];
const populateLegend = () => {
  return (
    <List data-testid="orders-search-legend">
      {legend.map((item) => {
        return (
          <ListItem key={item.label}>
            {item.icon}: {item.label}
          </ListItem>
        );
      })}
    </List>
  );
};

const getFilteredOrders = async ({ filters, setLoading, setFoundOrderData, setFilters }) => {
  setLoading(true);
  setFilters(filters);
  const response = await ordersService.getFilteredOrders(filters);
  setFoundOrderData(response);
  setLoading(false);
  return response;
};

const getByOrderNumber = async (orderNumber, setCurrentPage, setFoundOrderData) => {
  setCurrentPage(1);
  const result = await ordersService.getByOrderNumber(orderNumber);
  if (result?.order) {
    setFoundOrderData({ results: [result.order], total: 1 });
    return result.order;
  }
  setFoundOrderData({ results: [], total: 0 });
  return { results: [], total: 0 };
};

const getTodaysStoreOrders = async ({ filters, setLoading, setFoundOrderData, setFilters }) => {
  const todaysDate = new Date().toISOString().split('T')[0];
  const storeKey = storeLocation.getStoreKey();
  const newFilters = { ...filters, from: todaysDate, storeKey };
  const result = await getFilteredOrders({
    filters: newFilters,
    setLoading,
    setFoundOrderData,
    setFilters,
  });
  return result;
};

const isEmail = (input) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+$/;
  return emailRegex.test(input);
};

const hasFiltersAndInput = (filters, orderInput) => {
  const noFilters = Object.values(filters).every(
    (filter) => filter === undefined || filter?.length === 0,
  );
  return !noFilters && orderInput !== '';
};

const handlePageChange = async ({
  page,
  filters,
  setLoading,
  setFoundOrderData,
  setFilters,
  setCurrentPage,
}) => {
  setLoading(true);
  // eslint-disable-next-line no-param-reassign
  filters.page = page + 1;
  setCurrentPage(filters.page);
  setFilters(filters);
  try {
    const response = await ordersService.getFilteredOrders(filters);
    setFoundOrderData(response);
    setLoading(false);
    return response;
  } catch (error) {
    setLoading(false);
    throw new Error('Error fetching orders', error);
  }
};

const handleInput = async ({
  event,
  setOrderInput,
  filters,
  setFilters,
  setFoundOrderData,
  setLoading,
}) => {
  setOrderInput(event.target.value);
  const newFilters = {
    ...filters,
    customerEmail: event.target.value ? event.target.value : undefined,
    sortBy,
    sortOrder,
  };
  if (!hasFiltersAndInput(newFilters, event.target.value)) {
    await getTodaysStoreOrders({ filters: newFilters, setLoading, setFoundOrderData, setFilters });
    return;
  }
  if (event.target.value === '') {
    await getFilteredOrders({ filters: newFilters, setLoading, setFoundOrderData, setFilters });
  }
};

const handleSearch = async ({
  orderInput,
  filters,
  setFilters,
  setCurrentPage,
  setFoundOrderData,
  setLoading,
}) => {
  if (!isEmail(orderInput)) {
    const result = await getByOrderNumber(orderInput, setCurrentPage, setFoundOrderData);
    return result;
  }
  const newFilters = { ...filters, customerEmail: orderInput, sortBy, sortOrder };
  const result = await getFilteredOrders({
    filters: newFilters,
    setLoading,
    setFoundOrderData,
    setFilters,
  });
  return result;
};

const searchFilters = ({
  filters,
  selectedStore,
  orderInput,
  setOrderInput,
  setLoading,
  setFoundOrderData,
  setFilters,
  closeFilterModal,
}) => {
  const { exchangeFilter, returnFilter, originalFilter, draftFilter } = filters;
  const today = new Date();
  const defaultDate = new Date(new Date().setDate(today.getDate() - 90));
  const orderType = [exchangeFilter, returnFilter, originalFilter, draftFilter].filter(
    (type) => type !== '' && type !== undefined,
  );
  const newFilters = {
    orderType,
    storeKey: selectedStore?.key ? selectedStore.key : undefined,
    customerEmail: isEmail(orderInput) ? orderInput : undefined,
    sortBy,
    sortOrder,
  };
  if (!isEmail(orderInput)) {
    setOrderInput('');
  }
  if (!hasFiltersAndInput(newFilters, orderInput)) {
    getFilteredOrders({
      filters: { ...newFilters, from: defaultDate },
      setLoading,
      setFoundOrderData,
      setFilters,
    });
    closeFilterModal();
    return;
  }
  getFilteredOrders({ filters: newFilters, setLoading, setFoundOrderData, setFilters });
  closeFilterModal();
};

export default {
  legend,
  populateLegend,
  getFilteredOrders,
  getByOrderNumber,
  getTodaysStoreOrders,
  isEmail,
  handlePageChange,
  handleInput,
  handleSearch,
  searchFilters,
};
