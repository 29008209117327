const getRemainingFunds = (order) => {
  const orderPayments = order?.paymentInfo?.payments;
  // Go through each payment and its transactions to get the total amount refunded
  if (!orderPayments) {
    return 0;
  }
  const totalRefunds = orderPayments.reduce((acc, payment) => {
    const transactions = payment?.obj?.transactions;
    let refundTotalAmount = 0;
    transactions.forEach((transaction) => {
      if (transaction.type === 'Refund') {
        refundTotalAmount += transaction.amount.centAmount;
      }
      return refundTotalAmount;
    });
    return acc + refundTotalAmount;
  }, 0);
  const totalGrossAmount = order.taxedPrice?.totalGross?.centAmount || 0;
  const amountRemaining = totalGrossAmount - totalRefunds;
  return amountRemaining;
};

export default { getRemainingFunds };
