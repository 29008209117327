import { Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { bool, func, shape, string } from 'prop-types';
import { copyText } from 'language';
import TextLoader from 'components/TextLoader';
import { useCart, useSetCart } from 'contexts';
import chargeAfterUtils from '../chargeAfterUtils';

const SendApplication = ({
  goBack,
  setState,
  applicationDetails,
  setApplicationDetails,
  errorMessage,
  setErrorMessage,
  loading,
  setLoading,
  setOpenSnackbar,
  setSnackbarMessage,
}) => {
  const cart = useCart();
  const setCart = useSetCart();
  const applicationTypes = [
    {
      name: copyText.Cart.CartTools.ChargeAfter.email,
      value: 'email',
      disabled: false,
    },
    // SUNSET: add sms functionality when api is ready
    {
      name: copyText.Cart.CartTools.ChargeAfter.sms,
      value: 'sms',
      disabled: true,
    },
    // SUNSET: add qr code functionality
    {
      name: 'QR Code',
      value: 'qr',
      disabled: true,
    },
  ];

  return (
    <>
      <Typography data-testid="ca-send-app-title">
        {copyText.Cart.CartTools.ChargeAfter.transmitMethodQuery}
      </Typography>
      <FormControl fullWidth sx={{ marginBottom: 2, mt: 2 }}>
        <InputLabel id="charge-after-app-type">
          {copyText.Cart.CartTools.ChargeAfter.applicationMethod}
        </InputLabel>
        <Select
          labelId="charge-after-app-type"
          value={applicationDetails?.transmitMethod}
          label={copyText.Cart.CartTools.ChargeAfter.applicationMethod}
        >
          {applicationTypes?.map((type) => {
            return (
              <MenuItem
                disabled={type.disabled}
                onClick={() =>
                  chargeAfterUtils.updateApplication({
                    setApplicationDetails,
                    item: 'transmitMethod',
                    change: type.value,
                  })
                }
                key={type.value}
                value={type.value}
              >
                {type?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {applicationDetails?.transmitMethod && (
        <>
          <Typography fontWeight={700}>
            {`${copyText.Cart.CartTools.ChargeAfter.transmitMethod} ${
              applicationDetails?.transmitMethod
            } ${copyText.Cart.CartTools.ChargeAfter.to} ${chargeAfterUtils.populateTransmitMethod({
              applicationDetails,
            })}.`}
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            {copyText.Cart.CartTools.ChargeAfter.updateIncorrectInfo}
          </Typography>
        </>
      )}
      <Grid container display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Grid item xs={16} display="flex" justifyContent="center">
          <Button variant="outlined" onClick={goBack} sx={{ mr: 3 }} disabled={loading}>
            <TextLoader
              loading={loading}
              size={20}
              text={copyText.Cart.CartTools.ChargeAfter.goBack}
            />
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              chargeAfterUtils.handleSendApplication({
                cartId: cart?.id,
                setCart,
                setLoading,
                setState,
                setErrorMessage,
                applicationDetails,
                setOpenSnackbar,
                setSnackbarMessage,
              })
            }
            disabled={!applicationDetails?.transmitMethod || loading}
          >
            <TextLoader
              loading={loading}
              size={20}
              text={copyText.Cart.CartTools.ChargeAfter.sendLink}
            />
          </Button>
        </Grid>
        {errorMessage && (
          <Typography sx={{ mt: 3 }} color="error" variant="subtitle2" textAlign="center">
            {copyText.Cart.CartTools.ChargeAfter.sendLinkFailure}
          </Typography>
        )}
      </Grid>
    </>
  );
};

SendApplication.propTypes = {
  goBack: func.isRequired,
  setState: func.isRequired,
  setOpenSnackbar: func.isRequired,
  setSnackbarMessage: func.isRequired,
  loading: bool.isRequired,
  setLoading: func.isRequired,
  applicationDetails: shape({
    mobilePhoneNumber: string,
    transmitMethod: string,
  }),
  setApplicationDetails: func.isRequired,
  setErrorMessage: func.isRequired,
  errorMessage: string.isRequired,
};
SendApplication.defaultProps = {
  applicationDetails: {
    mobilePhoneNumber: '',
    transmitMethod: 'email',
  },
};
export default SendApplication;
