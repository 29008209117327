import React from 'react';
import { useSelectedOrder } from 'contexts/orders';
import { Typography, List, ListItem } from '@mui/material';
import { copyText } from 'language';
import CartCard from 'components/CartCard/CartCard';
import returnsAndExchanges from 'utils/returnsAndExchanges';
import constants from 'utils/constants';

const OrderItems = () => {
  const selectedOrder = useSelectedOrder();
  const displayItemData = () => {
    const updatedLineItems = returnsAndExchanges.checkLineItemAvailability(selectedOrder);
    return updatedLineItems.map((item) => {
      const customItemDetails = selectedOrder?.customLineItems.filter((customItem) => {
        const lineItemIds = customItem.custom?.fields?.lineItemsIds;
        if (lineItemIds) {
          return lineItemIds.includes(item.id);
        }
        return false;
      });

      return (
        <ListItem key={item.id}>
          <CartCard
            type={constants.CART_CARD_ORDER}
            key={item.id}
            productData={item}
            readOnly
            showFulfillmentMethod
            customData={customItemDetails[0]}
          />
        </ListItem>
      );
    });
  };

  return (
    <>
      <Typography variant="subHeader" component="h1">
        {copyText.Orders.OrderData.orderBreakdown}
      </Typography>
      <List sx={{ overflow: 'scroll', maxHeight: '58vh' }}>{displayItemData()}</List>
    </>
  );
};

export default OrderItems;
