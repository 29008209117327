import {
  Drawer,
  List,
  ListItem,
  Box,
  Badge,
  Typography,
  Divider,
  Button,
  Link,
  Grid,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { checkForDuplicateLineItemSkus } from 'utils/cart';
import EmptyCart from 'views/Cart/Components/LeftColumn/components/EmptyCart';
import copyText from 'language/enUS';
import { useCart } from 'contexts';
import CartCard from 'components/CartCard/CartCard';
import OrderTotal from 'components/OrderTotal/OrderTotal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import constants from 'utils/constants';

const CartDrawer = () => {
  const history = useHistory();
  const location = useLocation();
  const [count, setCount] = useState(0);
  const cart = useCart();
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleSelectCart = () => {
    history.push('/cart');
    setOpenDrawer(false);
  };
  useEffect(() => {
    setCount(cart?.totalLineItemQuantity || 0);
  }, [cart]);
  return (
    <>
      <Box>
        <Badge badgeContent={count} color="primary">
          <ShoppingCartIcon
            onClick={() => (location.pathname !== '/cart' ? setOpenDrawer(true) : null)}
            fontSize="large"
          />
        </Badge>
      </Box>
      <Drawer anchor="right" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <section role="main">
          <Box
            sx={{
              width: { xs: '100vw', sm: '60vw', md: '50vw', lg: '30vw' },
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            height="100vh"
          >
            <Box>
              <Grid
                container
                alignItems="center"
                display="flex"
                flexDirection="row"
                marginTop={3}
                marginBottom={2}
              >
                <Grid
                  item
                  columns={4}
                  marginLeft={3}
                  marginRight={3}
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={() => setOpenDrawer(false)}
                    aria-label={copyText.Cart.CartTools.closeCartPreview}
                  >
                    <ChevronRight />
                  </Button>
                </Grid>
                <Grid item columns={12}>
                  <Typography
                    padding={1}
                    display="flex"
                    justifyContent="center"
                    variant="h5"
                    component="h1"
                    alignItems="center"
                  >
                    {copyText.Cart.CartTools.cartPreview}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Box
                sx={{
                  position: 'relative',
                  overflow: 'scroll',
                  msOverflowStyle: 'none',
                  maxHeight: { sm: '71vh', md: '62vh', lg: '70vh' },
                }}
              >
                {cart?.lineItems?.length > 0 ? (
                  <List sx={{ mr: 3 }}>
                    {cart?.lineItems.map((lineItem, index) => {
                      return (
                        <ListItem key={lineItem.id}>
                          <CartCard
                            type={constants.CART_CARD_CART}
                            productData={lineItem}
                            showShippingMethod={checkForDuplicateLineItemSkus(
                              cart?.lineItems,
                              lineItem,
                              index,
                            )}
                            showPrice
                            showDelete
                            hideShippingMethod
                            inCartDrawer
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <EmptyCart />
                )}
              </Box>
            </Box>
            <Box>
              <Divider />
              <Box sx={{ p: 3 }}>
                <OrderTotal showOrderTotals={false} cart={cart} />
              </Box>
              <Divider />
              <Box textAlign="center" padding={3}>
                <Link underline="none" color="white" component={RouterLink} to="/cart">
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    sx={{
                      textTransform: 'none',
                      height: 56,
                      maxWidth: '35vw',
                    }}
                    onClick={handleSelectCart}
                  >
                    <Typography justifyContent="center" variant="p" component="span" color="white">
                      {copyText.Cart.CartTools.viewCart}
                    </Typography>
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        </section>
      </Drawer>
    </>
  );
};

export default CartDrawer;
