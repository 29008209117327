import { Button, Chip, Grid, FormLabel } from '@mui/material';
import { useSetOrderPayment, useSetSelectedOrder } from 'contexts/orders';
import orders from 'dataAccess/api/orders.ts';
import { copyText } from 'language';
import { bool, shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';

const ExchangeOrderLink = ({ productData }) => {
  const history = useHistory();
  const setOrderPayment = useSetOrderPayment();
  const setSelectedOrder = useSetSelectedOrder();
  const handleSelectExchangeOrder = async (exchangeOrderNumber) => {
    const exchangeOrder = await orders.getByOrderNumber(exchangeOrderNumber);
    history.push(`/orders/order/${exchangeOrderNumber}`);
    setOrderPayment(null);
    setSelectedOrder(exchangeOrder?.order);
  };

  return (
    <Grid item>
      {productData.exchangeOrderNumbers.length !== 0 && (
        <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item>
            <Chip
              variant="warning"
              label={`${copyText.Cart.CartTools.returnedOrExchanged}: ${productData.numReturned}`}
            />
          </Grid>
          <Grid item>
            <FormLabel>{copyText.Cart.CartTools.exchangeOrders}:</FormLabel>
          </Grid>
          {productData.exchangeOrderNumbers.map((orderNumber) => {
            return (
              <Grid item key={orderNumber}>
                <Button onClick={() => handleSelectExchangeOrder(orderNumber)}>
                  {orderNumber}
                </Button>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Grid>
  );
};

ExchangeOrderLink.propTypes = {
  productData: shape({
    available: bool,
    exchangeOrderNumber: string,
  }),
};

ExchangeOrderLink.defaultProps = {
  productData: {
    available: true,
    exchangeOrderNumber: null,
  },
};

export default ExchangeOrderLink;
