import orders from 'dataAccess/api/orders.ts';
import cartUtils from 'dataAccess/api/cart.ts';
import shippingAddressApi from 'dataAccess/api/cart.addresses.ts';
import { copyText } from 'language';
import { CircularProgress } from '@mui/material';
import { Close } from '@mui/icons-material';

const getByOrderId = async (orderId) => {
  const result = await orders.getByOrderNumber(orderId);
  return result.order;
};

const preEvent = async ({ orderId, setLoading, setProcessIcon, setProcessStatus }) => {
  if (orderId) {
    try {
      setLoading(true);
      setProcessIcon(<CircularProgress indeterminate size={14} />);
      setProcessStatus(copyText.Orders.Actions.exchangeInstructions.creatingExchangeCart);
    } catch (error) {
      setProcessStatus(copyText.Orders.Actions.exchangeInstructions.createExchangeCartFailure);
      setProcessIcon(<Close />);
    } finally {
      setLoading(false);
    }
  } else {
    setProcessStatus(copyText.Orders.Actions.exchangeInstructions.createExchangeCartOrderFailure);
    setProcessIcon(<Close />);
    setLoading(false);
  }
};

const markAsExchange = async ({ newCart, orderId }) => {
  const originalOrder = await getByOrderId(orderId);
  const originalOrderId = originalOrder.id;
  const originalOrderNumber = orderId;
  if (originalOrderId && originalOrderNumber) {
    const updatedCart = await cartUtils.markAsExchangeCart(
      newCart.id,
      originalOrderId,
      originalOrderNumber,
    );
    return updatedCart;
  }
  throw new Error('unable to get order by ID');
};

const usePrevAddress = async ({
  cart,
  orderId,
  setCart,
  setProcessStatus,
  setProcessIcon,
  setOpen,
  setExchangeModalStep,
}) => {
  const newCart = cart;
  const originalOrder = await getByOrderId(orderId);
  const address = {
    ...originalOrder?.shippingAddress,
    streetAddress: originalOrder?.shippingAddress?.streetName,
  };
  try {
    const result = await shippingAddressApi.setShippingAddress(
      newCart?.id,
      newCart,
      address,
      false,
    );
    await setCart(result);
    setProcessStatus(null);
    setProcessIcon(null);
    setOpen(false);
    setExchangeModalStep(0);
    setTimeout(() => {
      window.location.href = '/shop/retail';
    }, 1000);
  } catch (error) {
    setProcessStatus(copyText.Orders.Actions.exchangeInstructions.shippingAddressFailure);
    setOpen(false);
    setExchangeModalStep(0);
    setTimeout(() => {
      window.location.href = '/shop/retail';
    }, 1000);
  }
};

const openModal = ({ remainingFunds, setOpen, setProcessStatus, setProcessIcon }) => {
  if (remainingFunds > 0) {
    setOpen(true);
  } else {
    setOpen(true);
    setProcessStatus(copyText.Orders.Actions.exchangeInstructions.insufficientFunds);
    setProcessIcon(<Close />);
  }
};

const closeModal = (setOpen, setExchangeModalStep) => {
  setOpen(false);
  setExchangeModalStep(0);
  setTimeout(() => {
    window.location.href = '/shop/retail';
  }, 1000);
};

export default { getByOrderId, preEvent, markAsExchange, usePrevAddress, openModal, closeModal };
