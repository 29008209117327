import { Button } from '@mui/material';
import { copyText } from 'language';
import { number, func, string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

const ReviewSubmitButton = ({ activeStep, handleNext, handleSubmit, orderNumber }) => {
  if (activeStep < 2) {
    return (
      <Button variant="contained" onClick={handleNext}>
        {copyText.Orders.Returns.next}
      </Button>
    );
  }
  if (activeStep === 2) {
    return (
      <Button variant="contained" onClick={handleSubmit}>
        {copyText.Orders.Returns.submit}
      </Button>
    );
  }
  return (
    <Button component={RouterLink} variant="contained" to={`/orders/order/${orderNumber}`}>
      {copyText.Orders.Returns.finish}
    </Button>
  );
};

ReviewSubmitButton.propTypes = {
  activeStep: number.isRequired,
  handleNext: func.isRequired,
  handleSubmit: func.isRequired,
  orderNumber: string.isRequired,
};

export default ReviewSubmitButton;
