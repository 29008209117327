import { Button, IconButton } from '@mui/material';

const getOpenButtonStyle = ({
  handleOpen,
  openIcon,
  noButton,
  iconButton,
  openText,
  openBtnColor,
  openBtnPadding,
  openBtnMargin,
  openBtnVariant,
  precedingIcon,
}) => {
  if (noButton) {
    return null;
  }
  if (iconButton) {
    return <IconButton onClick={handleOpen}>{openIcon}</IconButton>;
  }
  return (
    <Button
      color={openBtnColor}
      sx={{ padding: openBtnPadding, margin: openBtnMargin }}
      variant={openBtnVariant}
      onClick={handleOpen}
      startIcon={precedingIcon}
    >
      {openText}
    </Button>
  );
};

const handleClose = async ({ reason, setLoading, onClose, setOpen, setMessage }) => {
  // Prevent closing modal by clicking/tapping outside of modal
  if (reason === 'backdropClick') return;
  try {
    setLoading(true);
    if (onClose) {
      await onClose();
    }
    setOpen(false);
  } catch (err) {
    setMessage(err.message);
  } finally {
    setLoading(false);
  }
};

const handleOpen = ({ onOpen, setOpen }) => {
  if (onOpen) {
    onOpen();
  }
  setOpen(true);
};

export default {
  getOpenButtonStyle,
  handleClose,
  handleOpen,
};
