import React, { useEffect } from 'react';
import {
  Typography,
  Grid,
  Alert,
  Button,
  Box,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { copyText } from 'language';
import { useCart, useSetCart, useSetCheckoutStepperActiveStep } from 'contexts';
import TextLoader from 'components/TextLoader';
import checkoutStepperUtils from 'utils/checkoutStepperUtils';
import usStateCode2Char from 'utils/UsStateCodes';
import shippingInfoInputUtils from 'utils/shippingInfoInputUtils';
import AddressAutocomplete from 'components/AddressAutocomplete/AddressAutocomplete';
import { arrayOf, bool, func, shape, string } from 'prop-types';

const CustomerInfo = ({
  formData,
  setFormData,
  showErrorMessage,
  setShowErrorMessage,
  loading,
  setLoading,
  disableUseStoreAddress,
  disableNextButton,
  setDisableNextButton,
  setDraftOrderActiveStep,
  setError,
  setShowMsg,
  draftOrder,
  invalidFields,
  setInvalidFields,
}) => {
  const cart = useCart();
  const setCart = useSetCart();
  const setCheckoutStepperActiveStep = useSetCheckoutStepperActiveStep();

  useEffect(() => {
    if (cart?.is_exchange_order) {
      shippingInfoInputUtils.populateExchangeShippingEmail({
        cart,
        setFormData,
        formData,
      });
    }
  }, [cart]);

  return (
    <Box>
      <Box id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {!draftOrder && (
          <Typography variant="h6">{copyText.Cart.CheckoutStepper.customerInfo}</Typography>
        )}
        <Box display="flex" alignItems="center">
          <Button
            onClick={() =>
              checkoutStepperUtils.handleUseStoreAddress({
                cart,
                setLoading,
                setCart,
                setFormData,
                setError,
                setShowMsg,
              })
            }
            disabled={disableUseStoreAddress || loading === true}
          >
            <TextLoader
              text={copyText.Cart.CartTools.useStoreAddress}
              loading={loading}
              size={20}
            />
          </Button>
          <Tooltip disableFocusListener title={copyText.Cart.CartTools.pickupCarryOutOnly}>
            <InfoOutlined sx={{ color: 'gray' }} />
          </Tooltip>
        </Box>
      </Box>
      <Box>
        <Grid container rowSpacing={{ sm: 2 }} columnSpacing={{ sm: 2 }}>
          <Grid item sm={6} sx={{ mb: 1, mt: 3 }}>
            <TextField
              label={copyText.Cart.CartTools.firstName}
              placeholder={copyText.Cart.CartTools.customerFirstName}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  formData,
                  setFormData,
                })
              }
              name="firstName"
              fullWidth
              value={formData?.firstName}
              error={invalidFields?.includes('firstName')}
              helperText={
                invalidFields?.includes('firstName')
                  ? `${copyText.App.invalid} ${copyText.Cart.CartTools.firstName}`
                  : ''
              }
            />
          </Grid>
          <Grid item sm={6} sx={{ mb: 1, mt: 3 }}>
            <TextField
              label={copyText.Cart.CartTools.lastName}
              placeholder={copyText.Cart.CartTools.customerLastName}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  formData,
                  setFormData,
                })
              }
              error={invalidFields?.includes('lastName')}
              helperText={
                invalidFields?.includes('lastName')
                  ? `${copyText.App.invalid} ${copyText.Cart.CartTools.lastName}`
                  : ''
              }
              name="lastName"
              fullWidth
              value={formData?.lastName}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              label={copyText.Cart.CartTools.email}
              placeholder={copyText.Cart.CartTools.email}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  formData,
                  setFormData,
                })
              }
              name="email"
              fullWidth
              disabled={cart?.is_exchange_order}
              value={formData?.email}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              label={copyText.Cart.CartTools.phoneNumber}
              placeholder={copyText.Cart.CartTools.phoneNumber}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  setFormData,
                  formData,
                })
              }
              name="phone"
              fullWidth
              value={formData?.phone}
              error={invalidFields?.includes('phone')}
            />
          </Grid>
          <Grid item sm={12} sx={{ mt: 2 }}>
            <Typography>
              <strong>{copyText.Cart.CartTools.deliveryAddress}</strong>
            </Typography>
            <Divider />
          </Grid>
          <Grid item sm={12} sx={{ mb: 1 }} display="flex" justifyContent="flex-end">
            <AddressAutocomplete
              onSelect={(place) =>
                shippingInfoInputUtils.onAutoComplete({
                  setInvalidFields,
                  place,
                  formData,
                  setFormData,
                })
              }
            />
          </Grid>
          <Grid item sm={12}>
            <TextField
              label={copyText.Cart.CartTools.streetAddressSteNumber}
              placeholder={copyText.Cart.CartTools.streetAddressSteNumber}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  setFormData,
                  formData,
                })
              }
              name="streetAddress"
              fullWidth
              value={formData?.streetAddress}
              error={invalidFields?.includes('streetAddress')}
              helperText={invalidFields?.includes('streetAddress') ? 'invalid field' : ''}
            />
          </Grid>
          <Grid item sm={8}>
            <TextField
              placeholder={copyText.Cart.CartTools.additionalStreetInfo}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  formData,
                  setFormData,
                })
              }
              name="additionalStreetInfo"
              fullWidth
              value={formData.additionalStreetInfo}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label={copyText.Cart.CartTools.city}
              placeholder={copyText.Cart.CartTools.city}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  setFormData,
                  formData,
                })
              }
              name="city"
              fullWidth
              value={formData?.city}
              error={invalidFields?.includes('city')}
            />
          </Grid>
          <Grid item sm={4}>
            <FormControl fullWidth>
              <InputLabel id="shipping-state-select-label">
                {copyText.Cart.CartTools.state}
              </InputLabel>
              <Select
                labelId="shipping-state-select-label"
                id="shipping-state-select"
                label={copyText.Cart.CartTools.state}
                fullWidth
                value={formData?.state}
              >
                {usStateCode2Char.map((code) => {
                  return (
                    <MenuItem
                      onClick={() =>
                        shippingInfoInputUtils.updateSelectValue({
                          key: 'state',
                          value: code,
                          setFormData,
                        })
                      }
                      value={code}
                      key={code}
                    >
                      {code}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4}>
            <TextField
              label={copyText.Cart.CartTools.country}
              placeholder={copyText.Cart.CartTools.country}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  setFormData,
                  formData,
                })
              }
              name="country"
              fullWidth
              value={formData?.country}
              disabled
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              label={copyText.Cart.CartTools.postalCode}
              placeholder={copyText.Cart.CartTools.postalCode}
              onChange={(e) =>
                shippingInfoInputUtils.updateForm({
                  e,
                  setFormData,
                  formData,
                })
              }
              name="postalCode"
              value={formData?.postalCode}
              fullWidth
            />
          </Grid>
        </Grid>
        {showErrorMessage && (
          <Box mt={4} display="flex" alignItems="center" justifyContent="center">
            <Alert severity="warning">
              {copyText.Cart.CartTools.addressValidation}
              <Button
                disabled={disableNextButton || loading}
                variant="text"
                size="small"
                sx={{ pl: 1, pr: 1 }}
                onClick={() =>
                  checkoutStepperUtils.updateCartWithShippingAddress({
                    cart,
                    formData,
                    validateShippingAddress: false,
                    setLoading,
                    setInvalidFields,
                    setCart,
                    setShowErrorMessage,
                    setCheckoutStepperActiveStep: draftOrder
                      ? setDraftOrderActiveStep
                      : setCheckoutStepperActiveStep,
                    setDisableNextButton,
                  })
                }
              >
                <TextLoader
                  text={copyText.Cart.CartTools.confirmAddress}
                  loading={loading}
                  size={10}
                />
              </Button>
            </Alert>
          </Box>
        )}
        <Box mt={4} display="flex" justifyContent="center">
          <Typography sx={{ fontSize: 14 }}>
            {copyText.Cart.CartTools.validAddressForWarranty}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CustomerInfo;

CustomerInfo.propTypes = {
  formData: shape({
    firstName: string,
    lastName: string,
    streetAddress: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    email: string,
  }).isRequired,
  setFormData: func.isRequired,
  showErrorMessage: bool.isRequired,
  setShowErrorMessage: func.isRequired,
  loading: bool.isRequired,
  setLoading: func.isRequired,
  disableUseStoreAddress: bool.isRequired,
  disableNextButton: bool,
  setDisableNextButton: func,
  setDraftOrderActiveStep: func,
  setError: func.isRequired,
  invalidFields: arrayOf(string).isRequired,
  setInvalidFields: func.isRequired,
  setShowMsg: func,
  draftOrder: bool,
};

CustomerInfo.defaultProps = {
  setDraftOrderActiveStep: () => {},
  draftOrder: false,
  disableNextButton: false,
  setDisableNextButton: () => {},
  setShowMsg: () => {},
};
