import { Button, IconButton, TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';

import './autocomplete.css';
import { copyText } from 'language';
import { Box } from '@mui/system';
import { Close } from '@mui/icons-material';
import { func } from 'prop-types';
import addressAutoCompleteUtils from './addressAutoCompleteUtils';

const AddressAutocomplete = ({ onSelect }) => {
  const [showSearch, setShowSearch] = useState(false);
  const autoCompleteRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    addressAutoCompleteUtils.autoCompleteStartup(
      inputRef,
      autoCompleteRef,
      onSelect,
      setShowSearch,
    );
  }, [showSearch]);

  return showSearch ? (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <TextField
        placeholder="Address Search"
        variant="outlined"
        inputRef={inputRef}
        fullWidth
        size="small"
      />
      <IconButton
        onClick={() => setShowSearch(false)}
        variant="contained"
        color="secondary"
        size="small"
      >
        <Close />
      </IconButton>
    </Box>
  ) : (
    <Button onClick={() => setShowSearch(true)} variant="outlined" color="primary" size="small">
      {copyText.Cart.CartTools.searchForAddress}
    </Button>
  );
};

AddressAutocomplete.propTypes = {
  onSelect: func.isRequired,
};

export default AddressAutocomplete;
