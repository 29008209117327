const getAccessToken = () => {
  const tokenStorage = sessionStorage.getItem('okta-token-storage');
  const oktaToken = tokenStorage && JSON.parse(tokenStorage).accessToken?.accessToken;
  if (oktaToken) {
    return oktaToken;
  }
  return null;
};

export default getAccessToken;
