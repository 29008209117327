import { React, useEffect, useState } from 'react';
import { Grid, Link } from '@mui/material';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import CategoryCard from 'components/CategoryCard/CategoryCard';
import { useShopCategories } from 'contexts';
import { lang } from 'language';

import placeholderImg from 'media/images/category_placeholder.png';
import ViewTransition from 'components/Transitions/ViewTransition';
import SkeletonGrid from '../../components/SkeletonGrid/SkeletonGrid';
import ShopHeader from '../../components/ShopHeader/ShopHeader';

import CategoriesUtils from './Categories.utils';

const CardsList = () => {
  const [currentCategories, setCurrentCategories] = useState();
  const categories = useShopCategories();
  const { url: baseUrl } = useRouteMatch();

  useEffect(() => {
    if (categories) {
      setCurrentCategories(CategoriesUtils.sortCategories(categories));
    }
  }, [categories]);

  if (!currentCategories) {
    return <SkeletonGrid numItems={6} />;
  }

  return currentCategories.map((category, ind) => {
    const key = category.id || ind;
    return (
      <ViewTransition direction="right" key={key}>
        <Grid item>
          <Link component={RouterLink} to={`${baseUrl}/category/${category.id}`} underline="none">
            <CategoryCard
              title={category?.name[lang]}
              imgUrl={CategoriesUtils?.categoryImages[category?.slug?.[lang]] ?? placeholderImg}
              imgAlt={`${category?.name[lang]} category image`}
            />
          </Link>
        </Grid>
      </ViewTransition>
    );
  });
};

const Categories = () => {
  return (
    <>
      <ShopHeader />
      <Grid
        container
        spacing={4}
        data-testid="category-grid"
        sx={{ justifyContent: 'space-evenly', mt: 2 }}
      >
        <CardsList />
      </Grid>
    </>
  );
};
export default Categories;
