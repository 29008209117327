// This util is to check if the user is currently at an outlet store
import storeLocation from './storeLocation';

const outletStores = ['UT-03', 'FL-04', 'GA-01', 'TX-02', 'TX-09', 'NV-01', 'NY-02'];

// eslint-disable-next-line import/prefer-default-export
export const checkOutletStore = () => {
  const currentStore = storeLocation.getLocationStorage();
  return outletStores.includes(currentStore?.key);
};
