import { Typography } from '@mui/material';
import { lang } from 'language';
import { useEffect, useState } from 'react';
import storeLocation from 'utils/storeLocation';
import { SquareOutlined } from '@mui/icons-material';
import copyText from 'language/enUS';
import { arrayOf, number, shape, string } from 'prop-types';

const PickListItem = ({ lineItem, order }) => {
  const [pickupStore, setPickupStore] = useState('test');
  const [street, setStreet] = useState('test');
  const [streetNumber, setStreetNumber] = useState('');
  const [city, setCity] = useState('city');
  const [state, setState] = useState('state');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');

  const getPickupDetails = async () => {
    const details = order.customLineItems?.filter((customLineItem) => {
      return customLineItem?.custom?.fields?.lineItemsIds?.includes(lineItem.id);
    });
    const storeKey = details[0]?.custom?.fields?.storeKey;
    const result = await storeLocation.getStoreByKey(storeKey);
    setPickupStore(result.name[lang]);
    const address = result.supplyChannels[0]?.obj?.address || null;
    if (address) {
      setStreet(address.streetName);
      setStreetNumber(address.streetNumber);
      setCity(address.city);
      setState(address.state);
      setPostalCode(address.postalCode);
      setCountry(address.country);
    }
  };
  useEffect(() => {
    getPickupDetails();
  }, []);
  return (
    <>
      <Typography display="flex">
        <SquareOutlined sx={{ mr: 1 }} />
        {lineItem.name[lang]} x {lineItem.quantity}
      </Typography>
      <Typography display="flex" align="left">
        {copyText.Cart.receipt.pickupAt}: {pickupStore}
      </Typography>
      <Typography display="flex" align="left">
        {street} {streetNumber}
      </Typography>
      <Typography display="flex" align="left">
        {city}, {state} {postalCode}, {country}
      </Typography>
    </>
  );
};

PickListItem.propTypes = {
  lineItem: shape({
    id: string.isRequired,
    name: shape({}).isRequired,
    quantity: number.isRequired,
  }).isRequired,
  order: shape({
    customLineItems: arrayOf(
      shape({
        custom: shape({
          fields: shape({
            lineItemsIds: arrayOf(string).isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

export default PickListItem;
