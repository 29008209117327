import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Snackbar, Alert, Typography } from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { copyText } from 'language';
import ReprintReceipts from 'views/Orders/components/ReprintReceipts';
import ExchangeModal from 'views/Orders/components/ExchangeModal';
import { useReturnMessage, useSelectedOrder, useSetReturnMessage } from 'contexts/orders';
import ExchangeOrderBacklink from 'views/Orders/components/ExchangeOrderBacklink';
import wellsFargoUtils from 'utils/wellsFargoUtils';
import EmailModal from '../../components/EmailModal';

const OrderActions = () => {
  const returnMessage = useReturnMessage();
  const setReturnMessage = useSetReturnMessage();
  const [showExchangeBacklink, setShowExchangeBacklink] = useState(false);
  const [displayWellsFargoMessage, setDisplayWellsFargoMessage] = useState(false);
  const selectedOrder = useSelectedOrder();
  const handleClose = () => {
    setReturnMessage(false);
  };

  const populateDisplayWellsFargoMessage = () => {
    if (wellsFargoUtils.validateWellsFargo(selectedOrder)) {
      setDisplayWellsFargoMessage(false);
    }
    if (!wellsFargoUtils.validateWellsFargo(selectedOrder)) {
      setDisplayWellsFargoMessage(true);
    }
  };

  useEffect(() => {
    if (selectedOrder.custom?.fields?.original_order_id) {
      setShowExchangeBacklink(true);
    } else {
      setShowExchangeBacklink(false);
    }
    populateDisplayWellsFargoMessage();
  }, [selectedOrder]);

  const { orderId } = useParams();
  return (
    <>
      <Grid container sx={{ mb: 1 }}>
        <Grid item sx={{ mr: 2 }}>
          <EmailModal />
        </Grid>
        <Grid item display="flex" justifyContent="center">
          <ReprintReceipts />
        </Grid>
        <Grid item sm={0.25} display="flex" justifyContent="center">
          <Divider orientation="vertical" />
        </Grid>
        {displayWellsFargoMessage && (
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <Typography component="h2" variant="sub_title" color="error">
              {copyText.Orders.Actions.wellsFargoMessage}
            </Typography>
          </Grid>
        )}
        {!displayWellsFargoMessage && (
          <>
            <Grid item sm={1.5} display="flex" justifyContent="center">
              <ExchangeModal order={selectedOrder} />
            </Grid>
            <Grid item sm={1.5} display="flex" justifyContent="center">
              <Button
                variant="outlined"
                component={RouterLink}
                to={`/orders/order/${orderId}/return`}
              >
                {copyText.Orders.Actions.return}
              </Button>
            </Grid>

            {showExchangeBacklink && (
              <Grid item sm={3} display="flex" justifyContent="center">
                <ExchangeOrderBacklink />
              </Grid>
            )}
          </>
        )}
      </Grid>
      <Snackbar open={returnMessage} onClose={handleClose}>
        <Alert severity="success" onClose={handleClose}>
          {copyText.Orders.Returns.successful}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderActions;
