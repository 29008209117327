import { useState, useEffect } from 'react';
import { Badge, Button, CircularProgress, Snackbar, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { shape, string, bool } from 'prop-types';
import {
  addToCart,
  checkForOutletDuplicates,
  handleRejectAllPromotions,
  cartContainsOutletItems,
} from 'utils/cart';
import { copyText } from 'language';

import {
  useCart,
  useSetCart,
  useSetAddToCartLoading,
  useAddToCartLoading,
  useSelectedShop,
} from 'contexts';
import CloseIcon from '@mui/icons-material/Close';
import { useOktaAuth } from '@okta/okta-react';
import cartLineItem from 'dataAccess/api/cart.lineItem.ts';

const AddToCartBtn = ({ details }) => {
  const loading = useAddToCartLoading();

  const [status, setStatus] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [hasLimitedAvailability, setHasLimitedAvailability] = useState(false);

  const setLoading = useSetAddToCartLoading();
  const [singleButtonLoading, setSingleButtonLoading] = useState(false);
  const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);
  // SUNSET: Remove quantity for add to cart or fix it to work with different variants
  // eslint-disable-next-line no-unused-vars

  const cart = useCart();
  const setCart = useSetCart();
  const { oktaAuth } = useOktaAuth();
  const selectedShop = useSelectedShop();

  const getError = (err) => {
    return err.response?.data?.errors[0]?.code || copyText.App.errorOccurred;
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const setOutOfStock = () => {
    const outOfStock = details?.attributes?.isOutOfStock || false;
    setIsOutOfStock(outOfStock);
  };

  const setLimitedAvailability = () => {
    if (details?.availability) {
      const limitedAvailability = Object.values(details?.availability?.channels).some(
        (channel) => channel.isOnStock,
      );
      setHasLimitedAvailability(limitedAvailability);
    }
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
      <CloseIcon />
    </IconButton>
  );

  const handleAddToCart = async () => {
    try {
      const salesAssociateId = oktaAuth?.authStateManager?._authState?.idToken?.claims?.email;
      setLoading(true);
      setSingleButtonLoading(true);
      let updatedCart = await addToCart(details, cart, salesAssociateId, selectedShop);
      if (selectedShop === 'outlet') {
        const newLineItem = updatedCart.lineItems.find(
          (lineItem) => lineItem?.variant?.sku === details?.sku,
        );
        updatedCart = await cartLineItem.markLineItemAsFinalSale(
          updatedCart?.id,
          newLineItem?.id,
          true,
        );
      }
      if (cartContainsOutletItems(updatedCart)) {
        updatedCart = await handleRejectAllPromotions({ cart: updatedCart });
      }

      setCart(updatedCart);
      setLoading(false);
      setSingleButtonLoading(false);
      setStatus(copyText.Shop.PDP.addToCart.success);
      setOpenSnackbar(true);
    } catch (error) {
      setLoading(false);
      setSingleButtonLoading(false);
      setStatus(`${copyText.Cart.CartTools.failedAddToCart}: ${getError(error)}`);
      setOpenSnackbar(true);
    }
  };

  const populateDuplicateMessage = () => {
    const hasDuplicate = checkForOutletDuplicates(cart, details, selectedShop);
    setShowDuplicateMessage(hasDuplicate);
  };

  useEffect(() => {
    setOutOfStock();
    setLimitedAvailability();
    populateDuplicateMessage();
  }, [details]);

  return (
    <>
      {isOutOfStock && hasLimitedAvailability && (
        <Typography data-testid="limited-inventory-warning">
          {copyText.ProductCard.hasLimitedAvailability}
        </Typography>
      )}
      {showDuplicateMessage && (
        <Typography data-testid="duplicate-warning">
          {copyText.ProductCard.duplicateItemInCart}
        </Typography>
      )}
      <Badge
        data-testid="add-to-cart-badge"
        color="secondary"
        overlap="rectangular"
        // SUNSET: Remove quantity for add to cart or fix it to work with different variants
        // badgeContent={quantity}
        component="p"
      >
        {/* <Typography>{copyText.ProductCard.hasLimitedAvailability}</Typography> */}
        {/* SUNSET: Refactor 'disabled' prop to not pass a function (needs to be a boolean) */}
        <Button
          disabled={
            loading || isOutOfStock || checkForOutletDuplicates(cart, details, selectedShop)
          }
          data-testid="add-to-cart-button"
          variant="contained"
          startIcon={singleButtonLoading ? null : <AddIcon />}
          onClick={handleAddToCart}
          sx={{ height: '2.5rem' }}
        >
          {singleButtonLoading ? <CircularProgress size={20} /> : copyText.ProductCard.addToCart}
        </Button>
      </Badge>
      <Snackbar
        sx={{ color: 'primary' }}
        open={openSnackbar}
        autoHideDuration={10000}
        onClose={() => setOpenSnackbar(false)}
        message={status}
        action={action}
      />
    </>
  );
};

AddToCartBtn.propTypes = {
  details: shape({
    sku: string.isRequired,
    availability: shape({}),
    attributes: shape({
      isOutOfStock: bool,
    }),
  }).isRequired,
};

export default AddToCartBtn;
