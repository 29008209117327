import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { InfoOutlined } from '@mui/icons-material';
import { string } from 'prop-types';

const MobileTooltip = ({ title }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  return (
    <Tooltip
      open={open}
      onClose={handleTooltipClose} // Close the tooltip when the user clicks away
      onClick={() => setOpen(true)}
      sx={{ ml: 1 }}
      leaveTouchDelay={10000}
      title={title}
    >
      <InfoOutlined fontSize="14px" />
    </Tooltip>
  );
};

MobileTooltip.propTypes = {
  title: string.isRequired,
};

export default MobileTooltip;
