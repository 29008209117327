import { OktaAuth } from '@okta/okta-auth-js';
import axios from 'axios';
import oktaConfig from 'utils/oktaConfig';
import accessTokenUtils from './utils/accessTokenUtils';

const oktaAuth = new OktaAuth(oktaConfig);

// Create an Axios instance
const instance = axios.create();

// Add a request interceptor
instance?.interceptors?.request?.use(
  async (config) => {
    // Do something before request is sent
    const accessToken = await accessTokenUtils.ensureValidAccessToken(oktaAuth);
    const newConfig = { ...config };
    newConfig.headers.Authorization = `Bearer ${accessToken}`;
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance?.interceptors?.response?.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // If response is 401, try refreshing the token
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const accessToken = await accessTokenUtils.ensureValidAccessToken(oktaAuth);
        originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        return instance(originalRequest); // Retry the request
      } catch (err) {
        return Promise.reject(error); // If refreshing fails, reject the request
      }
    }

    return Promise.reject(error);
  },
);

export default instance;
