import { shape, string, arrayOf, number, bool, func } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card, CardMedia, Chip, Grid, Box, Typography } from '@mui/material';
import { copyText, lang } from 'language';
import lineItemFormatters from 'utils/lineItems';
import DeliveryMethodSelect from './DeliveryMethodSelect';

const DeliveryMethodsCard = ({
  productData,
  showShippingMethod,
  selectedDeliveryMethods,
  setSelectedDeliveryMethods,
  removeMattress,
  setRemoveMattress,
}) => {
  const [productVariant, setProductVariant] = useState('');
  const [isFinalSale, setIsFinalSale] = useState(false);
  const [isFloorModel, setIsFloorModel] = useState(false);

  const populateFinalSaleFloorModel = () => {
    const isFinalSaleItem = productData?.custom?.fields?.final_sale;
    setIsFinalSale(isFinalSaleItem);
    setIsFloorModel(productData?.custom?.fields?.floor_model);
  };

  useEffect(() => {
    setProductVariant(lineItemFormatters.getDescription(productData?.variant));
    populateFinalSaleFloorModel();
  }, []);

  return (
    <Card sx={{ mt: 3, mb: 3, pt: 1 }} elevation={0}>
      <Grid container columns={16} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={16} sm={5} md={5} display="flex" alignContent="center">
          <CardMedia
            component="img"
            sx={{
              pr: 2,
              objectPosition: 'center' /* Center the image within the element */,
              height: '100%',
              width: '100%',
            }}
            src={productData.variant?.images[0]?.url}
            alt=""
          />
        </Grid>
        <Grid item xs={16} sm={11} md={11} sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box>
              <Typography
                component="p"
                sx={{ fontWeight: 600 }}
                color={productData.available === false ? 'grayUtility' : 'primary'}
              >
                {productData?.distributionChannel?.obj?.key ?? ''} {productData.name[lang]}
              </Typography>
              {isFinalSale && (
                <Chip label={copyText.Cart.CartTools.finalSale} color="error" size="small" />
              )}
              {isFloorModel && (
                <Chip
                  sx={{ ml: 1 }}
                  label={copyText.Cart.CartTools.floorModel}
                  color="error"
                  size="small"
                />
              )}
              <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                {productData?.variant?.sku}
              </Typography>
            </Box>
          </Box>
          <Grid marginRight={2} alignItems="center" container justifyContent="space-between">
            <Grid item display="flex" alignItems="center">
              <Typography variant="subtitle2">{productVariant}</Typography>
            </Grid>
          </Grid>
          <Grid marginTop={2} container justifyContent="space-between" alignItems="flex-start">
            <Typography>
              {copyText.App.quantityAbbreviated}: {productData.quantity}
            </Typography>
            <Grid item xs={16} sm={8} md={8}>
              {showShippingMethod ? (
                <DeliveryMethodSelect
                  sku={productData.variant.sku}
                  lineItemId={productData.id}
                  selectedDeliveryMethods={selectedDeliveryMethods}
                  setSelectedDeliveryMethods={setSelectedDeliveryMethods}
                  removeMattress={removeMattress}
                  setRemoveMattress={setRemoveMattress}
                  distributionChannel={productData?.distributionChannel?.obj?.key}
                />
              ) : (
                <Typography sx={{ fontStyle: 'italic', fontSize: 14 }}>
                  {copyText.Cart.CartTools.sameSkuShippingMethods}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default DeliveryMethodsCard;

DeliveryMethodsCard.propTypes = {
  productData: shape({
    productId: string.isRequired,
    quantity: number.isRequired,
    variant: shape({
      attributes: arrayOf(
        shape({
          name: string.isRequired,
        }),
      ).isRequired,
      images: arrayOf(
        shape({
          dimensions: shape({
            w: number.isRequired,
            h: number.isRequired,
          }).isRequired,
          url: string.isRequired,
        }),
      ).isRequired,
      id: number.isRequired,
    }),
    price: shape({
      value: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    name: shape({
      'en-US': string.isRequired,
    }).isRequired,
  }).isRequired,
  showShippingMethod: bool,
  selectedDeliveryMethods: arrayOf(
    shape({
      cost: number,
      key: string,
      name: string,
      needsRemoval: bool,
      storeKey: string,
      skus: arrayOf(string),
    }),
  ),
  setSelectedDeliveryMethods: func,
  removeMattress: number,
  setRemoveMattress: func,
};

DeliveryMethodsCard.defaultProps = {
  showShippingMethod: false,
  selectedDeliveryMethods: [],
  setSelectedDeliveryMethods: () => {},
  removeMattress: 0,
  setRemoveMattress: () => {},
};
