import React, { useEffect, useState } from 'react';
import { Body, AppDock, AppDockIcon } from './Signage.styles';
import defaultBg from './images/defaultBg.png';
import dockIcon from './images/icon.png';
import data from './js/data';

const iPadSignage = () => {
  const [pageHref, setPageHref] = useState('http://purple.com/');
  const [pageBg, setPageBg] = useState(defaultBg);

  const getParams = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return {
      page: urlSearchParams.get('page'),
      version: urlSearchParams.get('version'),
    };
  };

  const getPageData = (page, version) => {
    const versionData = data?.[version];
    const pageData = versionData?.[page];
    const { imgUri = pageBg, href = pageHref } = pageData || {};

    return { imgUri, href };
  };

  const populatePage = async () => {
    const { page, version } = getParams();
    const pageData = getPageData(page, version);
    const { imgUri, href } = pageData;

    setPageHref(href);
    setPageBg(imgUri);
  };

  useEffect(() => {
    populatePage();
  }, []);

  return (
    <Body style={{ backgroundImage: `url(${pageBg})` }} id="the_body" data-testid="the_body">
      <AppDock>
        <a href={pageHref} id="app_dock__link" data-testid="app_dock__link">
          <AppDockIcon src={dockIcon} alt="more information" className="app_dock__icon" />
        </a>
      </AppDock>
    </Body>
  );
};

export default iPadSignage;
