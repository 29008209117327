import copyText from 'language/enUS';

const getAgent = ({ orderData }) => {
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  // This is to make sure only emails are displayed on the front end.
  if (orderData?.custom?.fields?.salesAssociateId) {
    return orderData?.custom?.fields?.salesAssociateId;
  }
  if (emailRegex.test(orderData?.createdBy?.externalUserId)) {
    return orderData?.createdBy?.externalUserId;
  }
  return copyText.Orders.OrderDetails.notAvailable;
};

const lastPageFunction = ({ history, orderInput }) => {
  history.push({ pathname: '/orders', search: `?search=${orderInput}` });
};

const loadOrderData = ({ history, orderData, useLoadOrderByNumber }) => {
  const path = history.location.pathname.split('/');
  const pathOrderNumber = path[path.length - 1];
  if (!orderData.orderNumber || orderData.orderNumber !== pathOrderNumber) {
    useLoadOrderByNumber(pathOrderNumber);
  }
};

export default {
  getAgent,
  lastPageFunction,
  loadOrderData,
};
