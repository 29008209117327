import { lang } from 'language';

export const getProducts = ({ shopProducts, categoryId }) => {
  if (shopProducts) {
    return shopProducts[categoryId];
  }

  return null;
};

export const _getPricesByChannelKey = (variant, channelKey = null) => {
  let prices = variant?.prices;

  if (prices) {
    prices = prices.filter((price) => {
      if (channelKey) {
        return price?.channel?.obj?.key === channelKey;
      }
      return !price?.channel;
    });
  }

  return prices;
};

export const _filterVariantsByChannelKey = (product, channelKey) => {
  return product?.variants.map((variant) => {
    const updatedVariant = JSON.parse(JSON.stringify(variant));
    const prices = _getPricesByChannelKey(variant, channelKey);
    updatedVariant.prices = prices;
    return updatedVariant;
  });
};

export const _removeProductsWithEmptyPrices = (products) => {
  return products.filter((product) => {
    const masterVariantPrices = product?.masterVariant?.prices;
    let isVariantPricesEmpty = true;
    // check if product has any variants
    if (product?.variants) {
      const variantPrices = product?.variants?.map((variant) => variant.prices);
      isVariantPricesEmpty = variantPrices?.every((price) => price.length === 0);
    }

    return masterVariantPrices.length > 0 || !isVariantPricesEmpty;
  });
};

export const filterProducts = ({ products, baseUrl }) => {
  const isOutlet = baseUrl.includes('outlet');
  const channelKey = isOutlet ? 'OUTLET' : null;
  const updatedProducts = [];

  // loop over masterVariant and variants to get channel prices
  products.forEach((product) => {
    const updatedProduct = JSON.parse(JSON.stringify(product));
    const filteredMasterVariantPrices = _getPricesByChannelKey(product.masterVariant, channelKey);
    // get channel prices for masterVariant
    updatedProduct.masterVariant.prices = filteredMasterVariantPrices;

    // get channel prices for variants
    if (product?.variants?.length > 0) {
      const filteredVariants = _filterVariantsByChannelKey(product, channelKey);
      updatedProduct.variants = filteredVariants;
    }
    updatedProducts.push(updatedProduct);
  });

  // if prices are empty, remove product
  const result = _removeProductsWithEmptyPrices(updatedProducts);
  return result;
};

export const filterProductsByTitle = ({ products, searchValue }) => {
  return products.filter((product) => {
    const productName = product.name[lang] || product.name?.en;
    if (!productName) return null;
    return productName.toLowerCase().includes(searchValue.toLowerCase());
  });
};
