const convertCentToDollar = (price) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const newPrice = formatter.format(price / 100);
  return newPrice;
};

export default convertCentToDollar;
