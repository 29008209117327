const getExchangeTotal = (cart) => {
  if (cart?.is_exchange_order) {
    let sum = 0;
    const lineItems = cart.exchange_order_line_items
      ? JSON.parse(cart.exchange_order_line_items)
      : [];
    lineItems.forEach((item) => {
      sum += item.exchangeValue?.centAmount ?? 0;
    });
    return sum;
  }
  return 0;
};

export default getExchangeTotal;
