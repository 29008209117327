import React from 'react';
import { string } from 'prop-types';
import { Typography, CardContent, CardMedia, CardActionArea, Box } from '@mui/material';

import placeholderImg from 'media/images/category_placeholder.png';

const CategoryCard = ({ imgUrl, imgAlt, title }) => {
  return (
    <Box sx={{ width: 290 }}>
      <CardActionArea>
        <CardMedia component="img" height="300" image={imgUrl} alt={imgAlt} />
        <CardContent sx={{ pl: 0 }}>
          <Typography gutterBottom variant="h6" component="h2" align="left">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Box>
  );
};

CategoryCard.propTypes = {
  imgUrl: string,
  imgAlt: string,
  title: string.isRequired,
};

CategoryCard.defaultProps = {
  imgUrl: placeholderImg,
  imgAlt: null,
};

export default CategoryCard;
