import { Close } from '@mui/icons-material';
import { arrayOf, number, shape, string } from 'prop-types';
import refundUtils from 'utils/refunds/refundUtils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import ProcessIndicator from 'components/ProcessIndicator/ProcessIndicator';
import { copyText } from 'language';
import { useEffect, useState } from 'react';
import { useSetCart, useCart } from 'contexts';
import ClearCartBtn from 'components/ClearCart/components/ClearCartBtn';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import exchangeModalUtils from '../utils/exchangeModalUtils';

const ExchangeModal = ({ order }) => {
  const [open, setOpen] = useState(false);
  const setCart = useSetCart();
  const cart = useCart();
  const [processStatus, setProcessStatus] = useState(null);
  const [processIcon, setProcessIcon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [remainingFunds, setRemainingFunds] = useState(0);
  const [exchangeModalStep, setExchangeModalStep] = useState(0);
  const { orderId } = useParams();

  const onComplete = async (newCart) => {
    try {
      const updatedCart = await exchangeModalUtils.markAsExchange({ newCart, orderId });

      setCart(updatedCart);

      setExchangeModalStep(1);
    } catch (error) {
      setProcessStatus(copyText.Orders.Actions.exchangeInstructions.createExchangeCartFailure);
      setProcessIcon(<Close />);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setLoading(false);
    setProcessIcon(null);
    setProcessStatus(null);
    setOpen(false);
  };

  useEffect(() => {
    setProcessStatus(null);
    setProcessIcon(null);
    setRemainingFunds(refundUtils.getRemainingFunds(order));
  }, []);
  return (
    <>
      <Button
        variant="outlined"
        onClick={() =>
          exchangeModalUtils.openModal({
            remainingFunds,
            setOpen,
            setProcessStatus,
            setProcessIcon,
          })
        }
      >
        {copyText.Orders.Actions.exchange}
      </Button>
      <Dialog open={open}>
        <DialogTitle>
          {copyText.Orders.Actions.exchangeInstructions.createExchangeOrder}
        </DialogTitle>
        {exchangeModalStep === 0 && (
          <>
            <DialogContent>
              <Typography fontWeight="bold">
                {copyText.Orders.Actions.exchangeInstructions.warning}
              </Typography>
              <ul>
                <li>
                  <Typography>{copyText.Orders.Actions.exchangeInstructions.continue}</Typography>
                </li>
                <li>
                  <Typography>{copyText.Orders.Actions.exchangeInstructions.addItems}</Typography>
                </li>
                <li>
                  <Typography>{copyText.Orders.Actions.exchangeInstructions.checkout}</Typography>
                </li>
                <ul>
                  <li>
                    <Typography>
                      {copyText.Orders.Actions.exchangeInstructions.totalGreater}
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      {copyText.Orders.Actions.exchangeInstructions.totalLess}
                    </Typography>
                  </li>
                </ul>
                <li>
                  <Typography fontWeight="700">
                    {copyText.Orders.Actions.exchangeInstructions.singlePaymentOnly}
                  </Typography>
                </li>
              </ul>

              <Typography fontWeight="bold">
                {copyText.Orders.Actions.exchangeInstructions.proceed}
              </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 3, display: 'flex', justifyContent: 'space-between' }}>
              <ProcessIndicator statusIcon={processIcon} text={processStatus} />
              <Box display="flex" justifyContent="space-between">
                <Button sx={{ mr: 3 }} variant="outlined" onClick={handleCancel}>
                  {copyText.App.cancel}
                </Button>
                <ClearCartBtn
                  disabled={loading || remainingFunds <= 0}
                  text={copyText.App.yesContinue}
                  preEvent={() =>
                    exchangeModalUtils.preEvent({
                      orderId,
                      setLoading,
                      setProcessIcon,
                      setProcessStatus,
                    })
                  }
                  onComplete={onComplete}
                />
              </Box>
            </DialogActions>
          </>
        )}
        {exchangeModalStep === 1 && (
          <DialogContent>
            <Typography>{copyText.Orders.Actions.exchangeAddress}</Typography>
            <Button
              sx={{ mr: 3, mt: 3 }}
              variant="outlined"
              onClick={() =>
                exchangeModalUtils.usePrevAddress({
                  cart,
                  orderId,
                  setCart,
                  setProcessStatus,
                  setProcessIcon,
                  setOpen,
                  setExchangeModalStep,
                })
              }
            >
              {copyText.App.yes}
            </Button>
            <Button
              sx={{ mt: 3 }}
              variant="outlined"
              onClick={() => exchangeModalUtils.closeModal(setOpen, setExchangeModalStep)}
            >
              {copyText.App.no}
            </Button>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default ExchangeModal;

ExchangeModal.propTypes = {
  order: shape({
    customLineItems: arrayOf(shape({})).isRequired,
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
      totalNet: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    lineItems: arrayOf(shape({})).isRequired,
    orderNumber: string.isRequired,
    createdAt: string.isRequired,
  }).isRequired,
};
