import getTotals from 'views/Orders/hooks/getTotals';
// SUNRISE: (Adrian) We need to make sure this still works once refunds are in place.
const getReturnedItems = (originalOrder) => {
  if (!originalOrder.returnInfo) {
    return [];
  }
  // combine the returned arrays by using flatMap
  const transformedItems = originalOrder.returnInfo.flatMap((returnInfoItem) => {
    const items = returnInfoItem.items || [];

    // map over items in the nested returnInfo items
    return items.map((item) => {
      const isExchange = item.custom?.fields?.type === 'Exchange';
      // if not an exchange item, skip
      const exchangeOrderNumber = isExchange
        ? item.custom?.fields?.exchange_order_number || null
        : null;

      return {
        lineItemId: item.lineItemId,
        quantity: item.quantity,
        exchangeCredit: item?.custom?.fields?.centAmount || 0,
        exchangeOrderNumber,
      };
    });
  });

  return transformedItems;
};

const getNumReturned = (returnItems, itemId) => {
  return returnItems.reduce((acc, returnedItem) => {
    if (returnedItem.lineItemId === itemId) {
      return acc + returnedItem.quantity;
    }
    return acc;
  }, 0);
};

const getAvailableCentAmount = (returnItems, item) => {
  return returnItems.reduce((acc, returnedItem) => {
    if (returnedItem.lineItemId === item.id) {
      return acc - returnedItem.exchangeCredit;
    }
    return acc;
  }, item.taxedPrice.totalGross.centAmount);
};

const getExchangeOrderNumbers = (returnItems, itemId) => {
  const exchangeNums = returnItems.map((returnedItem) => {
    if (returnedItem.lineItemId === itemId) {
      return returnedItem.exchangeOrderNumber;
    }
    return null;
  });
  return exchangeNums.filter(Boolean);
};

const checkLineItemAvailability = (originalOrder) => {
  const items = originalOrder.lineItems;
  const returnedItems = getReturnedItems(originalOrder);

  const updatedItems = items.map((item) => {
    const numReturned = getNumReturned(returnedItems, item.id);
    const availableCentAmount = getAvailableCentAmount(returnedItems, item);

    const exchangeOrderNumbers = getExchangeOrderNumbers(returnedItems, item.id);
    const numAvailable = item.quantity - numReturned;
    if (numAvailable === 0) {
      return {
        ...item,
        numAvailable,
        numReturned,
        availableCentAmount,
        exchangeOrderNumbers,
      };
    }
    return {
      ...item,
      numAvailable,
      numReturned,
      availableCentAmount,
      exchangeOrderNumbers,
    };
  });

  return updatedItems;
};

const getExchangeCreditTotal = (selectedOrder) => {
  const exchangeItems = selectedOrder?.custom?.fields?.exchange_order_line_items
    ? JSON.parse(selectedOrder?.custom?.fields?.exchange_order_line_items)
    : [];

  const total = exchangeItems.reduce((prev, curr) => prev + curr.exchangeValue.centAmount, 0);
  return total;
};

const getRefundTotal = (selectedOrder, orderPayment) => {
  if (selectedOrder?.custom?.fields?.is_exchange_order) {
    const giftCards = getTotals.getGiftCardPayments(orderPayment);

    const exchanges = getExchangeCreditTotal(selectedOrder);
    const total = (selectedOrder?.taxedPrice?.totalGross?.centAmount || 0) - giftCards - exchanges;
    if (total < 0) return Math.abs(total);
  }
  return 0;
};

export default {
  getReturnedItems,
  checkLineItemAvailability,
  getExchangeCreditTotal,
  getRefundTotal,
};
