import copyText from 'language/enUS';
import cloverUtils from 'utils/clover';

const checkCloverStatus = ({ setCloverStatus }) => {
  const accessToken = cloverUtils.getCloverAccessToken();
  const device = cloverUtils.getSessionCloverDevice();

  if (!accessToken) {
    setCloverStatus(copyText.Cart.CartTools.missingCloverToken);
  } else if (!device || device === 'select') {
    setCloverStatus(copyText.Cart.CartTools.missingCloverDevice);
  } else {
    setCloverStatus(null);
  }
};

export default {
  checkCloverStatus,
};
