import React, { useState } from 'react';
import { TextField, Typography, Alert } from '@mui/material';
import { func, shape, string } from 'prop-types';
import { Box } from '@mui/system';
import { copyText } from 'language';
import shippingInfoInputUtils from 'utils/shippingInfoInputUtils';

const DraftCustomerInfo = ({ customerInfo, setCustomerInfo }) => {
  const [invalidField, setInvalidField] = useState([]);
  const [invalidFieldMessage, setInvalidFieldMessage] = useState('');
  const maxNoteLength = 500;

  return (
    <Box>
      <TextField
        label={copyText.Cart.CartTools.email}
        name="email"
        type="email"
        sx={{ mt: 2, mb: 3 }}
        defaultValue={customerInfo?.email}
        fullWidth
        placeholder={copyText.Cart.CartTools.customerEmail}
        onChange={(e) =>
          shippingInfoInputUtils.updateForm({
            e,
            setFormData: setCustomerInfo,
            setInvalidFieldMessage,
            setInvalidField,
          })
        }
        error={invalidField.includes('email')}
        helperText={invalidField.includes('email') ? invalidFieldMessage : ''}
      />
      <TextField
        name="customerName"
        sx={{ mb: 3 }}
        defaultValue={customerInfo?.customerName}
        label={copyText.Cart.CartTools.DraftOrder.customerFirstName}
        fullWidth
        onChange={(e) => shippingInfoInputUtils.updateForm({ e, setFormData: setCustomerInfo })}
      />
      <TextField
        name="note"
        rows={5}
        multiline
        defaultValue={customerInfo?.note}
        label={`${copyText.Cart.CartTools.DraftOrder.addNote} (${customerInfo?.note.length}/${maxNoteLength} ${copyText.Cart.CartTools.DraftOrder.charLimit}) `}
        placeholder={copyText.Cart.CartTools.DraftOrder.addNotePlaceholder}
        fullWidth
        inputProps={{ maxLength: 500 }}
        onChange={(e) => shippingInfoInputUtils.updateForm({ e, setFormData: setCustomerInfo })}
      />
      <Alert icon={false} sx={{ p: 3, mt: 3, ml: 1, mr: 1 }}>
        <Typography sx={{ mb: 1 }}>{copyText.Cart.CartTools.DraftOrder.advisoryOne} </Typography>
        <Typography>
          <strong>{copyText.Cart.CartTools.DraftOrder.advisoryTwo}</strong>
        </Typography>
      </Alert>
    </Box>
  );
};

DraftCustomerInfo.propTypes = {
  customerInfo: shape({
    email: string,
    customerName: string,
    note: string,
  }).isRequired,
  setCustomerInfo: func.isRequired,
};

export default DraftCustomerInfo;
