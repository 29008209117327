import React, { useEffect } from 'react';
import DetailLayout from 'components/layouts/DetailLayout';
import { useHistory } from 'react-router-dom';

import { useSelectedOrder, useOrderPayment, useOrderInput } from 'contexts/orders';
import CustomerDetails from 'components/CustomerDetails/CustomerDetails';
import getDate from 'utils/getDate';

import UseOrderSearchFunctions from '../OrderSearch/hooks/OrderSearchFunctions';
import OrderData from './OrderData';
import OrderActions from './OrderActions';
import OrderItems from '../../components/OrderItems';
import OrderTotals from '../../components/OrderTotals';
import DiscountDisplay from './DiscountDisplay';
import ShowPayment from './components/ShowPayment';
import orderDetailsUtils from './orderDetails.utils';

const OrderDetails = () => {
  const orderData = useSelectedOrder();
  const orderPayment = useOrderPayment();
  const orderInput = useOrderInput();
  const shippingAddress = orderData?.shippingAddress;
  const history = useHistory();
  const customerFullName = `${shippingAddress?.firstName} ${shippingAddress?.lastName}`;
  const customerAddress = `${shippingAddress?.streetName} ${shippingAddress?.additionalStreetInfo} ${shippingAddress?.city}, ${shippingAddress?.state} ${shippingAddress?.postalCode}`;
  const { useLoadOrderByNumber } = UseOrderSearchFunctions();

  useEffect(() => {
    orderDetailsUtils.loadOrderData({ history, orderData, useLoadOrderByNumber });
  }, [history.location]);

  return (
    <DetailLayout
      identifier={orderData?.orderNumber}
      lastPageFunction={() => orderDetailsUtils.lastPageFunction({ history, orderInput })}
      lastPageText="Return to Order Search"
      DetailComponents={[
        OrderData({
          orderNumber: orderData?.orderNumber,
          orderDate: getDate(orderData?.createdAt),
          salesAgent: orderDetailsUtils.getAgent({ orderData }),
          paymentMethod: ShowPayment({ orderPayment }),
        }),
        CustomerDetails({
          name: customerFullName,
          phone: shippingAddress?.phone,
          email: orderData?.customerEmail,
          address: customerAddress,
        }),
      ]}
      ActionsComponent={OrderActions()}
      BreakdownComponent={OrderItems()}
      TotalsComponents={[OrderTotals(), DiscountDisplay({ order: orderData })]}
    />
  );
};

export default OrderDetails;
