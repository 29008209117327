import React from 'react';
import { Box, FormGroup, FormLabel, TextField } from '@mui/material';
import { shape, string, arrayOf, func, bool } from 'prop-types';
import { copyText } from 'language';

const ReturnCustomerInfoForm = ({ formData, updateForm, readOnly }) => (
  <Box sx={{ width: '100%' }}>
    <FormLabel>{copyText.Orders.Returns.customerInfo}</FormLabel>
    <FormGroup sx={{ width: '100%', mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.agentName}
          value={formData.agent_name}
          name="agent_name"
          onChange={updateForm}
          disabled={readOnly}
        />
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.agentEmail}
          value={formData.agent_email}
          name="agent_email"
          onChange={updateForm}
          disabled={readOnly}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.storeKey}
          value={formData.store_code}
          name="store_code"
          onChange={updateForm}
          disabled={readOnly}
        />
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.orderNumber}
          value={formData.order_number}
          name="order_number"
          onChange={updateForm}
          disabled={readOnly}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.customerName}
          value={formData.customer_name}
          name="customer_name"
          onChange={updateForm}
          disabled={readOnly}
        />
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.customerEmail}
          value={formData.customer_email}
          name="customer_email"
          onChange={updateForm}
          disabled={readOnly}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.storeAddress}
          value={formData.store_address_1}
          name="store_address_1"
          onChange={updateForm}
          disabled={readOnly}
        />
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.suite}
          value={formData.store_suite}
          name="store_suite"
          onChange={updateForm}
          disabled={readOnly}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.city}
          value={formData.store_city}
          name="store_city"
          onChange={updateForm}
          disabled={readOnly}
        />
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.state}
          value={formData.store_state}
          name="store_state"
          onChange={updateForm}
          disabled={readOnly}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.zip}
          value={formData.store_zip}
          name="store_zip"
          onChange={updateForm}
          disabled={readOnly}
        />
        <TextField
          sx={{ width: '48%' }}
          label={copyText.Orders.Returns.formFields.country}
          value={formData.store_country}
          name="store_country"
          onChange={updateForm}
          disabled={readOnly}
        />
      </Box>
    </FormGroup>
  </Box>
);

export default ReturnCustomerInfoForm;

ReturnCustomerInfoForm.propTypes = {
  formData: shape({
    condition: string,
    returnReasons: arrayOf(string),
    otherReason: string,
    physicalReturn: bool,
    agent_name: string,
    agent_email: string,
    store_code: string,
    order_number: string,
    customer_email: string,
    customer_name: string,
    is_return: bool,
    store_address_1: string,
    store_suite: string,
    store_city: string,
    store_state: string,
    store_zip: string,
    store_country: string,
  }),
  updateForm: func,
  readOnly: bool,
};

ReturnCustomerInfoForm.defaultProps = {
  formData: [],
  readOnly: false,
  updateForm: () => {},
};
