import { MenuItem } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useCart, useSetCart } from 'contexts';
import { useState } from 'react';
import TextLoader from 'components/TextLoader';
import { copyText } from 'language';
import cartPopulateUtils from './utils/cartPopulateUtils';

const CartPopulate = () => {
  const cart = useCart();
  const setCart = useSetCart();
  const [loading, setLoading] = useState(false);
  const { oktaAuth } = useOktaAuth();
  return (
    <>
      <MenuItem
        disabled={loading}
        variant="contained"
        onClick={() =>
          cartPopulateUtils.addLineItem({
            sku: '10-21-24008',
            cart,
            setCart,
            setLoading,
            salesAssociateId: oktaAuth.authStateManager?._authState?.idToken?.claims?.email,
          })
        }
      >
        <TextLoader loading={loading} text={copyText.DevTools.addMattressToCart} size={20} />
      </MenuItem>
      <MenuItem
        disabled={loading}
        variant="contained"
        onClick={() =>
          cartPopulateUtils.addLineItem({
            sku: '10-38-12991',
            cart,
            setCart,
            setLoading,
            salesAssociateId: oktaAuth.authStateManager?._authState?.idToken?.claims?.email,
          })
        }
      >
        <TextLoader loading={loading} text={copyText.DevTools.addMattressBaseToCart} size={20} />
      </MenuItem>
      <MenuItem
        disabled={loading}
        variant="contained"
        onClick={() => cartPopulateUtils.populateStoreAddress({ cart, setCart, setLoading })}
      >
        <TextLoader loading={loading} text={copyText.DevTools.addShippingAddressToCart} size={20} />
      </MenuItem>
    </>
  );
};

export default CartPopulate;
