const setAllTypes = ({ setExchangeFilter, setReturnFilter, setOriginalFilter, setDraftFilter }) => {
  setExchangeFilter('');
  setReturnFilter('');
  setOriginalFilter('');
  setDraftFilter('');
};

const resetFilters = ({
  exchangeFilter,
  returnFilter,
  originalFilter,
  draftFilter,
  setAllOrderTypes,
}) => {
  if (exchangeFilter || returnFilter || originalFilter || draftFilter) {
    setAllOrderTypes(false);
  }
  if (!exchangeFilter && !returnFilter && !originalFilter && !draftFilter) {
    setAllOrderTypes(true);
  }
};

export default {
  setAllTypes,
  resetFilters,
};
