import React from 'react';
import * as PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { copyText } from 'language';

const Greeting = ({ userName }) => {
  return (
    <Typography variant="p" component="p">
      {`${copyText.Greeting.greeting} ${userName}`}
    </Typography>
  );
};

Greeting.propTypes = {
  userName: PropTypes.string.isRequired,
};

export default Greeting;
