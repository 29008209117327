import { React, useEffect, useState } from 'react';
import { Grid, Link } from '@mui/material';
import { useParams, Link as RouterLink, useRouteMatch } from 'react-router-dom';

import { copyText } from 'language';
import { useShopProducts, usePopulateProducts } from 'contexts';

import CategoryCard from 'components/CategoryCard/CategoryCard';
import Page404 from 'views/Page404/Page404';
import ViewTransition from 'components/Transitions/ViewTransition';
import NoProducts from 'views/Shop/components/NoProducts/NoProducts';

import SkeletonGrid from '../../components/SkeletonGrid/SkeletonGrid';
import ShopHeader from '../../components/ShopHeader/ShopHeader';
import ProductFilter from './components/ProductFilter';
import { getProducts, filterProducts } from './Products.utils';

const Products = () => {
  const [allProducts, setAllProducts] = useState();
  const [products, setProducts] = useState();
  const [loading, setLoading] = useState(true);

  const { categoryId } = useParams();
  const shopProducts = useShopProducts();
  const populateProductsStore = usePopulateProducts();
  const { url: baseUrl } = useRouteMatch();

  const populateProducts = async () => {
    setLoading(true);
    if (!shopProducts) {
      await populateProductsStore();
    }
    if (shopProducts) {
      const foundProducts = await getProducts({ shopProducts, categoryId });

      if (foundProducts) {
        const filteredProducts = filterProducts({ products: foundProducts, baseUrl });
        setAllProducts(filteredProducts);
        setProducts(filteredProducts);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    populateProducts();
  }, [shopProducts, baseUrl, categoryId]);

  // --- Loading view ---
  if (!products && loading && !shopProducts) {
    return (
      <>
        <ShopHeader />
        <Grid container spacing={4} sx={{ justifyContent: 'space-evenly' }}>
          <SkeletonGrid numItems={6} />
        </Grid>
      </>
    );
  }

  // --- Error view ---
  if (!products && !loading) {
    return <Page404 error={copyText.Shop.Products.error} />;
  }

  // --- No products found ---
  if (!loading && shopProducts && products.length === 0) {
    return (
      <>
        <ShopHeader />
        <Grid container sx={{ justifyContent: 'left', mb: 3, mt: 0 }}>
          <Grid item xs={12} sm={3}>
            <ProductFilter products={allProducts} setProducts={setProducts} />
          </Grid>
        </Grid>
        <NoProducts />
      </>
    );
  }

  // --- Products view ---
  if (products) {
    return (
      <>
        <ShopHeader />
        <Grid container sx={{ justifyContent: 'left', mb: 3, mt: 0 }}>
          <Grid item xs={12} sm={3}>
            <ProductFilter products={allProducts} setProducts={setProducts} />
          </Grid>
        </Grid>
        <ViewTransition direction="up">
          <Grid container spacing={4} sx={{ justifyContent: 'space-evenly' }}>
            {products?.map(
              (product) =>
                product && (
                  <Grid key={product.id} item>
                    <Link
                      component={RouterLink}
                      underline="none"
                      to={{
                        pathname: `${baseUrl}/product/${product.id}`,
                      }}
                    >
                      <CategoryCard
                        imgUrl={product.masterVariant?.images[0]?.url}
                        title={product.name?.en || product.name['en-US']}
                        imgAlt={`
                      ${product.name?.en || product.name['en-US']}
                      ${' '}${copyText.Shop.Products.imgAlt}
                      `}
                      />
                    </Link>
                  </Grid>
                ),
            )}
          </Grid>
        </ViewTransition>
      </>
    );
  }
  return null;
};

export default Products;
