import React from 'react';
import { func, node, bool, string, shape } from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

import TextLoader from 'components/TextLoader';
import { copyText } from 'language';

const DialogModal = (props) => {
  const { open, closeAction, children, copy, continueAction, loading, testId, disabled } = props;

  const handleClose = (event, reason) => {
    // Disable closing modal from clicking backdrop
    if (reason && reason === 'backdropClick') {
      return;
    }
    if (closeAction) {
      closeAction(event);
    } else {
      // Do nothing
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth data-testid={testId}>
      <DialogTitle sx={{ p: 3 }}>{copy?.title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          sx={{ paddingLeft: 3, paddingRight: 3, mr: 3 }}
          variant="outlined"
          onClick={handleClose}
        >
          {copy?.cancel || copyText.App.cancel}
        </Button>
        <Button
          sx={{ paddingLeft: 4, paddingRight: 4 }}
          variant="contained"
          onClick={continueAction}
          disabled={disabled || loading}
        >
          <TextLoader loading={loading} size={25} text={copy?.continue || ''} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogModal.propTypes = {
  open: bool,
  closeAction: func,
  children: node,
  copy: shape({
    title: string,
    cancel: string,
    continue: string,
  }),
  continueAction: func.isRequired,
  loading: bool,
  disabled: bool,
  testId: string,
};

DialogModal.defaultProps = {
  open: false,
  closeAction: () => {},
  children: null,
  copy: null,
  loading: false,
  disabled: false,
  testId: 'dialog-modal',
};

export default DialogModal;
