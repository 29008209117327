import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useOktaAuth } from '@okta/okta-react';

import {
  useCart,
  useSetCart,
  useSetShowCheckout,
  useSetShowPendingPaymentWarning,
  useShowCheckout,
} from 'contexts';
import { copyText } from 'language';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import convertCentToDollar from 'utils/convertCentToDollar';
import multiPayUtils from 'views/Cart/Components/RightColumn/components/CartFinancials/components/MultiPayModal/utils/multiPayUtils';

import TextLoader from 'components/TextLoader';
import ProcessIndicator from 'components/ProcessIndicator/ProcessIndicator';
import CloverWarning from 'components/CloverWarning/CloverWarning';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';

import CancelAlert from './components/CancelAlert';
import CreateOrder from './components/CreateOrder';
import PendingPayments from './components/PendingPayments';
import OrderCreationFailure from './components/OrderCreationFailure';

const currency = '$';
const MultiPayModal = () => {
  const cart = useCart();
  const setCart = useSetCart();
  const setShowPendingPaymentWarning = useSetShowPendingPaymentWarning();
  const showCheckout = useShowCheckout();
  const setShowCheckout = useSetShowCheckout();
  const [customPayment, setCustomPayment] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [remainingTotal, setRemainingTotal] = useState(0);
  const [status, setStatus] = useState('');
  const [customAmountError, setCustomAmountError] = useState(null);
  const [showOrderCreationFailure, setShowOrderCreationFailure] = useState(false);
  const [orderCreationError, setOrderCreationError] = useState(null);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [checkoutDisabled, setCheckoutDisabled] = useState(false);
  const [voidLoading, setVoidLoading] = useState(false);
  const [showVoidError, setShowVoidError] = useState(false);
  const [showMissingCloverWarning, setShowMissingCloverWarning] = useState(false);
  const [message, setMessage] = useState('');

  const [loadingOrder, setLoadingOrder] = useState(false);
  const [showReceipt, setShowReceipt] = useState(false);
  const [disableRepeat, setDisableRepeat] = useState(false);
  const [newOrder, setNewOrder] = useState({});
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    setPaymentAmount(financialCalculators.getUnpaidTotal(cart, payments) / 100);
    setRemainingTotal(financialCalculators.getUnpaidTotal(cart, payments));
    multiPayUtils.handlePayInFull({
      setCustomPayment,
      setCheckoutDisabled,
      setPaymentAmount,
      cart,
      payments,
    });
  }, [payments, cart]);

  useEffect(() => {
    setPayments(financialCalculators.parsePendingPayments(cart));
    setRemainingTotal(financialCalculators.getUnpaidTotal(cart, payments));
    multiPayUtils.handlePayInFull({
      setCustomPayment,
      setCheckoutDisabled,
      setPaymentAmount,
      cart,
      payments,
    });
  }, [showCheckout, cart]);

  useEffect(() => {
    multiPayUtils.cloverValidation({ setShowMissingCloverWarning });
    window.addEventListener('storage', () =>
      multiPayUtils.cloverValidation({ setShowMissingCloverWarning }),
    );
    return () =>
      window.removeEventListener('storage', () =>
        multiPayUtils.cloverValidation({ setShowMissingCloverWarning }),
      );
  }, []);

  useEffect(() => {
    multiPayUtils.showCheckout({ setShow: setShowCheckout });
  }, [window.location.href]);

  return (
    <Dialog fullWidth open={showCheckout} data-testid="checkout-button-modal">
      <DialogTitle
        sx={{ display: 'flex', p: 3, justifyContent: 'space-between' }}
        alignItems="center"
      >
        <Typography variant="h5" component="span">
          {copyText.Cart.CheckoutButtons.checkout}
        </Typography>
        <Box pr={1}>
          <Typography variant="h6" align="right">
            {convertCentToDollar(remainingTotal)}
          </Typography>
          <Typography variant="subtitle1" align="right" textTransform="none">
            {copyText.Cart.CheckoutButtons.remainingTotal}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        {showMissingCloverWarning && <CloverWarning />}
        <Select
          disabled={remainingTotal === 0}
          fullWidth
          sx={{ mb: 3 }}
          value={paymentMethod}
          label={copyText.Cart.CheckoutButtons.proceed}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <MenuItem value="card">{copyText.Cart.CheckoutButtons.card}</MenuItem>
          <MenuItem disabled value="cash">
            {copyText.Cart.CheckoutButtons.cash}
          </MenuItem>
          <MenuItem value="affirm">{copyText.Cart.CheckoutButtons.affirmPaymentMethod}</MenuItem>
        </Select>
        <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  disabled={remainingTotal === 0}
                  checked={customPayment === false}
                  onChange={() =>
                    multiPayUtils.handlePayInFull({
                      setCustomPayment,
                      setCheckoutDisabled,
                      setPaymentAmount,
                      cart,
                      payments,
                    })
                  }
                />
              }
              label={copyText.Cart.CheckoutButtons.payInFull}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>{copyText.Cart.CheckoutButtons.customPayment}</Typography>
            <Checkbox
              disabled={payments?.length > 1}
              checked={customPayment === true}
              onChange={() => setCustomPayment(true)}
              data-testid="custom-payment-checkbox"
            />
            <TextField
              InputProps={{
                startAdornment: <Typography>{currency}</Typography>,
              }}
              error={customAmountError}
              type="number"
              placeholder="0.00"
              onChange={(e) =>
                multiPayUtils.handleCustomAmountInput({
                  setPaymentAmount,
                  amount: e.target.value,
                  setCheckoutDisabled,
                  setCustomAmountError,
                  remainingTotal,
                })
              }
              value={customPayment ? paymentAmount : undefined}
              disabled={!customPayment}
              data-testid="custom-payment-input"
            />
          </Box>
        </Box>
        <Box mb={2}>
          <Typography variant="subtitle2" textAlign="center">
            {copyText.Cart.CheckoutButtons.splitPaymentLimit}
          </Typography>
        </Box>
        {showCancelWarning && (
          <CancelAlert
            setShowCancelWarning={setShowCancelWarning}
            voidLoading={voidLoading}
            showVoidError={showVoidError}
            handleClearCart={() =>
              multiPayUtils.clearUnprocessedCart({
                setCart,
                setLoading,
                setShowCheckout,
                setCustomPayment,
                setShowCancelWarning,
                setShowVoidError,
                salesAssociateId: oktaAuth?.authStateManager?._authState?.idToken?.claims?.email,
              })
            }
            handleCancel={() =>
              multiPayUtils.handleCancel({
                setShowCheckout,
                setVoidLoading,
                setShowVoidError,
                setCustomPayment,
                setShowCancelWarning,
                setShowPendingPaymentWarning,
                setCart,
                cart,
              })
            }
          />
        )}
        {payments?.length > 0 && (
          <PendingPayments
            payments={payments}
            setDisableRepeat={setDisableRepeat}
            setShowVoidError={setShowVoidError}
            setShowCancelWarning={setShowCancelWarning}
          />
        )}
        {showOrderCreationFailure && (
          <OrderCreationFailure
            error={orderCreationError}
            handleClose={() => setShowOrderCreationFailure(false)}
          />
        )}
        {remainingTotal === 0 && (
          <CreateOrder
            setStatus={setStatus}
            setShow={setShowCheckout}
            loadingOrder={loadingOrder}
            setLoadingOrder={setLoadingOrder}
            showReceipt={showReceipt}
            setShowReceipt={setShowReceipt}
            disableRepeat={disableRepeat}
            setDisableRepeat={setDisableRepeat}
            newOrder={newOrder}
            setNewOrder={setNewOrder}
            onError={(error) =>
              multiPayUtils.handleError({
                setShowOrderCreationFailure,
                setOrderCreationError,
                cart,
                error,
              })
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Grid
          container
          sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}
        >
          <Grid item>
            <ProcessIndicator data-testid="process-status" text={status} />
          </Grid>
          <Grid item>
            <Button
              sx={{ mr: 3 }}
              onClick={() =>
                multiPayUtils.handleCancelWarning({
                  cart,
                  payments,
                  setShowCancelWarning,
                  setShowCheckout,
                })
              }
              variant="outlined"
            >
              {copyText.App.cancel}
            </Button>
            <Button
              disabled={checkoutDisabled || loading || remainingTotal === 0}
              onClick={() =>
                multiPayUtils.processPayment({
                  cart,
                  paymentAmount,
                  setStatus,
                  setLoading,
                  setCart,
                  setPayments,
                  setShowOrderCreationFailure,
                  setShowCancelWarning,
                  setMessage,
                })
              }
              variant="contained"
              data-testid="proceed-to-checkout-button"
            >
              <TextLoader
                text={copyText.Cart.CheckoutButtons.processPayment}
                loading={loading}
                size={20}
              />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
      <SnackbarMessage message={message} setMessage={setMessage} />
    </Dialog>
  );
};

export default MultiPayModal;
