import bedding from './images/bedding.jpg';
import pillows from './images/pillows.jpg';
import v3 from '../v3';
/**
 * --- v3.01 ---
 * - Bedding: ENV_URL/signage?page=bedding&version=v3.01
 * - Pillows: ENV_URL/signage?page=pillows&version=v3.01
 */
const data = {
  ...v3,
  bedding: {
    ...v3.bedding,
    imgUri: bedding,
  },
  pillows: {
    ...v3.pillows,
    imgUri: pillows,
  },
};

export default data;
