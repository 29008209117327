import { Close } from '@mui/icons-material';
import { Alert, IconButton, Typography } from '@mui/material';
import { copyText } from 'language';
import { arrayOf, func, shape } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useCart, useSetCart } from 'contexts';
import multiPayUtils from 'utils/multiPayUtils';

const OrderCreationFailure = ({ error, handleClose }) => {
  const [message, setMessage] = useState('');
  const setCart = useSetCart();
  const cart = useCart();
  useEffect(() => {
    multiPayUtils.checkErrorResponseForVoid({ cart, error, setCart, setMessage });
    setMessage(error?.response?.data?.errors[0]?.detail);
  }, [error]);

  return (
    <Alert
      sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
      severity="warning"
      action={
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      }
    >
      <Typography variant="h6">{copyText.Cart.CheckoutButtons.orderCreationFailed}</Typography>
      <Typography variant="subtitle2">
        <strong>{copyText.Cart.CheckoutButtons.reason}:</strong> {message}
      </Typography>
      <Typography variant="subtitle2">
        <strong>{copyText.Cart.CheckoutButtons.resolution}:</strong>{' '}
        {multiPayUtils.getErrorAction(error?.response?.data?.errors[0]?.code)}
      </Typography>
    </Alert>
  );
};

OrderCreationFailure.propTypes = {
  handleClose: func.isRequired,
  error: shape({
    response: shape({
      data: shape({
        errors: arrayOf(
          shape({
            code: func.isRequired,
            detail: func.isRequired,
          }).isRequired,
        ),
      }).isRequired,
    }),
  }).isRequired,
};

export default OrderCreationFailure;
