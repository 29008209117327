import storesApi from 'dataAccess/api/stores.ts';
import shippingUtils from 'utils/shippingMethods';

const displayAdditionalOptions = (selectedDelivery) => {
  return selectedDelivery === 'Pick Up' || selectedDelivery === 'In-Home Setup' ? 8 : 16;
};

const filterPickupStores = (storesInput) => {
  return storesInput.filter((store) => store?.key !== 'UT-CC' && store?.key !== 'UT-IS');
};

const getStores = async (setLoading, setStores) => {
  setLoading(true);
  const result = await storesApi.getStores();
  setStores(filterPickupStores(result));
  setLoading(false);
};

const populateAvailableShippingMethods = async ({
  cart,
  sku,
  distributionChannel,
  setLoading,
  setAvailableMethods,
  setError,
  setShowMsg,
}) => {
  setLoading(true);
  try {
    const methods = await shippingUtils.getAvailableShippingMethods(cart, sku);
    const storeKey = cart?.store?.key;

    const filterMethods = (method, condition) => method.filter(condition);
    let availableMethods = methods;
    if (distributionChannel === 'OUTLET') {
      // Outlet items are carry out only
      availableMethods = filterMethods(methods, (method) => method.key === 'Carry Out');
    }
    if (storeKey === 'UT-CC' || storeKey === 'UT-IS') {
      // If store is CC or IS, you cannot select carry out
      availableMethods = filterMethods(methods, (method) => method.key !== 'Carry Out');
    }
    setAvailableMethods(availableMethods);
  } catch (err) {
    setError(err.message);
    setShowMsg(true);
  } finally {
    setLoading(false);
  }
};

const updateDeliveryMethod = async ({
  deliveryMethod,
  cart,
  sku,
  selectedDeliveryMethods,
  setLoading,
  setSelectedDeliveryMethods,
  setSelectedDelivery,
  setStoreKey,
  setError,
  setShowMsg,
}) => {
  setLoading(true);
  try {
    const newDeliveryMethod = deliveryMethod;
    newDeliveryMethod['storeKey'] = cart?.store?.key;
    const updatedDeliveryMethods = await shippingUtils.updateDeliveryMethods(
      sku,
      newDeliveryMethod,
      selectedDeliveryMethods,
    );
    setSelectedDeliveryMethods(updatedDeliveryMethods);
    setSelectedDelivery(deliveryMethod.name);
    if (newDeliveryMethod.storeKey !== 'UT-CC' && newDeliveryMethod.storeKey !== 'UT-IS') {
      setStoreKey(newDeliveryMethod.storeKey);
    }
  } catch (err) {
    setError(err.message);
    setShowMsg(true);
  } finally {
    setLoading(false);
  }
};

const handleUpdateMattressRemoval = async ({
  value,
  selectedDeliveryMethods,
  sku,
  setLoading,
  setRemoveMattress,
  setSelectedDeliveryMethods,
  setError,
  setShowMsg,
}) => {
  setLoading(true);
  try {
    const updatedDeliveryMethods = await shippingUtils.updateNeedsRemovalOfMattress(
      value,
      selectedDeliveryMethods,
      sku,
    );
    setRemoveMattress(value);
    setSelectedDeliveryMethods(updatedDeliveryMethods);
  } catch (err) {
    setError(err.message);
    setShowMsg(true);
  } finally {
    setLoading(false);
  }
};

const handleUpdateStore = async ({
  sKey,
  sku,
  selectedDeliveryMethods,
  setLoading,
  setStoreKey,
  setSelectedDeliveryMethods,
  setError,
  setShowMsg,
}) => {
  setLoading(true);
  try {
    const updatedDeliveryMethods = await shippingUtils.updateStoreForPickUp(
      sku,
      sKey,
      selectedDeliveryMethods,
    );
    setStoreKey(sKey);
    setSelectedDeliveryMethods(updatedDeliveryMethods);
  } catch (err) {
    setError(err.message);
    setShowMsg(true);
  } finally {
    setLoading(false);
  }
};

export {
  displayAdditionalOptions,
  filterPickupStores,
  getStores,
  populateAvailableShippingMethods,
  updateDeliveryMethod,
  handleUpdateMattressRemoval,
  handleUpdateStore,
};
