import { useState } from 'react';
import { Button, DialogContent, DialogActions, Grid, Typography } from '@mui/material';
import { copyText } from 'language';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';

const Feedback = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const continueToLink = async () => {
    window.location.href =
      'https://onpurple.atlassian.net/servicedesk/customer/portal/32/group/167';
  };

  return (
    <DialogTemplate
      openText={copyText.Feedback.sendFeedback}
      dialogTitle={copyText.Feedback.sendFeedback}
      openBtnVariant="contained"
      openBtnColor="primary"
      openBtnMargin={2}
      open={open}
      setOpen={setOpen}
      setMessage={setMessage}
      message={message}
    >
      <DialogContent>
        <Grid container columns={16} display="flex" flexDirection="column">
          <Grid item marginTop={3}>
            <Typography variant="h6">{copyText.Feedback.linkWarning}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          size="large"
          sx={{ paddingLeft: 4, paddingRight: 4 }}
          variant="contained"
          onClick={continueToLink}
        >
          {copyText.Feedback.continue}
        </Button>
      </DialogActions>
    </DialogTemplate>
  );
};

export default Feedback;
