import { Button } from '@mui/material';
import { useSelectedOrder, useSetOrderPayment, useSetSelectedOrder } from 'contexts/orders';
import orders from 'dataAccess/api/orders.ts';
import { copyText } from 'language';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ExchangeOrderBacklink = () => {
  const history = useHistory();
  const setOrderPayment = useSetOrderPayment();
  const setSelectedOrder = useSetSelectedOrder();
  const selectedOrder = useSelectedOrder();

  const handleViewOriginalOrder = async () => {
    const originalOrderId = selectedOrder.custom?.fields?.original_order_id ?? null;
    if (originalOrderId) {
      const orderById = await orders.getOrderById(originalOrderId);
      const originalOrder = await orders.getByOrderNumber(orderById.orderNumber);
      history.push(`/orders/order/${originalOrder.order.orderNumber}`);
      setOrderPayment(null);
      setSelectedOrder(originalOrder.order);
    }
  };
  return (
    <Button variant="outlined" onClick={handleViewOriginalOrder}>
      {copyText.Cart.CartTools.viewOriginalOrder}
    </Button>
  );
};

export default ExchangeOrderBacklink;
