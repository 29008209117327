import { lang } from 'language';
// --- CATEGORY IMAGES ---
import mposBase from './assets/categoryImages/Base Category Image.jpeg';
import mposBedding from './assets/categoryImages/Bedding Category Image.png';
import mposMattresses from './assets/categoryImages/Mattress Category Image.jpg';
import mposPajamas from './assets/categoryImages/Pajamas category image.png';
import mposPillows from './assets/categoryImages/Pillow Category Image.png';
import mposSeatCushions from './assets/categoryImages/Seat Cushion Category Image.jpg';
import mposSheets from './assets/categoryImages/Sheets category image.png';
import mposSquishy from './assets/categoryImages/Squishy Category Image.jpg';
import giftCard from './assets/categoryImages/Gift Card Category Image.png';
// -----------------------

const categoryImages = {
  'mpos-bed-frames': mposBase,
  'mpos-bedding': mposBedding,
  'mpos-mattresses': mposMattresses,
  'mpos-pajamas': mposPajamas,
  'mpos-pillows': mposPillows,
  'mpos-seat-cushions': mposSeatCushions,
  'mpos-sheets': mposSheets,
  'mpos-squishy': mposSquishy,
  'mpos-gift-card': giftCard,
};

const sortCategories = (categories) => {
  // hard-coded category slugs in the order we want them to appear
  const orderedCategoriesSlugs = [
    'mpos-pillows',
    'mpos-mattresses',
    'mpos-bed-frames',
    'mpos-sheets',
    'mpos-bedding',
    'mpos-seat-cushions',
    'mpos-gift-card',
    'mpos-pajamas',
    'mpos-squishy',
  ];
  const sortedCategories = categories.sort((a, b) => {
    const aIndex = orderedCategoriesSlugs.indexOf(a.slug[lang].toLowerCase());
    const bIndex = orderedCategoriesSlugs.indexOf(b.slug[lang].toLowerCase());
    return aIndex - bIndex;
  });
  return sortedCategories;
};

export default {
  categoryImages,
  sortCategories,
};
