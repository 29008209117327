import { Container, Grid } from '@mui/material';
import ViewTransition from 'components/Transitions/ViewTransition';
import LeftColumn from './Components/LeftColumn/LeftColumn';
import RightColumn from './Components/RightColumn/RightColumn';

const Cart = () => {
  return (
    <ViewTransition>
      <Container sx={{ mt: 10, mb: 15 }}>
        <Grid container columnSpacing={4} columns={16}>
          <LeftColumn />
          <RightColumn />
        </Grid>
      </Container>
    </ViewTransition>
  );
};

export default Cart;
