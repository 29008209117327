// This file contains copies of error codes from orders-ms. We use these for specific error handling in the frontend.
const ORDER_NOT_CUSTOMERS = 'ORD-001';
const NO_SHIPPING_ADDRESS = 'ORD-002';
const SHIPPING_METHOD_ERROR = 'ORD-003';
const PRE_AUTH_ERROR = 'ORD-004';
const PRE_AUTH_FRAUD = 'ORD-005';
const ITEMS_CAN_NOT_BE_SHIPPED = 'ORD-006';
const NO_REMAINING_AMOUNT_TO_AUTHORIZE = 'ORD-007';
const GIFT_CARD_BALANCE_NOT_ENOUGH = 'ORD-008';
const DRAFT_CART_NOT_FOUND = 'ORD-009';
const ROLE_NOT_ALLOWED = 'ORD-010';
const NON_LEASABLE_ITEMS = 'ORD-011';
const CT_PAYMENT_UPDATE_FAILURE = 'ORD-012';
const PAYMENT_CAPTURE_FAILURE = 'ORD-013';
const PAYMENT_RETRIEVAL_FAILURE = 'ORD-015';
const CT_PAYMENT_UPDATED_FAILED_VOID_SUCCESS = 'ORD-016';

export default {
  ORDER_NOT_CUSTOMERS,
  PRE_AUTH_ERROR,
  PRE_AUTH_FRAUD,
  NO_SHIPPING_ADDRESS,
  SHIPPING_METHOD_ERROR,
  ITEMS_CAN_NOT_BE_SHIPPED,
  NO_REMAINING_AMOUNT_TO_AUTHORIZE,
  GIFT_CARD_BALANCE_NOT_ENOUGH,
  DRAFT_CART_NOT_FOUND,
  ROLE_NOT_ALLOWED,
  NON_LEASABLE_ITEMS,
  CT_PAYMENT_UPDATE_FAILURE,
  CT_PAYMENT_UPDATED_FAILED_VOID_SUCCESS,
  PAYMENT_CAPTURE_FAILURE,
  PAYMENT_RETRIEVAL_FAILURE,
};
