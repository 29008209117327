const isValidEmail = (emailCandidate) => {
  // check if the input is an email and does not contain double periods
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const hasDoublePeriods = /\.{2,}/.test(emailCandidate);
  return emailRegex.test(emailCandidate) && !hasDoublePeriods;
};

const isValidName = (nameCandidate) => {
  // Check if the input contains at least one non-space character
  return nameCandidate.trim().length > 0;
};

const isInvalidEntry = (input) => {
  // Check if the input is empty or contains only spaces
  if (input.length > 0) {
    // input is not empty
    return input.trim().length === 0;
  }
  // input is empty
  return false;
};

const isValidPhoneNumber = (phoneNumber) => /^[0-9+()\-\s]+$/.test(phoneNumber);

export default {
  isValidEmail,
  isValidPhoneNumber,
  isValidName,
  isInvalidEntry,
};
