import React from 'react';
import { Typography } from '@mui/material';
import { node } from 'prop-types';

import { logError } from 'utils/errorUtils';
import { copyText } from 'language';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    logError({ method: 'component/ErrorBoundary', error });
    return { hasError: true };
  }

  render() {
    const { state, props } = this;

    if (state.hasError) {
      // Render an error message or fallback UI
      return <Typography>{copyText.Page404.somethingWrong}</Typography>;
    }
    // Render the children components
    return props.children;
  }
}

ErrorBoundary.propTypes = {
  children: node.isRequired,
};

export default ErrorBoundary;
