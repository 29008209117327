import { createTheme } from '@mui/material/styles';
import { colors, backgroundColors, darkModeColors } from 'utils/themeColors';

const spacing = 8;

// Define base theme options that can be customized for light and dark modes
const getBaseThemeOptions = (themeType) => ({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    h2: {
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    h3: {
      fontWeight: 700,
      fontSize: 12,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    h4: {
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    h5: {
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    h6: {
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    page_title: {
      fontSize: 35,
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    sub_header: {
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
      fontSize: 20,
    },
    sub_title: {
      fontWeight: 700,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
      fontSize: 13,
    },
    dialog_content_heading: {
      fontWeight: 700,
      fontSize: 30,
      color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
    },
    list_item_bold_title: {
      lineHeight: 2,
      fontWeight: 700,
      fontSize: 14,
    },
    list_item: {
      fontWeight: 400,
      lineHeight: 2,
      fontSize: 14,
    },
    fontWeightRegular: 400,
    fontSize: 14,
  },
  spacing,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: themeType === 'dark' ? darkModeColors.lightMidnight : colors.lightGrayBG,
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'text', color: 'primary' },
          style: {
            fontWeight: 700,
            fontSize: 12,
            color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
          },
        },
        {
          props: { variant: 'outlined', color: 'primary' },
          style: {
            fontWeight: 700,
            fontSize: 12,
            color: themeType === 'dark' ? darkModeColors.white : colors.primaryPurple,
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            fontWeight: 700,
            fontSize: 12,
            color: themeType === 'dark' ? darkModeColors.white : colors.white,
          },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 20,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: spacing * 2,
          backgroundColor: themeType === 'dark' ? darkModeColors.lightMidnight : colors.lightGrayBG,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: themeType === 'dark' ? darkModeColors.lightMidnight : colors.lightGrayBG,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: spacing * 3,
          backgroundColor:
            themeType === 'dark' ? backgroundColors.darkModeBackground : colors.white,
        },
      },
    },
    MuiSnackbar: {
      defaultProps: {
        autoHideDuration: 6000,
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: themeType === 'dark' ? colors.midnight : colors.lightGrayBG,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: themeType === 'dark' ? colors.midnight : colors.mediumPurple,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor:
            themeType === 'dark' ? backgroundColors.darkModeBackground : colors.white,
        },
      },
    },
  },
});

// Define light and dark palettes
const lightPalette = {
  palette: {
    mode: 'light', // Updated from 'type' to 'mode'
    primary: {
      main: colors.primaryPurple,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.mediumPurple,
      contrastText: colors.white,
    },
    tertiary: {
      main: colors.darkAqua,
      contrastText: colors.white,
    },
    text: {
      primary: colors.lightMidnight,
      secondary: colors.mediumPurple,
    },
    divider: colors.grayUtility,
    error: {
      main: colors.redUtility,
    },
    success: {
      main: colors.greenUtility,
    },
    label_promo: {
      main: colors.darkAqua,
      contrastText: colors.white,
    },
    label_bestseller: {
      main: colors.lightPurpleBG,
      contrastText: colors.primaryPurple,
    },
    background: {
      default: colors.white, // Set the default background color for light mode
    },
    ...colors,
    ...backgroundColors,
  },
  components: {
    ...getBaseThemeOptions('light').components,
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: colors.lightGrayBG,
        },
      },
    },
  },
};

const darkPalette = {
  palette: {
    mode: 'dark',
    background: {
      default: backgroundColors.darkModeBackground,
      paper: darkModeColors.lightMidnight,
      contrastText: darkModeColors.white,
    },
    primary: {
      main: darkModeColors.mediumPurple,
    },
    secondary: {
      main: darkModeColors.primaryPurple,
      contrastText: darkModeColors.white,
    },
    tertiary: {
      main: darkModeColors.darkAqua,
      contrastText: darkModeColors.white,
    },
    text: {
      primary: darkModeColors.white,
      secondary: darkModeColors.white,
    },
    divider: darkModeColors.grayUtility,
    error: {
      main: darkModeColors.redUtility,
    },
    success: {
      main: darkModeColors.greenUtility,
    },
    label_promo: {
      main: darkModeColors.darkAqua,
      contrastText: darkModeColors.white,
    },
    label_bestseller: {
      main: darkModeColors.lightPurpleBG,
      contrastText: darkModeColors.primaryPurple,
    },
    ...darkModeColors,
    ...backgroundColors,
  },
  components: {
    ...getBaseThemeOptions('dark').components,
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          backgroundColor: darkModeColors.lightMidnight,
          color: darkModeColors.white,
          contrastText: darkModeColors.white,
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: darkModeColors.white, // Default color
          '&.Mui-selected': {
            color: darkModeColors.white, // Keeps the color the same even when selected
          },
        },
        label: {
          '&.Mui-selected': {
            color: darkModeColors.white, // Ensures label color doesn't change when selected
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          color: darkModeColors.white,
        },
      },
    },
  },
};

// Function to get theme based on session storage
const getTheme = (darkMode) => {
  const themeType = darkMode ? 'dark' : 'light';
  const palette = darkMode ? darkPalette : lightPalette;

  return createTheme({
    ...getBaseThemeOptions(themeType),
    ...palette,
  });
};

export default { getTheme, theme: getTheme(false) };
