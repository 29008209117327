import { Alert, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { copyText } from 'language';
import { func, bool } from 'prop-types';
import React from 'react';
import TextLoader from 'components/TextLoader';

const CancelAlert = ({
  handleCancel,
  setShowCancelWarning,
  showVoidError,
  voidLoading,
  handleClearCart,
}) => {
  return (
    <Alert severity="warning" sx={{ mt: 3 }}>
      <Typography textAlign="center">{copyText.Cart.CheckoutButtons.cancelWarning}</Typography>
      {showVoidError && (
        <Alert severity="error">
          <Typography textAlign="center">{copyText.Cart.CheckoutButtons.voidError}</Typography>
        </Alert>
      )}
      <Box display="flex" justifyContent="space-between" mt={3}>
        {!showVoidError && (
          <Button variant="outlined" onClick={handleCancel}>
            <TextLoader
              text={copyText.Cart.CheckoutButtons.yesCancel}
              loading={voidLoading}
              size={20}
            />
          </Button>
        )}
        {showVoidError && (
          <Button variant="outlined" onClick={handleClearCart}>
            <TextLoader text={copyText.Cart.CheckoutButtons.clearCart} loading={voidLoading} />
          </Button>
        )}
        {showVoidError && (
          <Button variant="contained" onClick={handleCancel}>
            <TextLoader
              text={copyText.Cart.CheckoutButtons.retryVoid}
              loading={voidLoading}
              size={20}
            />
          </Button>
        )}
        {!showVoidError && (
          <Button variant="contained" onClick={() => setShowCancelWarning(false)}>
            {copyText.Cart.CheckoutButtons.noContinue}
          </Button>
        )}
      </Box>
    </Alert>
  );
};

CancelAlert.propTypes = {
  handleCancel: func.isRequired,
  setShowCancelWarning: func.isRequired,
  voidLoading: bool,
  showVoidError: bool,
  handleClearCart: func.isRequired,
};
CancelAlert.defaultProps = {
  voidLoading: false,
  showVoidError: false,
};

export default CancelAlert;
