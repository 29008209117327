import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { useState } from 'react';
import { copyText } from 'language';
import TextLoader from 'components/TextLoader';
import cartGiftcard from 'dataAccess/api/cart.giftcard.ts';
import { useCart, useSetCart } from 'contexts';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';

const GiftCard = () => {
  const cart = useCart();
  const setCart = useSetCart();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [giftCardNumber, setGiftCardNumber] = useState();
  const [securityCode, setSecurityCode] = useState();
  const handleCloseModal = () => {
    setOpen(false);
    setGiftCardNumber(null);
    setSecurityCode(null);
  };

  const getError = (err) => {
    return (
      err?.response?.data?.errors[0]?.meta?.error ??
      err?.response?.data?.errors[0]?.title ??
      err.message
    );
  };

  const addGiftCardNumber = (e) => {
    setGiftCardNumber(e.target.value);
  };
  const addSecurityCode = (e) => {
    setSecurityCode(e.target.value);
  };

  const applyBalance = async () => {
    try {
      setLoading(true);

      const giftCard = {
        giftCardNumber,
        securityCode,
      };
      const result = await cartGiftcard.addGiftCardToCart(cart.id, giftCard);
      setCart(result.data);
      setLoading(false);
      setOpen(false);
      setMessage(copyText.Cart.CartTools.successfulGiftCard);
      setGiftCardNumber(null);
      setSecurityCode(null);
    } catch (err) {
      setLoading(false);
      setMessage(`${copyText.Cart.CartTools.failedGiftCard}: ${getError(err)}`);
    }
  };

  return (
    <DialogTemplate
      open={open}
      setOpen={setOpen}
      onClose={handleCloseModal}
      message={message}
      setMessage={setMessage}
      loading={loading}
      dialogTitle={copyText.Cart.CartTools.applyGiftCard}
      openText={copyText.App.add}
      openBtnVariant="text"
      openBtnPadding={0}
    >
      <DialogContent>
        <TextField
          sx={{ mb: 2, mt: 2 }}
          onChange={addGiftCardNumber}
          fullWidth
          placeholder={copyText.Cart.CartTools.giftCardNumber}
        />
        <TextField
          placeholder={copyText.Cart.CartTools.giftCardSecurityCode}
          onChange={addSecurityCode}
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="contained"
          onClick={applyBalance}
          disabled={loading || !securityCode || !giftCardNumber}
        >
          <TextLoader text={copyText.Cart.CartTools.applyBalance} loading={loading} size={20} />{' '}
        </Button>
      </DialogActions>
    </DialogTemplate>
  );
};

export default GiftCard;
