import { Close } from '@mui/icons-material';
import { IconButton, Snackbar } from '@mui/material';
import { func, string } from 'prop-types';

const SnackbarMessage = ({ message, setMessage }) => {
  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setMessage('')}>
      <Close />
    </IconButton>
  );
  return (
    <Snackbar
      sx={{ color: 'primary' }}
      open={!!message}
      autoHideDuration={6000}
      onClose={() => setMessage('')}
      message={message}
      action={action}
    />
  );
};

SnackbarMessage.propTypes = {
  message: string,
  setMessage: func.isRequired,
};

SnackbarMessage.defaultProps = {
  message: '',
};

export default SnackbarMessage;
