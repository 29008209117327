// This function is to check if the discount amount is more than 50%  of the total cart amount and block non managers from creating discount codes over 50%.
import financialCalculators from './financialCalculators/financialCalculators';

const checkOutletCartDiscountAmount = ({ discountValueType, discountValue, cart, mposManager }) => {
  // if the user is a manager, they can create any discount
  // true in this case would mean that the user has the permission to create the discount

  if (mposManager) return true;

  if (discountValueType === 'relative' && discountValue > 50 && !mposManager) {
    return false;
  }
  if (discountValueType === 'relative' && discountValue <= 50) {
    return true;
  }
  if (discountValueType === 'absolute') {
    const discountPercentage = (discountValue * 100) / financialCalculators.getSubtotal(cart);
    if (discountPercentage > 0.5) {
      return false;
    }
    return true;
  }

  return false;
};

const checkOutletProductDiscountAmount = ({
  discountValueType,
  discountValue,
  discountQuantity,
  sku,
  cart,
  mposManager,
}) => {
  // if the user is a manager, they can create any discount
  // true in this case would mean that the user has the permission to create the discount
  if (mposManager) return true;
  // relative == a percentage of the product price
  if (discountValueType === 'relative') {
    const product = cart.lineItems.find((item) => item.variant.sku === sku);
    const productPrice = product.price.value.centAmount;
    // we divide the discount value by 100 to get the percentage based on the whole number that is passed in by the user
    const discountAmount = (discountValue / 100) * productPrice * (discountQuantity + 1);
    const discountPercentage = discountAmount / financialCalculators.getSubtotal(cart);
    if (discountPercentage <= 0.5) {
      return true;
    }
    return false;
  }
  if (discountValueType === 'absolute') {
    const discountPercentage = (discountValue * 100) / financialCalculators.getSubtotal(cart);
    if (discountPercentage <= 0.5) {
      return true;
    }
    return false;
  }
  return false;
};

export default { checkOutletCartDiscountAmount, checkOutletProductDiscountAmount };
