import React from 'react';
import { Grid, Typography } from '@mui/material';

import ViewTransition from 'components/Transitions/ViewTransition';
import { copyText } from 'language';

const NoProducts = () => {
  return (
    <ViewTransition direction="up">
      <Grid container sx={{ height: '100vh' }}>
        <Grid item mt={15} sm={12}>
          <Typography variant="h1" textAlign="center">
            {copyText.Shop.Products.notFound}
          </Typography>
        </Grid>
      </Grid>
    </ViewTransition>
  );
};

export default NoProducts;
