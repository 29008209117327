import styled from 'styled-components';

export const Body = styled.div`
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  margin: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
`;

export const VideoPlayer = styled.div`
  display: none;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  background-color: black;
`;

export const AppDock = styled.div`
  background-color: #dcd7ebe5;
  width: 300px;
  height: 100px;
  opacity: 0.9;
  border-radius: 30px;
  line-height: 150px;
  text-align: center;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
`;

export const AppDockIcon = styled.img`
  border-radius: 20px;
  height: 4em;
`;
