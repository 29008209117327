import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Page404 from 'views/Page404/Page404';
import { OrdersContextProvider } from 'contexts/orders';
import Returns from './subviews/Returns/Returns';
// SUNSET: Move Orders view to new location: views/Orders.jsx
import OrderSearch from './subviews/OrderSearch/OrderSearch';
import OrderDetails from './subviews/OrderDetails/OrderDetails';
// Future Orders view location
// import Orders from './views/Orders';

const OrdersRoutes = () => {
  /**
   * --- Hierarchy ---
   * Orders Search -- "/orders"
   * |__ Individual Order -- "/orders/order/:orderId"
   * |   |__ Returns/Exchanges -- "/orders/order/:orderId/refund"
   */
  return (
    <OrdersContextProvider>
      <Switch>
        <Route exact path="/orders/order/:orderId/return" component={Returns} />
        <Route exact path="/orders/order/:orderId" component={OrderDetails} />
        <Route exact path="/orders" component={OrderSearch} />
        <Route path="*" component={Page404} />
      </Switch>
    </OrdersContextProvider>
  );
};

export default OrdersRoutes;
