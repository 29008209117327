import { checkOutletStore } from 'utils/checkOutletStore';

const getOktaData = () => {
  return JSON.parse(sessionStorage.getItem('okta-token-storage'));
};

const getDevelopers = () => {
  return [
    'adrian.ri@purple.com',
    'todd.f@purple.com',
    'joseph.d@purple.com',
    'jeffery.b@purple.com',
    'austin.wr@purple.com',
    'alona.f@purple.com',
    'babitha.a@purple.com',
    'bels.t@purple.com',
    'matthew.z@purple.com',
    'gregor.g@purple.com',
    'bronwyn.h@purple.com',
    'jax.m@purple.com',
    'tyler.r@purple.com',
  ];
};

export const getExperimentalStores = () => {
  return ['UT-06'];
};

export const canAccessDevTools = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const expUsers = getDevelopers();

  const userInList = expUsers.includes(userEmail);
  return userInList;
};

export const canDoSplitTransactions = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const users = getDevelopers();

  const userInList = users.includes(userEmail);
  return userInList;
};

export const canDoNewReturns = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const expUsers = getDevelopers();

  const userInList = expUsers.includes(userEmail);
  return userInList;
};

export const canDoChargeAfter = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const devs = getDevelopers();
  const userInList = devs.includes(userEmail);
  return userInList;
};

export const canUseFinalSaleFloorModelToggles = () => {
  return true;
};

export const canViewOutlets = () => {
  const currentUser = getOktaData();
  const userEmail = currentUser?.accessToken?.claims.sub;
  const devs = getDevelopers();
  const userInList = devs.includes(userEmail);
  const isOutlet = checkOutletStore();
  return isOutlet || userInList;
};

export default {
  canAccessDevTools,
  canDoNewReturns,
};
