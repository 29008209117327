import React from 'react';
import { Box, Typography } from '@mui/material';
import { copyText } from 'language';

const ReturnSuccessful = () => (
  <Box>
    <Typography color="primary" variant="h6">
      {copyText.Orders.Returns.returnSuccessfulMessage}
    </Typography>
  </Box>
);

export default ReturnSuccessful;
