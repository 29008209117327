// --- Google Analytics ---
const script = document.createElement('script');
script.async = true;
script.id = 'google_analytics_4';
script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_TAG_ID}`;
document.head.appendChild(script);

window.dataLayer = window.dataLayer || [];
const gtag = (...args) => {
  // NOTE: dataLayer is inherited from the script source (gtag)
  // eslint-disable-next-line no-undef
  dataLayer.push(args);
};
gtag('js', new Date());
gtag('config', process.env.REACT_APP_GOOGLE_TAG_ID);
