const ensureValidAccessToken = async (oktaAuth) => {
  const tokenManager = oktaAuth.tokenManager;
  let accessToken = await tokenManager.get('accessToken');
  if (!accessToken || tokenManager.hasExpired(accessToken)) {
    try {
      // leave this console log in so that we can see when the token is refreshing
      // eslint-disable-next-line no-console
      console.log('accessToken is expired or missing. refreshing...');
      // If the token is expired or missing, refresh the access token
      const freshTokens = await tokenManager.renew('accessToken');
      accessToken = freshTokens.accessToken;
      // leave this console log in so that we can see when the token is refreshed
      // eslint-disable-next-line no-console
      console.log('accessToken refreshed.');
      return accessToken; // Return the valid access token
    } catch (err) {
      // leave this console log in so that we can see when the token fails to refresh
      // eslint-disable-next-line no-console
      console.log(`Error refreshing access token: ${JSON.stringify(err)}`);
    }
  }
  return accessToken.accessToken;
};

export default {
  ensureValidAccessToken,
};
