import { copyText, lang } from 'language';
import formValidation from 'utils/formValidation';
import draftOrderUtils from 'utils/draftOrderUtils';
import shippingInfoInputUtils from 'utils/shippingInfoInputUtils';
import cartService from 'dataAccess/api/cart.ts';

import convertCentToDollar from './convertCentToDollar';
import financialCalculators from './financialCalculators/financialCalculators';
import { logError } from './errorUtils';
import storeLocation from './storeLocation';

const getDescription = (variant) => {
  const desc = variant.attributes.find((attr) => attr.name === 'title');
  const colorDesc = variant.attributes.find((attr) => attr.name === 'color');
  let description = desc?.value ?? '';
  if (colorDesc && colorDesc.value && colorDesc?.value?.label) {
    description = `${desc?.value} | ${colorDesc?.value?.label}`;
  }
  return description;
};

const getStrikethroughPrice = (lineItem) => {
  const basePrice = lineItem.price.value.centAmount;
  let discountPrice = basePrice;
  const discounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
  if (discounts.length > 0) {
    discountPrice = discounts.reduce((acc, discount) => {
      return acc + discount.cent_amount;
    }, basePrice);
  }
  return discountPrice !== basePrice ? discountPrice : null;
};

const formatLineItems = (lineItems) => {
  const items = [];
  lineItems.forEach((lineItem) => {
    const price = convertCentToDollar(lineItem.totalPrice.centAmount) ?? '';
    const description = getDescription(lineItem.variant);
    const strikeThroughPrice = getStrikethroughPrice(lineItem)
      ? convertCentToDollar(getStrikethroughPrice(lineItem) * lineItem.quantity)
      : null;
    const item = {
      product_id: lineItem.productId ?? '',
      sku: lineItem?.variant.sku ?? '',
      name: lineItem?.name[lang] ?? '',
      description: description ?? '',
      qty: lineItem?.quantity ?? 0,
      price,
      strike_through_price: strikeThroughPrice ?? '',
      image: lineItem?.variant?.images[0]?.url ?? '',
      finalSale: lineItem?.custom?.fields?.final_sale ?? false,
      floorModel: lineItem?.custom?.fields?.floor_model ?? false,
    };
    items.push(item);
  });
  return items;
};

// SUNSET: ToddF Remove if not needed for draft orders
// const getStoreData = async (storeKey) => {
//   const storeData = await storeLocation.getStoreByKey(storeKey);
//   const storeDetails = storeData?.supplyChannels[0]?.obj;
//   const storeParams = {
//     name: storeDetails?.name[lang] ?? '',
//     streetAddress: storeDetails?.address?.streetName ?? '',
//     streetNumber: storeDetails?.address?.streetNumber ?? '',
//     postalCode: storeDetails?.address?.postalCode ?? '',
//     city: storeDetails?.address?.city ?? '',
//     state: storeDetails?.address?.state ?? '',
//     description: storeDetails?.description[lang] ?? '',
//     phone: storeDetails?.phone ?? '',
//     openTime: storeDetails?.hours?.open ?? '',
//     closeTime: storeDetails?.hours?.close ?? '',
//     key: storeDetails?.key ?? '',
//   };
//   return storeParams;
// };

const formatDraftOrderItems = async (email, cart, okta, note, customerName) => {
  const agentEmail = okta.authStateManager?._authState?.idToken?.claims?.email ?? '';
  const agentName = okta.authStateManager?._authState?.idToken?.claims?.name ?? '';
  const subtotal = convertCentToDollar(financialCalculators.getSubtotal(cart));
  const tax = convertCentToDollar(financialCalculators.getTax(cart));
  const shipping = convertCentToDollar(financialCalculators.getShippingTotals(cart));
  const discounts = convertCentToDollar(financialCalculators.getCartDiscounts(cart));
  const orderTotal = convertCentToDollar(financialCalculators.getTotalPrice(cart));
  const lineItems = formatLineItems(cart.lineItems);
  // let storeData = {};
  // if (cart && cart.store && cart.store.key) {
  //   storeData = await getStoreData(cart.store.key);
  // }
  const params = {
    agentName,
    agentEmail,
    agentNote: note ?? '',
    customerEmail: email ?? '',
    customerFirstName: customerName ?? '',
    cartId: cart?.id,
    subtotal,
    tax,
    shipping,
    discounts,
    orderTotal,
    lineItems,
    storeKey: cart?.store?.key ?? '',
  };
  return params;
};

const checkCustomerInfoFields = (customerInfo, setFieldsAreValid) => {
  const emailIsValid = formValidation.isValidEmail(customerInfo?.email);
  const fieldsAreValid = !!(emailIsValid && customerInfo?.customerName);
  setFieldsAreValid(fieldsAreValid);
};

const markCartAsDraft = async (cart, setMessage) => {
  try {
    setMessage(copyText.Cart.CartTools.DraftOrder.markingCartAsDraft);
    const result = await cartService.markCartAsDraft(cart?.id);
    return result;
  } catch (error) {
    setMessage(copyText.Cart.CartTools.DraftOrder.markAsDraftFailure);
    logError({
      method: 'markCartAsDraft',
      errorInfo: error,
      message: error?.message,
      source: 'utils/draftOrderUtils',
    });
    return false;
  }
};

const handleGenerateLink = ({ cart, setLinkGenerated, setMessage }) => {
  const link = `${process.env.REACT_APP_ECOMM_SITE_URL}/draft-order/checkout?cartId=${cart?.id}&c3ch=email&c3nid=draft_order`;
  navigator.clipboard.writeText(link);
  setLinkGenerated(true);
  setMessage(copyText.Cart.CartTools.DraftOrder.linkCopied);
  setTimeout(() => {
    setLinkGenerated(false);
  }, 2000);
};

const showGenerateLink = () => {
  const store = storeLocation.getLocationStorage();
  const salesStore = 'UT-IS';
  const ccareStore = 'UT-CC';
  if (store?.key === salesStore || store?.key === ccareStore) {
    return true;
  }
  return false;
};

const sendDraftOrderEmail = async (cart, customerInfo, oktaAuth, setMessage) => {
  try {
    setMessage(copyText.Cart.CartTools.DraftOrder.sendDraft);
    const params = await draftOrderUtils.formatDraftOrderItems(
      customerInfo?.email,
      cart,
      oktaAuth,
      customerInfo.note,
      customerInfo.customerName,
    );
    const draftEmailSent = await cartService.sendDraftOrderEmail(params);
    if (draftEmailSent) {
      setMessage(copyText.Cart.CartTools.DraftOrder.sendDraftSuccess);
    }
  } catch (error) {
    setMessage(copyText.Cart.CartTools.DraftOrder.sendDraftFailure);
    logError({
      method: 'sendDraftOrderEmail',
      errorInfo: error,
      message: error?.message,
      source: 'utils/draftOrderUtils',
    });
  }
};

const handleDeliveryAddress = ({
  setActiveStep,
  deliveryAddressWarning,
  setDeliveryAddressWarning,
  setEnableUpdateCart,
  cart,
  formData,
  setLoading,
  setCart,
  setShowErrorMessage,
  setCustomerInfo,
}) => {
  const validFields = shippingInfoInputUtils.checkFormFields({ formData, setEnableUpdateCart });
  if (!validFields && deliveryAddressWarning) {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setDeliveryAddressWarning(false);
    cartService.setDraftInfo(cart.id, {
      customerEmail: formData.email,
      draftFirstName: formData.firstName,
    });
    return;
  }
  if (!validFields) {
    setDeliveryAddressWarning(true);
    return;
  }
  setDeliveryAddressWarning(false);
  shippingInfoInputUtils.updateCartWithShippingAddress({
    cart,
    formData,
    validateShippingAddress: true,
    setLoading,
    setCart,
    setShowErrorMessage,
    setActiveStep,
    setEnableUpdateCart,
  });
  setCustomerInfo((prevState) => ({
    email: formData?.email || prevState?.email,
    customerName: formData?.firstName || prevState?.customerName,
    note: prevState?.note,
  }));
};

export default {
  formatDraftOrderItems,
  getDescription,
  checkCustomerInfoFields,
  markCartAsDraft,
  sendDraftOrderEmail,
  handleDeliveryAddress,
  handleGenerateLink,
  showGenerateLink,
};
