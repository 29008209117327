import { Box } from '@mui/material';
import { copyText } from 'language';

const EmptyCart = () => {
  return (
    <Box
      component="div"
      alignItems="center"
      height="50vh"
      justifyContent="center"
      text-align="center"
      display="flex"
    >
      {copyText.Cart.ItemList.emptyCart}
    </Box>
  );
};

export default EmptyCart;
