import React, { useState, useEffect } from 'react';
import { Box, Typography, Chip, List } from '@mui/material';
import { copyText } from 'language';
import getDiscountCodes from 'utils/discountCodes';
import { shape, string } from 'prop-types';

const DiscountDisplay = ({ order }) => {
  const [discountList, setDiscountList] = useState([]);

  const displayDiscounts = (list) => {
    if (list.length) {
      return list.map((discount) => {
        return (
          <Chip
            key={discount.id}
            label={discount.display_name}
            variant="outlined"
            color="secondary"
            sx={{ mb: 1 }}
          />
        );
      });
    }
    return <Typography>{copyText.Orders.Discounts.none}</Typography>;
  };

  useEffect(() => {
    setDiscountList(getDiscountCodes(order));
  }, [order.id]);

  return (
    <Box key="discount_display" sx={{ mt: 1, p: 2 }}>
      <Typography variant="sub_title">{copyText.Orders.Discounts.usedDiscounts}</Typography>
      <List sx={{ overflow: 'scroll', maxHeight: '10vh' }}>{displayDiscounts(discountList)}</List>
    </Box>
  );
};

export default DiscountDisplay;

DiscountDisplay.propTypes = {
  order: shape({ id: string }),
};

DiscountDisplay.defaultProps = {
  order: { id: '' },
};
