// This function is to check if the discount amount is more than 25%  of the total cart amount and returns true if it is.
import financialCalculators from './financialCalculators/financialCalculators';

const checkCartDiscountAmount = ({ discountValueType, discountValue, cart }) => {
  if (discountValueType === 'relative' && discountValue >= 25) {
    return true;
  }
  if (discountValueType === 'relative' && discountValue <= 25) {
    return false;
  }
  if (discountValueType === 'absolute') {
    const discountPercentage = (discountValue * 100) / financialCalculators.getSubtotal(cart);
    if (discountPercentage > 0.25) {
      return true;
    }
    return false;
  }

  return false;
};

const checkProductDiscountAmount = ({
  discountValueType,
  discountValue,
  discountQuantity,
  sku,
  cart,
}) => {
  // relative == a percentage of the product price
  if (discountValueType === 'relative') {
    const product = cart.lineItems.find((item) => item.variant.sku === sku);
    const productPrice = product.price.value.centAmount;
    // we divide the discount value by 100 to get the percentage based on the whole number that is passed in by the user
    const discountAmount = (discountValue / 100) * productPrice * (discountQuantity + 1);
    const discountPercentage = discountAmount / financialCalculators.getSubtotal(cart);
    if (discountPercentage > 0.25) {
      return true;
    }
    return false;
  }
  if (discountValueType === 'absolute') {
    const discountPercentage = (discountValue * 100) / financialCalculators.getSubtotal(cart);
    if (discountPercentage > 0.25) {
      return true;
    }
    return false;
  }
  return false;
};

export default { checkCartDiscountAmount, checkProductDiscountAmount };
