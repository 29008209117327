import {
  Create as CartHistoryIcon,
  CreateOutlined as CartHistoryIconOutlined,
  ShoppingCart as ShoppingCartIcon,
  AssignmentOutlined as InventoryIconOutlined,
  ShoppingCartOutlined,
  Assignment as InventoryIcon,
  StorefrontOutlined,
  StorefrontRounded,
} from '@mui/icons-material';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { copyText } from 'language';
import { useIsOutlet } from 'contexts';

const Footer = () => {
  const history = useHistory();
  const location = useLocation();
  const isOutlet = useIsOutlet();

  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <BottomNavigation
        showLabels
        value={location?.pathname}
        onChange={(event, newValue) => {
          history?.push(newValue);
        }}
        sx={{ padding: 5 }}
      >
        <BottomNavigationAction
          label={copyText.Footer.shop}
          value="/shop/retail"
          icon={
            location?.pathname.includes('/shop/retail') ? (
              <ShoppingCartIcon fontSize="large" />
            ) : (
              <ShoppingCartOutlined fontSize="large" />
            )
          }
        />
        {isOutlet && (
          <BottomNavigationAction
            label="Outlet"
            value="/shop/outlet"
            icon={
              location?.pathname.includes('/shop/outlet') ? (
                <StorefrontRounded fontSize="large" />
              ) : (
                <StorefrontOutlined fontSize="large" />
              )
            }
          />
        )}
        <BottomNavigationAction
          label={copyText.Footer.orders}
          value="/orders"
          icon={
            location?.pathname.includes('/orders') ? (
              <InventoryIcon fontSize="large" />
            ) : (
              <InventoryIconOutlined fontSize="large" />
            )
          }
        />
        <BottomNavigationAction
          label={copyText.Footer.drafts}
          value="/cart-history"
          icon={
            location?.pathname.includes('/cart-history') ? (
              <CartHistoryIcon fontSize="large" />
            ) : (
              <CartHistoryIconOutlined fontSize="large" />
            )
          }
        />
      </BottomNavigation>
    </Paper>
  );
};

export default Footer;
