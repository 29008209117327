const handleSubmit = ({ submitSearchQuery, setOpen }) => {
  submitSearchQuery({ page: 0 });
  setOpen(false);
};

const handleSelectAgent = ({ agent, setSelectedAgent, updateForm }) => {
  setSelectedAgent(agent);
  const event = {
    target: {
      getAttribute: (attr) => {
        if (attr === 'name') {
          return 'customerEmail';
        }
        return null;
      },
      value: agent?.email,
      type: 'text',
    },
  };
  updateForm(event);
};

const handleSelectLocation = ({ e, updateForm }) => {
  const event = {
    target: {
      getAttribute: (attr) => {
        if (attr === 'name') {
          return e.target.name;
        }
        return null;
      },
      value: e.target.value,
      type: 'select',
    },
  };
  updateForm(event);
};

export default {
  handleSubmit,
  handleSelectAgent,
  handleSelectLocation,
};
