import { Alert, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import TextLoader from 'components/TextLoader';
import { func, string } from 'prop-types';
import { copyText } from 'language';
import { useCart, useSetCart } from 'contexts';
import chargeAfterUtils from '../chargeAfterUtils';

const Polling = ({ setState, setStatusMessage, statusMessage, setOrderDetails }) => {
  const [loading, setLoading] = useState(false);
  const cart = useCart();
  const setCart = useSetCart();
  // sunset: remove next line once sandbox is connected

  return (
    <Grid container display="flex" justifyContent="center" sx={{ mt: 3, px: 3, mb: 5 }}>
      <Grid item xs={16} display="flex" justifyContent="center" sx={{ mb: 5 }}>
        <CircularProgress size={100} thickness={1} />
      </Grid>
      <Typography data-testid="ca-polling" textAlign="center" fontWeight={700} mb={1}>
        {copyText.Cart.CartTools.ChargeAfter.waitingForAppCompletion}
      </Typography>
      <Typography textAlign="center" variant="subtitle2" sx={{ mb: 3 }}>
        {copyText.Cart.CartTools.ChargeAfter.appCompletionInstructions}
      </Typography>
      <Grid item xs={16} display="flex" justifyContent="center">
        <Button
          variant="contained"
          onClick={() =>
            chargeAfterUtils.pollForAppComplete({
              setCart,
              cart,
              setLoading,
              setState,
              setStatusMessage,
              setOrderDetails,
            })
          }
          disabled={loading}
        >
          <TextLoader
            loading={loading}
            size={20}
            text={copyText.Cart.CartTools.ChargeAfter.retrieveDetailsCreateOrder}
          />
        </Button>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center" mt={5}>
        <Typography variant="subtitle2" textAlign="center">
          {copyText.Cart.CartTools.ChargeAfter.patienceIsAVirtue}
        </Typography>
        {/* <Button
          onClick={() =>
            chargeAfterUtils.handleUpdateState({
              cartId: cart?.id,
              setCart,
              setState,
              setLoading,
              newState: 'initial-verified',
              additionalDetails: { statusTrackingId: null, correlationId: null },
              setErrorMessage: setStatusMessage,
            })
          }
          size="small"
          variant="outlined"
        >
          <TextLoader
            loading={loading}
            size={20}
            text={copyText.Cart.CartTools.ChargeAfter.updateDetailsResendLink}
          />
        </Button> */}
      </Box>
      <Grid
        mt={2}
        item
        xs={12}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        textAlign="center"
      >
        {statusMessage && (
          <Alert severity="error">
            <Typography color="error">{statusMessage}</Typography>
            <Typography color="error" variant="subtitle2" mt={1}>
              {copyText.App.genericError}
            </Typography>
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

Polling.propTypes = {
  setState: func.isRequired,
  statusMessage: string.isRequired,
  setStatusMessage: func.isRequired,
  setOrderDetails: func.isRequired,
};

export default Polling;
