import { useState, useEffect } from 'react';
import { Tune } from '@mui/icons-material';
import {
  Button,
  DialogActions,
  DialogContent,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
} from '@mui/material';

import { func, shape, bool } from 'prop-types';

import { copyText, lang } from 'language';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';
import attributionUtils from 'components/AgentAttribution/attributionUtils';
import storeLocation from 'utils/storeLocation';
import CartHistoryFiltersUtils from './CartHistoryFilters.utils';

const CartHistoryFilters = ({
  formData,
  updateForm,
  submitSearchQuery,
  selectedAgent,
  setSelectedAgent,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loadingSuccess, setLoadingSuccess] = useState(true);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [agents, setAgents] = useState([]);

  useEffect(() => {
    storeLocation.getStoreLocationsList().then((locationsList) => {
      const sortedLocations = locationsList.sort((a, b) => a?.key?.localeCompare(b?.key));
      setLocations(sortedLocations);
    });
    attributionUtils.fetchAgents({ setAgents, setLoading, setMessage, setLoadingSuccess });
  }, []);

  return (
    <DialogTemplate
      openBtnVariant="text"
      open={open}
      setOpen={setOpen}
      setMessage={setMessage}
      dialogTitle={copyText.Cart.CartTools.CartHistory.filters}
      openIcon={<Tune />}
      openText={copyText.Cart.CartTools.CartHistory.filters}
      message={message}
      precedingIcon={<Tune />}
    >
      <DialogContent sx={{ my: 3, display: 'flex', flexDirection: 'column' }}>
        <InputLabel id="draft-agent-select">Sales Agent Name</InputLabel>
        <Autocomplete
          id="draft-agent-select"
          name="customerEmail"
          disabled={loading || !loadingSuccess}
          value={selectedAgent}
          inputValue={inputValue || ''}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            CartHistoryFiltersUtils.handleSelectAgent({
              agent: newValue,
              setSelectedAgent,
              updateForm,
            });
          }}
          options={agents}
          getOptionLabel={(option) => option?.name || ''}
          renderInput={(params) => (
            <TextField
              variant="standard"
              name="customerEmail"
              placeholder="Agent name..."
              InputProps={{
                disableUnderline: true,
                ...params.InputProps,
                style: {
                  ...params.InputProps.style,
                  borderBottom: 'none',
                },
              }}
              sx={{ pt: 1 }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
            />
          )}
        />
        <InputLabel id="draft-store-select" sx={{ mt: 3 }}>
          {copyText.Cart.CartTools.CartHistory.storeSelect}
        </InputLabel>
        <Select
          sx={{ pt: 1 }}
          id="draft-store-select"
          variant="standard"
          disableUnderline
          name="store"
          value={formData.store}
          onChange={(e) => {
            CartHistoryFiltersUtils.handleSelectLocation({ e, updateForm });
          }}
        >
          <MenuItem value="all" name="store">
            {copyText.Cart.CartTools.CartHistory.allStores}
          </MenuItem>
          {locations?.map((location) => (
            <MenuItem name="store" value={location?.key} key={location?.key}>
              {location?.key}-{location?.name?.[lang]}
            </MenuItem>
          ))}
        </Select>
        <FormControlLabel
          sx={{ mt: 2 }}
          componentsProps={{ typography: { variant: 'p' } }}
          label={copyText.Cart.CartTools.CartHistory.draftOrderFilter}
          labelPlacement="end"
          control={
            <Checkbox
              value={formData?.draftsFilter}
              checked={formData?.draftsFilter}
              onChange={(e) => updateForm(e)}
              name="draftsFilter"
              size="small"
            />
          }
        />
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <Button
          variant="contained"
          onClick={() =>
            CartHistoryFiltersUtils.handleSubmit({
              submitSearchQuery,
              setOpen,
            })
          }
          color="primary"
        >
          {copyText.Cart.CartTools.CartHistory.search}
        </Button>
      </DialogActions>
    </DialogTemplate>
  );
};

CartHistoryFilters.propTypes = {
  formData: shape({
    draftsFilter: bool,
  }),
  updateForm: func.isRequired,
  submitSearchQuery: func.isRequired,
  selectedAgent: shape({}),
  setSelectedAgent: func.isRequired,
};

CartHistoryFilters.defaultProps = {
  formData: {
    draftsFilter: false,
  },
  selectedAgent: null,
};

export default CartHistoryFilters;
