import React, { useState, useEffect } from 'react';
import {
  TableCell,
  TableRow,
  TextField,
  IconButton,
  Collapse,
  List,
  ListSubheader,
  ListItem,
  Typography,
  Grid,
} from '@mui/material';
import { shape, string, number, arrayOf, func } from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { lang, copyText } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';
import returnUtils from './Utils/returnUtils';

const ReturnRow = (props) => {
  const {
    row,
    selectedOrder,
    quantityErrorId,
    setQuantityErrorId,
    returnItems,
    setReturnItems,
    setDisable,
  } = props;
  const returnInfo = selectedOrder.returnInfo;
  const [open, setOpen] = useState(false);
  const [itemReturnArray, setItemReturnArray] = useState();
  const [returnQuantity, setReturnQuantity] = useState(0);
  const [disableReturnQuantity, setDisableReturnQuantity] = useState(false);

  // This sets the error on the inputs so only the given sku turns red
  const showQuantityError = (id) => {
    return quantityErrorId === id;
  };

  useEffect(() => {
    returnUtils.checkForDiscountedDuplicateSku(
      row,
      selectedOrder,
      returnItems,
      setReturnItems,
      setDisableReturnQuantity,
    );
  }, [returnItems]);

  useEffect(() => {
    returnUtils.getItemReturnInfo(returnInfo, row.id, setItemReturnArray);
  }, []);

  useEffect(() => {
    if (disableReturnQuantity) {
      setReturnQuantity(0);
    }
  }, [disableReturnQuantity]);

  return (
    <>
      <TableRow key={row.id} value={row} sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{row.name[lang]}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>{row.variant.sku}</TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          {convertCentToDollar(row.taxedPrice.totalGross.centAmount)}
        </TableCell>
        <TableCell sx={{ textAlign: 'center' }}>
          {returnUtils.getQtyAvail(row, selectedOrder)}
        </TableCell>
        <TableCell align="center" sx={{ maxWidth: 50, alignContent: 'center' }}>
          {/* Quantity to Return */}
          <TextField
            id={`${row.id}-return-quantity`}
            data-testid="number-input"
            value={returnQuantity}
            InputProps={{
              min: 0,
              max: row.quantity,
            }}
            error={showQuantityError(row.variant.sku)}
            type="number"
            placeholder="0"
            disabled={disableReturnQuantity}
            sx={{ width: 100 }}
            onChange={(e) => {
              setReturnQuantity(e.target.value);
              returnUtils.handleInput({
                e,
                item: row,
                returnItems,
                setReturnItems,
                selectedOrder,
                setQuantityErrorId,
                setDisable,
              });
            }}
          />
        </TableCell>
      </TableRow>
      <TableRow sx={{ borderBottom: 2 }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List
              dense
              disablePadding
              subheader={
                <ListSubheader color="primary" sx={{ fontWeight: 'bold' }}>
                  {!itemReturnArray?.length
                    ? copyText.Orders.Returns.noPreviousReturns
                    : copyText.Orders.Returns.previousReturns}
                </ListSubheader>
              }
            >
              {!!itemReturnArray?.length &&
                itemReturnArray?.map((returnData) => {
                  return (
                    <ListItem key={returnData.id}>
                      <Grid container>
                        <Grid item>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.date}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {new Date(returnData.createdAt).toLocaleString()}
                          </Typography>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.quantity}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {returnData.quantity}
                          </Typography>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.refundAmount}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {returnData?.custom?.fields?.centAmount
                              ? `$ ${returnData.custom.fields.centAmount / 100}`
                              : copyText.Orders.Returns.notAvailable}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.shippingStatus}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {returnData.shipmentState || copyText.Orders.Returns.pending}
                          </Typography>
                          <Typography variant="list_item_bold_title" mr={1}>
                            {copyText.Orders.Returns.refundStatus}
                          </Typography>
                          <Typography variant="list_item" mr={2} color="secondary">
                            {returnData.paymentState || copyText.Orders.Returns.pending}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

ReturnRow.propTypes = {
  row: shape({
    id: string,
    quantity: number,
    variant: shape({ sku: string }),
    name: shape({ 'en-US': string }),
    taxedPrice: shape({ totalGross: shape({ centAmount: number }) }),
  }),
  selectedOrder: shape({
    returnInfo: arrayOf(
      shape({
        items: arrayOf(
          shape({
            id: string,
            lastModifiedAt: string,
            lineItemId: string,
            paymentState: string,
            shipmentState: string,
            quantity: number,
          }),
        ),
      }),
    ),
  }),
  quantityErrorId: string,
  setQuantityErrorId: func.isRequired,
  returnItems: arrayOf(shape({ sku: string })),
  setReturnItems: func.isRequired,
  setDisable: func.isRequired,
};

ReturnRow.defaultProps = {
  row: {},
  selectedOrder: {},
  quantityErrorId: '',
  returnItems: [],
};
export default ReturnRow;
