import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  DialogActions,
  DialogTitle,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useOktaAuth } from '@okta/okta-react';
import { bool } from 'prop-types';
import { copyText } from 'language';
import {
  useCart,
  useSetCart,
  useSetShowCheckout,
  useOpenCheckoutStepper,
  useSetOpenCheckoutStepper,
  useCheckoutStepperActiveStep,
  useSetCheckoutStepperActiveStep,
} from 'contexts';
import cloverUtils from 'utils/clover';
import shippingUtils from 'utils/shippingMethods';
import multiPayUtils from 'utils/multiPayUtils';
import checkoutStepperUtils from 'utils/checkoutStepperUtils';
import receiptUtils from 'components/ReceiptModal/utils';
import DeliveryMethods from 'components/DeliveryMethods/DeliveryMethods';
import CustomerInfo from 'components/DeliveryMethods/components/CustomerInfo';
import ReviewOrder from 'components/ReviewOrder/ReviewOrder';
import CloverWarning from 'components/CloverWarning/CloverWarning';
import MultiPayStep from 'components/OrderSummaryCard/MultiPayStep';
import OrderConfirmation from 'components/OrderSummaryCard/OrderConfirmation';
import TextLoader from 'components/TextLoader';
import PaymentResponseStep from 'components/PaymentResponseStep/PaymentResponseStep';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';

const CheckoutStepper = ({ checkRefund }) => {
  const history = useHistory();
  const cart = useCart();
  const setCart = useSetCart();
  const componentRef = useRef();
  const { oktaAuth } = useOktaAuth();
  const setShowCheckout = useSetShowCheckout();
  const openCheckoutStepper = useOpenCheckoutStepper();
  const setOpenCheckoutStepper = useSetOpenCheckoutStepper();
  const checkoutStepperActiveStep = useCheckoutStepperActiveStep();
  const setCheckoutStepperActiveStep = useSetCheckoutStepperActiveStep();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [disableNextButton, setDisableNextButton] = useState(false);
  const [disableUseStoreAddress, setDisableUseStoreAddress] = useState(false);
  const [selectedDeliveryMethods, setSelectedDeliveryMethods] = useState([]);
  const [showMissingCloverWarning, setShowMissingCloverWarning] = useState(false);
  const [status, setStatus] = useState('');
  const [newOrder, setNewOrder] = useState({});
  const [disableRepeat, setDisableRepeat] = useState(false);
  const [showOrderCreationFailure, setShowOrderCreationFailure] = useState(false);
  const [orderCreationError, setOrderCreationError] = useState(null);
  const [payments, setPayments] = useState([]);
  const [showCancelWarning, setShowCancelWarning] = useState(false);
  const [cancelButtonWasClicked, setCancelButtonWasClicked] = useState(false);
  const [printReceiptMessage, setPrintReceiptMessage] = useState('');
  const [openReceiptSnackbar, setOpenReceiptSnackbar] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState({});
  const [invalidFields, setInvalidFields] = useState([]);

  const [formData, setFormData] = useState({
    firstName: cart?.shippingAddress?.firstName || '',
    lastName: cart?.shippingAddress?.lastName || '',
    streetAddress: cart?.shippingAddress?.streetName || '',
    additionalStreetInfo: cart?.shippingAddress?.additionalStreetInfo || '',
    postalCode: cart?.shippingAddress?.postalCode || '',
    city: cart?.shippingAddress?.city || '',
    state: cart?.shippingAddress?.state || '',
    country: cart?.shippingAddress?.country || 'US',
    email: cart?.shippingAddress?.email || '',
    phone: cart?.shippingAddress?.phone || '',
  });

  const cloverValidation = () => {
    const cloverDevice = cloverUtils.getSessionCloverDevice();
    const cloverLoginRequired = cloverUtils.checkCloverLoginRequired();
    setDisableNextButton(cloverLoginRequired && cloverDevice === null);
    setShowMissingCloverWarning(cloverLoginRequired && !cloverDevice);
  };

  useEffect(() => {
    checkoutStepperUtils.checkShippingMethods({ cart, setDisableUseStoreAddress });
  }, [cart?.customLineItems]);

  useEffect(() => {
    checkoutStepperUtils.handleStepFunctions(
      checkoutStepperActiveStep,
      cart,
      cloverValidation,
      disableRepeat,
      formData,
      selectedDeliveryMethods,
      setDisableNextButton,
    );
    checkoutStepperUtils.handleRemoveEventListener(checkoutStepperActiveStep, cloverValidation);
  }, [formData, selectedDeliveryMethods, checkoutStepperActiveStep, disableRepeat]);

  useEffect(() => {
    setFormData({
      firstName: cart?.shippingAddress?.firstName || cart?.draft_first_name || '',
      lastName: cart?.shippingAddress?.lastName || '',
      streetAddress: cart?.shippingAddress?.streetName || '',
      additionalStreetInfo: cart?.shippingAddress?.additionalStreetInfo || '',
      postalCode: cart?.shippingAddress?.postalCode || '',
      city: cart?.shippingAddress?.city || '',
      state: cart?.shippingAddress?.state || '',
      country: cart?.shippingAddress?.country || 'US',
      email: cart?.shippingAddress?.email || cart?.customerEmail || '',
      phone: cart?.shippingAddress?.phone || '',
    });
  }, [cart]);

  const closeModal = () => {
    if (checkoutStepperActiveStep !== 4) {
      multiPayUtils.handleCancelWarningClose({
        cart,
        payments,
        setShowCancelWarning,
        setOpenCheckoutStepper,
        setCheckoutStepperActiveStep,
        setSelectedDeliveryMethods,
        setCancelButtonWasClicked,
      });
    } else if (checkoutStepperActiveStep === 4) {
      setOpenCheckoutStepper(false);
      setCheckoutStepperActiveStep(0);
      setSelectedDeliveryMethods([]);
      history.push('/shop');
    } else {
      setOpenCheckoutStepper(false);
      setCheckoutStepperActiveStep(0);
      setSelectedDeliveryMethods([]);
    }
  };

  const handleBack = () => {
    if (checkoutStepperActiveStep === 1) {
      setSelectedDeliveryMethods([]);
      setCheckoutStepperActiveStep((prev) => prev - 1);
    }
    if (checkoutStepperActiveStep === 2) {
      setCheckoutStepperActiveStep((prev) => prev - 1);
    }
    if (checkoutStepperActiveStep === 3) {
      multiPayUtils.handleCancelWarningBack({
        cart,
        payments,
        setShowCancelWarning,
        setCheckoutStepperActiveStep,
        setCancelButtonWasClicked,
      });
    }
  };

  const handleNext = async () => {
    if (checkoutStepperActiveStep === 0) {
      checkoutStepperUtils.updateCartWithShippingAddress({
        cart,
        formData,
        validateShippingAddress: true,
        setLoading,
        setCart,
        setShowErrorMessage,
        setCheckoutStepperActiveStep,
        setDisableNextButton,
        setInvalidFields,
      });
    }
    if (checkoutStepperActiveStep === 1) {
      setLoading(true);
      try {
        const updatedCart = await shippingUtils.setDeliveryMethods(
          selectedDeliveryMethods,
          cart.id,
        );
        setCart(updatedCart);
        setCheckoutStepperActiveStep((prev) => prev + 1);
        setSelectedDeliveryMethods([]);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    }
    if (checkoutStepperActiveStep === 2 && !cart?.is_exchange_order) {
      setCheckoutStepperActiveStep((prev) => prev + 1);
      setDisableNextButton(true);
    }
    if (checkoutStepperActiveStep === 2 && cart?.is_exchange_order) {
      checkoutStepperUtils.sendPaymentRequest(
        cart,
        checkRefund,
        false,
        oktaAuth,
        setCart,
        setCheckoutStepperActiveStep,
        setError,
        setLoading,
        setNewOrder,
        setPaymentResponse,
      );
    }
    if (checkoutStepperActiveStep === 3) {
      multiPayUtils.handleOrderCreation({
        setLoading,
        setStatus,
        cart,
        setCart,
        setNewOrder,
        setDisableRepeat,
        setShowCheckout,
        setCheckoutStepperActiveStep,
        oktaAuth,
        onError: (err) => {
          multiPayUtils.handleError({
            setShowOrderCreationFailure,
            setOrderCreationError,
            error: err,
          });
        },
      });
    }
    if (checkoutStepperActiveStep === 4) {
      closeModal();
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '{ padding: 5px }',
  });

  const getButtonText = () => {
    if (loading) {
      return <CircularProgress size={25} color="primary" />;
    }
    if (checkoutStepperActiveStep === 2 && cart?.is_exchange_order) {
      return copyText.Cart.CheckoutStepper.processExchange;
    }
    if (checkoutStepperActiveStep === 3) {
      return copyText.Cart.CheckoutStepper.placeOrder;
    }
    if (checkoutStepperActiveStep === 4) {
      return copyText.Cart.CheckoutStepper.close;
    }
    return copyText.Cart.CheckoutStepper.next;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={openCheckoutStepper}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle variant="h5">{copyText.Cart.CheckoutStepper.checkout}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Stepper
            sx={{ pt: 1, pb: 3, width: '100%', borderBottom: 'solid 1px lightGray' }}
            activeStep={checkoutStepperActiveStep}
          >
            <Step sx={{ pl: '0' }}>
              <StepLabel>{copyText.Cart.CheckoutStepper.customerInfo}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{copyText.Cart.CheckoutStepper.deliveryMethods}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{copyText.Cart.CheckoutStepper.reviewOrder}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{copyText.Cart.CheckoutStepper.paymentInfo}</StepLabel>
            </Step>
            <Step sx={{ pr: '0' }}>
              <StepLabel>{copyText.Cart.CheckoutStepper.orderConfirmation}</StepLabel>
            </Step>
          </Stepper>
          <Box sx={{ width: '100%' }}>
            {showMissingCloverWarning && checkoutStepperActiveStep === 2 && <CloverWarning />}
          </Box>
          <Box sx={{ width: '100%', mt: 3 }}>
            {/* -- Step 1 -- */}
            {checkoutStepperActiveStep === 0 && (
              <CustomerInfo
                formData={formData}
                setFormData={setFormData}
                showErrorMessage={showErrorMessage}
                setShowErrorMessage={setShowErrorMessage}
                loading={loading}
                setLoading={setLoading}
                disableUseStoreAddress={disableUseStoreAddress}
                disableNextButton={disableNextButton}
                setDisableNextButton={setDisableNextButton}
                handleNext={handleNext}
                setError={setError}
                invalidFields={invalidFields}
                setInvalidFields={setInvalidFields}
              />
            )}
            {/* -- Step 2 -- */}
            {checkoutStepperActiveStep === 1 && (
              <DeliveryMethods
                selectedDeliveryMethods={selectedDeliveryMethods}
                setSelectedDeliveryMethods={setSelectedDeliveryMethods}
              />
            )}
            {/* -- Step 3 -- */}
            {checkoutStepperActiveStep === 2 && <ReviewOrder />}
            {/* -- Step 4 - Is Exchange Order -- */}
            {checkoutStepperActiveStep === 3 && cart?.is_exchange_order && (
              <PaymentResponseStep response={paymentResponse} loading={loading} />
            )}
            {/* -- Step 4 - Is NOT Exchange Order -- */}
            {checkoutStepperActiveStep === 3 && !cart?.is_exchange_order && (
              <MultiPayStep
                status={status}
                setStatus={setStatus}
                setDisableRepeat={setDisableRepeat}
                showOrderCreationFailure={showOrderCreationFailure}
                setShowOrderCreationFailure={setShowOrderCreationFailure}
                orderCreationError={orderCreationError}
                setDisableNextButton={setDisableNextButton}
                payments={payments}
                setPayments={setPayments}
                showCancelWarning={showCancelWarning}
                setShowCancelWarning={setShowCancelWarning}
                cancelButtonWasClicked={cancelButtonWasClicked}
                setSelectedDeliveryMethods={setSelectedDeliveryMethods}
                setError={setError}
              />
            )}
            {/* -- Step 5 -- */}
            {checkoutStepperActiveStep === 4 && (
              <OrderConfirmation
                order={newOrder}
                componentRef={componentRef}
                printReceiptMessage={printReceiptMessage}
                setPrintReceiptMessage={setPrintReceiptMessage}
                openReceiptSnackbar={openReceiptSnackbar}
                setOpenReceiptSnackbar={setOpenReceiptSnackbar}
                showHeader
              />
            )}
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {checkoutStepperActiveStep === 2 && cart?.is_exchange_order && (
          <Typography color="error" sx={{ fontSize: '14px', mb: 2, ml: 1 }}>
            {copyText.Cart.CheckoutStepper.exchangeWarning}
          </Typography>
        )}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          {checkoutStepperActiveStep === 4 ? (
            <Button
              variant="outlined"
              sx={{ display: 'block', displayPrint: 'none', py: 1 }}
              onClick={() =>
                receiptUtils.printThermalReceipt({
                  setLoading,
                  setMessage: setPrintReceiptMessage,
                  setOpenSnackbar: setOpenReceiptSnackbar,
                  order: newOrder,
                })
              }
            >
              <TextLoader
                loading={loading}
                text={copyText.Orders.Actions.printThermalReceipt}
                size={20}
              />
            </Button>
          ) : (
            <Button variant="outlined" color="primary" onClick={closeModal}>
              {copyText.Cart.CheckoutStepper.cancel}
            </Button>
          )}
          <Box sx={{ display: 'flex' }}>
            {checkoutStepperActiveStep !== 0 && checkoutStepperActiveStep !== 4 && (
              <Button
                variant="contained"
                color="primary"
                disabled={loading || (checkoutStepperActiveStep === 3 && cart?.is_exchange_order)}
                onClick={handleBack}
                data-testid="checkout-stepper-back-button"
                sx={{ mr: 4, px: 4, py: 1 }}
              >
                {copyText.Cart.CheckoutStepper.back}
              </Button>
            )}
            {checkoutStepperActiveStep === 4 && (
              <Button
                variant="contained"
                sx={{ display: 'block', displayPrint: 'none', mr: 4, px: 4, py: 1 }}
                onClick={handlePrint}
              >
                {copyText.App.print}
              </Button>
            )}
            <Button
              variant="outlined"
              color="primary"
              sx={{ px: 4, py: 1 }}
              onClick={handleNext}
              disabled={
                disableNextButton || (checkoutStepperActiveStep === 3 && cart?.is_exchange_order)
              }
            >
              {getButtonText()}
            </Button>
          </Box>
        </Box>
      </DialogActions>
      <SnackbarMessage message={error} setMessage={setError} />
    </Dialog>
  );
};

export default CheckoutStepper;

CheckoutStepper.propTypes = {
  checkRefund: bool,
};

CheckoutStepper.defaultProps = {
  checkRefund: false,
};
