import { useEffect, useState } from 'react';
import { Autocomplete, TextField, Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { string, shape, func } from 'prop-types';

import { useCart, useSetMessage } from 'contexts';
import { copyText } from 'language';

import attributionUtils from './attributionUtils';

const AgentAttribution = ({ selectedAgent, setSelectedAgent }) => {
  const [agents, setAgents] = useState([]);
  const cart = useCart();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state
  const [loadingSuccess, setLoadingSuccess] = useState(true);
  const [editMode, setEditMode] = useState(false); // Add edit mode state
  const setMessage = useSetMessage();

  useEffect(() => {
    attributionUtils.fetchAgents({ setAgents, setLoading, setMessage, setLoadingSuccess });
  }, []);

  useEffect(() => {
    const salesAssociateId = attributionUtils.getCurrentSalesAgent({ cart });
    if (salesAssociateId) {
      const agent = agents.find((a) => a.email === salesAssociateId);
      const salesAssociate = salesAssociateId
        ? { email: salesAssociateId, name: salesAssociateId }
        : null;
      setSelectedAgent(agent ?? salesAssociate);
      setInputValue(salesAssociateId ?? null);
    }
  }, [cart, agents]);

  if (loading) {
    return (
      <>
        <Typography variant="subtitle2" fontWeight={700}>
          {copyText.Cart.CartTools.salesAgent}:
        </Typography>
        <Box>{copyText.App.loading}</Box>
      </>
    );
  }
  if (!loadingSuccess) {
    return (
      <>
        <Typography variant="subtitle2" fontWeight={700}>
          {copyText.Cart.CartTools.salesAgent}:
        </Typography>
        <Box display="flex" alignItems="center">
          {copyText.App.errorOccurred}
          <Button
            onClick={() =>
              attributionUtils.fetchAgents({
                setAgents,
                setLoading,
                setMessage,
                setLoadingSuccess,
              })
            }
          >
            {copyText.App.retry}
          </Button>
        </Box>
      </>
    );
  }
  return (
    <>
      <Typography variant="subtitle2" fontWeight={700}>
        {copyText.Cart.CartTools.salesAgent}:
      </Typography>
      {editMode ? (
        <Autocomplete
          disabled={loading || !loadingSuccess || !cart}
          size="small"
          value={selectedAgent}
          onChange={(event, newValue) => {
            attributionUtils.handleUpdate({
              cart,
              newValue,
              agents,
              setSelectedAgent,
              setEditMode,
              setMessage,
            });
          }}
          onBlur={() => setEditMode(false)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={agents}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField size="small" {...params} />
          )}
        />
      ) : (
        <Box>
          <Box display="flex" alignItems="center">
            <Typography>
              {selectedAgent ? selectedAgent.email : copyText.Cart.CartTools.noAgentSelected}
            </Typography>
            <Button onClick={() => setEditMode(true)}>{copyText.App.edit}</Button>
          </Box>
          {!selectedAgent && (
            <Typography
              variant="subtitle"
              sx={{ fontWeight: 'semi-bold', fontSize: 12 }}
              color="error"
            >
              {copyText.Cart.CartTools.selectSalesAgent}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default AgentAttribution;

AgentAttribution.propTypes = {
  selectedAgent: shape({
    email: string,
    name: string,
  }),
  setSelectedAgent: func.isRequired,
};
AgentAttribution.defaultProps = {
  selectedAgent: null,
};
