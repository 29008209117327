import React, { useState, useEffect } from 'react';
import {
  useOrderInput,
  useFoundOrderData,
  useCurrentPage,
  useSetCurrentPage,
  useSelectedStore,
  useSetSelectedStore,
  useSetOrderInput,
  useSetSelectedOrder,
  useSetOrderPayment,
  useFilters,
  useSetFilters,
  useSetFoundOrderData,
} from 'contexts/orders';
import { useLocation } from 'react-router-dom';
import SearchLayout from 'components/layouts/SearchLayout';
import storeLocation from 'utils/storeLocation';
import { copyText, lang } from 'language';
import OrderFilterModal from './OrderFilterModal';
import UseOrderSearchFunctions from './hooks/OrderSearchFunctions';
import orderSearchUtils from './utils/SearchUtils';

const Orders = () => {
  const [orderTableData, setOrderTableData] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [showfilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search');
  const currentPage = useCurrentPage();
  const setCurrentPage = useSetCurrentPage();
  const foundOrderData = useFoundOrderData();
  const setFoundOrderData = useSetFoundOrderData();
  const orderInput = useOrderInput();
  const setOrderInput = useSetOrderInput();
  const selectedStore = useSelectedStore();
  const setSelectedStore = useSetSelectedStore();
  const setSelectedOrder = useSetSelectedOrder();
  const setOrderPayment = useSetOrderPayment();
  const filters = useFilters();
  const setFilters = useSetFilters();

  const { useTodaysStoreOrders, useSearchUserInput, useFormatOrderTableData } =
    UseOrderSearchFunctions();

  const orderColumns = {
    idColumn: copyText.Orders.OrderList.orderNumber,
    columns: [
      {
        title: copyText.Orders.OrderList.purchaseDate,
      },
      {
        title: copyText.Orders.OrderList.orderType,
      },
      {
        title: copyText.Orders.OrderList.grandTotal,
        tooltip: copyText.Orders.OrderList.grandTotalTooltip,
      },
      {
        title: copyText.Orders.OrderList.customerName,
      },
      {
        title: copyText.Orders.OrderList.customerEmail,
      },
      {
        title: copyText.Orders.OrderList.location,
      },
    ],
  };

  const openFilterModal = () => {
    setShowFilterModal(true);
  };

  const closeFilterModal = () => {
    setShowFilterModal(false);
  };

  const handleListItemClick = (row) => {
    setOrderPayment(null);
    setSelectedOrder(row.data);
  };
  const performInitialLoad = () => {
    if (searchQuery && foundOrderData.count === 0) {
      setOrderInput(searchQuery);
      useSearchUserInput(searchQuery);
    }
    if (!orderInput && foundOrderData.count === 0) {
      useTodaysStoreOrders();
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    orderSearchUtils.handleSearch({
      orderInput,
      filters,
      setFilters,
      setCurrentPage,
      setFoundOrderData,
      setLoading,
    });
  };

  const populateTableData = async () => {
    const tableData = await useFormatOrderTableData(foundOrderData.results);
    setOrderTableData(tableData);
  };

  const populateStoreList = async () => {
    const storesList = await storeLocation.getStoreLocationsList();
    setLocationList(storesList);
  };

  useEffect(() => {
    populateTableData();
  }, [foundOrderData.results]);

  useEffect(() => {
    setSelectedStore(storeLocation.getLocationStorage()?.name[lang]);
    performInitialLoad();
  }, []);

  useEffect(() => {
    populateStoreList();
    // cleanup
    return () => {};
  }, [showfilterModal]);

  return (
    <SearchLayout
      searchLabel={copyText.Orders.Orders.orderSearch}
      searchFunction={handleSearch}
      searchInput={orderInput}
      handleInput={(event) =>
        orderSearchUtils.handleInput({
          event,
          setOrderInput,
          filters,
          setFilters,
          setFoundOrderData,
          setLoading,
        })
      }
      loading={loading}
      setLoading={setLoading}
      columns={orderColumns.columns}
      idColumn={orderColumns.idColumn}
      rowData={orderTableData}
      numRows={foundOrderData.total}
      handleListItemClick={handleListItemClick}
      populateLegend={orderSearchUtils.populateLegend}
      handlePageChange={orderSearchUtils.handlePageChange}
      currentPage={currentPage - 1} // Table pagination offset
      setCurrentPage={setCurrentPage}
      setFilterToOpen={openFilterModal}
      filters={filters}
      setFilters={setFilters}
      setFoundOrderData={setFoundOrderData}
      FilterComponent={OrderFilterModal({
        modalOpenStatus: showfilterModal,
        selectedStore,
        setSelectedStore,
        open: showfilterModal,
        closeFilterModal,
        setOpen: setShowFilterModal,
        locationList,
        orderInput,
        setOrderInput,
        setLoading,
        setFilters,
      })}
      showLegend
    />
  );
};

export default Orders;
