// SUNRISE: Uncomment after logging lambda is ready
// import errorApi from 'dataAccess/api/errorHandling.ts';

const checkIsJsonString = (data) => {
  try {
    JSON.parse(data);
  } catch (error) {
    return false;
  }
  return true;
};

const getErrorsFromStorage = () => {
  const data = localStorage.getItem('errorData');
  if (!data) {
    return [];
  }
  if (data && checkIsJsonString(data)) {
    return JSON.parse(data);
  }
  return ['ERRORS NOT PARSABLE'];
};

const logErrorToLocalStorage = (error) => {
  let message = 'no message provided';
  let source = 'no source provided';
  let method = 'no method provided';
  let pathName = 'no pathName provided';
  let errorInfo = 'no errorInfo provided';
  let errorRaw = 'no error provided';

  if (error) {
    message = error.message || message;
    source = error.source || source;
    method = error.method || method;
    pathName = error.pathName || pathName;
    errorInfo = error.errorInfo ? JSON.stringify(error.errorInfo) : errorInfo;
    errorRaw = JSON.stringify(error);
  }

  // Maximum entries allowed for errorData storage
  const maxEntries = 200;
  const errorStorage = getErrorsFromStorage();

  if (errorStorage.length > maxEntries) {
    errorStorage.pop();
  }

  const timestamp = new Date().toISOString();

  const newEntry = `| ${timestamp} | ${method} | ${source} | ${pathName} | ${message} | ${errorInfo} | ${errorRaw} |`;
  const errorData = [newEntry, ...errorStorage];

  localStorage.setItem('errorData', JSON.stringify(errorData));
};

// SUNSET: Deprecate 'src' in favor of 'method'
const logError = async (errorRaw) => {
  // SUNSET: Remove next line after logging lambda is ready
  logErrorToLocalStorage(errorRaw);

  // SUNRISE: Uncomment after logging lambda is ready
  // try {
  //   await errorApi.logErrorToAPI(errorInfo);
  //   return true;
  // } catch (err) {
  //   logErrorToLocalStorage({ message, source, method: src || method }, errorInfo);
  //   return false;
  // }
};

export { checkIsJsonString, logErrorToLocalStorage, getErrorsFromStorage, logError };
