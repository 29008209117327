import copyText from 'language/enUS';

const fulfillmentStatusOptions = [
  // sunset: determine if these other statuses are needed when updating w/ agent and date
  // {
  //   key: 'initial',
  //   label: copyText.Orders.OrderData.fulfillmentStatuses.initial,
  // },
  {
    key: 'awaiting',
    label: copyText.Orders.OrderData.fulfillmentStatuses.awaiting,
  },
  // {
  //   key: 'processing',
  //   label: copyText.Orders.OrderData.fulfillmentStatuses.processing,
  // },
  {
    key: 'fulfilled',
    label: copyText.Orders.OrderData.fulfillmentStatuses.fulfilled,
  },
];

export default fulfillmentStatusOptions;
