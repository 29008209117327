import styled from 'styled-components';
import { backgroundColors } from 'utils/themeColors';

export default styled.div`
  background: linear-gradient(
    to bottom,
    ${backgroundColors.lightPurple60},
    ${backgroundColors.magicHourPink}
  );
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  text-align: center;
  display: flex;
  flex-direction: row;
  text-align: center;
  align-content: space-around;
  flex-wrap: wrap;
  margin-top: 0;
`;
