import React, { useEffect, useState } from 'react';
import { Alert, Box, Grid } from '@mui/material';
import { useCart } from 'contexts';
import clover from 'utils/clover';
import { checkForDuplicateLineItemSkus } from 'utils/cart';
import constants from 'utils/constants';
import { copyText } from 'language';
import CartCard from 'components/CartCard/CartCard';

import CartTitle from './components/CartTitle';
import EmptyCart from './components/EmptyCart';
import ExchangeItems from './components/ExchangeItems';

const LeftColumn = () => {
  const [cloverStatus, setCloverStatus] = useState(null);
  const cart = useCart();

  const checkCloverStatus = () => {
    const accessToken = clover.getCloverAccessToken();
    const device = clover.getSessionCloverDevice();
    const loginRequired = clover.checkCloverLoginRequired();
    if (!loginRequired) {
      setCloverStatus(null);
    } else if (!accessToken) {
      setCloverStatus(copyText.Cart.CartTools.missingCloverToken);
    } else if (!device || device === 'select') {
      setCloverStatus(copyText.Cart.CartTools.missingCloverDevice);
    } else {
      setCloverStatus(null);
    }
  };

  useEffect(() => {
    checkCloverStatus();
    window.addEventListener('storage', checkCloverStatus);
    return () => window.removeEventListener('storage', checkCloverStatus);
  }, []);

  return (
    <Grid item xs={16} sm={9} md={10}>
      <CartTitle />
      {cloverStatus && <Alert severity="warning">{cloverStatus}</Alert>}
      <Box
        sx={{
          position: 'relative',
          overflow: 'scroll',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          maxHeight: '100vh',
          mb: 4,
        }}
      >
        {cart?.lineItems?.length > 0 ? (
          cart.lineItems.map((lineItem, index) => {
            return (
              <CartCard
                type={constants.CART_CARD_CART}
                key={lineItem.id}
                productData={lineItem}
                showEdit
                showShippingMethod={checkForDuplicateLineItemSkus(cart?.lineItems, lineItem, index)}
              />
            );
          })
        ) : (
          <EmptyCart />
        )}
      </Box>
      {cart?.is_exchange_order && <ExchangeItems orderId={cart?.original_order_id} />}
    </Grid>
  );
};

export default LeftColumn;
