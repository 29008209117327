const checkForWF = (paymentArray) => {
  const result = paymentArray?.some(
    (payment) =>
      payment?.obj?.paymentMethodInfo?.paymentInterface === 'CHARGE_AFTER' &&
      payment.obj.interfaceInteractions?.some(
        (interaction) => interaction?.fields?.transaction_tag === 'Wells Fargo',
      ),
  );
  return result;
};

const hasBeenTwoDays = (givenDate) => {
  const given = new Date(givenDate).getTime();
  const now = Date.now();
  const diffDays = (now - given) / (1000 * 60 * 60 * 24);
  return diffDays >= 2;
};

// This function checks to see if it has been two days since the Wells Fargo order was created and returns false if it has not.
const validateWellsFargo = (orderInfo) => {
  if (checkForWF(orderInfo?.paymentInfo?.payments)) {
    if (!hasBeenTwoDays(orderInfo.createdAt)) {
      return false;
    }
  }
  return true;
};

export default { validateWellsFargo };
