import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useCart, useSortedStores, useSetStores } from 'contexts';
import { copyText } from 'language';
import { string, func, arrayOf, shape, number, bool } from 'prop-types';
import { Close } from '@mui/icons-material';
import {
  populateAvailableShippingMethods,
  getStores,
  displayAdditionalOptions,
  updateDeliveryMethod,
  handleUpdateStore,
  handleUpdateMattressRemoval,
} from '../utils/DeliveryMethodSelectUtils';

const DeliveryMethodSelect = ({
  sku,
  selectedDeliveryMethods,
  setSelectedDeliveryMethods,
  removeMattress,
  setRemoveMattress,
  distributionChannel,
}) => {
  const [availableMethods, setAvailableMethods] = useState([]);
  const [selectedDelivery, setSelectedDelivery] = useState('');
  const [storeKey, setStoreKey] = useState('');
  const [error, setError] = useState(null);
  const [showMsg, setShowMsg] = useState(false);
  const [loading, setLoading] = useState(true);
  const cart = useCart();
  const sortedStores = useSortedStores();
  const setStores = useSetStores();

  const handleSnackbarClose = () => {
    setShowMsg(false);
  };

  const action = (
    <IconButton
      disabled={loading}
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleSnackbarClose}
    >
      <Close />
    </IconButton>
  );

  useEffect(() => {
    populateAvailableShippingMethods({
      cart,
      sku,
      distributionChannel,
      setLoading,
      setAvailableMethods,
      setError,
      setShowMsg,
    });
    setLoading(false);
  }, [cart?.lineItems]);

  useEffect(() => {
    getStores(setLoading, setStores);
  }, []);

  return (
    <Grid container display="flex" flexDirection="row" justifyContent="space-between" columns={16}>
      <Grid item xs={16} sm={displayAdditionalOptions(selectedDelivery)}>
        <FormControl
          data-testid="form-control-shippingMethod"
          fullWidth
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <InputLabel id="select-delivery-method">
            {copyText.Cart.CheckoutStepper.selectDeliveryMethod}
          </InputLabel>
          <Select
            id="select-delivery-method"
            fullWidth
            label={copyText.Cart.CheckoutStepper.selectDeliveryMethod}
            value={selectedDelivery}
            disabled={loading}
            endAdornment={loading ? <CircularProgress size={25} color="primary" /> : null}
          >
            {availableMethods &&
              availableMethods.map((option) => {
                return (
                  <MenuItem
                    key={option.name}
                    value={option.name}
                    onClick={() =>
                      updateDeliveryMethod({
                        deliveryMethod: option,
                        cart,
                        sku,
                        selectedDeliveryMethods,
                        setLoading,
                        setSelectedDeliveryMethods,
                        setSelectedDelivery,
                        setStoreKey,
                        setError,
                        setShowMsg,
                      })
                    }
                  >
                    {option.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
      {selectedDelivery === 'Pick Up' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="pickup-from-shipping">{copyText.Cart.CartTools.pickupFrom}</InputLabel>
            <Select
              id="pickup-from-shipping"
              fullWidth
              label={copyText.Cart.CartTools.pickupFrom}
              value={storeKey}
              IconComponent={() => null}
              disabled={loading}
            >
              {sortedStores.map((store) => {
                return (
                  <MenuItem
                    key={store.key}
                    value={store.key}
                    onClick={() =>
                      handleUpdateStore({
                        sKey: store.key,
                        sku,
                        selectedDeliveryMethods,
                        setLoading,
                        setStoreKey,
                        setSelectedDeliveryMethods,
                        setError,
                        setShowMsg,
                      })
                    }
                  >
                    {store.name['en-US']}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      )}
      {selectedDelivery === 'In-Home Setup' && (
        <Grid item xs={15} sm={7}>
          <FormControl fullWidth>
            <InputLabel id="select-remove-mattress">
              {copyText.Cart.CartTools.removeMattress}
            </InputLabel>
            <Select
              id="select-remove-mattress"
              fullWidth
              label={copyText.Cart.CartTools.removeMattress}
              value={removeMattress}
              onChange={(e) =>
                handleUpdateMattressRemoval({
                  value: e.target.value,
                  selectedDeliveryMethods,
                  sku,
                  setLoading,
                  setRemoveMattress,
                  setSelectedDeliveryMethods,
                  setError,
                  setShowMsg,
                })
              }
              IconComponent={() => null}
              disabled={loading}
            >
              <MenuItem value={1}>{copyText.App.yes}</MenuItem>
              <MenuItem value={0}>{copyText.App.no}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Snackbar open={showMsg} onClose={handleSnackbarClose} message={error} action={action} />
    </Grid>
  );
};

DeliveryMethodSelect.propTypes = {
  sku: string,
  selectedDeliveryMethods: arrayOf(
    shape({
      cost: number,
      key: string,
      name: string,
      needsRemoval: bool,
      storeKey: string,
      skus: arrayOf(string),
    }),
  ),
  setSelectedDeliveryMethods: func,
  removeMattress: number,
  setRemoveMattress: func,
  distributionChannel: string,
};

DeliveryMethodSelect.defaultProps = {
  distributionChannel: '',
  sku: '',
  selectedDeliveryMethods: [],
  setSelectedDeliveryMethods: () => {},
  removeMattress: 0,
  setRemoveMattress: () => {},
};

export default DeliveryMethodSelect;
