import React, { useEffect, useState } from 'react';
import {
  Typography,
  Checkbox,
  Box,
  CardMedia,
  Grid,
  Card,
  IconButton,
  Snackbar,
  Chip,
} from '@mui/material';
import { useCart, useSetCart } from 'contexts';
import { copyText, lang } from 'language';
import cartService from 'dataAccess/api/cart.ts';
import QuantitySelect from 'components/CartCard/components/QuantitySelect';
import convertCentToDollar from 'utils/convertCentToDollar';
import exchangeUtils from 'utils/exchangeUtils';
import { Close } from '@mui/icons-material';
import { bool, func, shape, string, arrayOf, number } from 'prop-types';

const ExchangeItem = ({ lineItem, lineItems, loading, setLoading }) => {
  const cart = useCart();
  const setCart = useSetCart();

  const [error, setError] = useState('');
  const [checked, setChecked] = useState(false);
  const [price, setPrice] = useState('');
  const [itemQuantity, setItemQuantity] = useState(0);
  const [description, setDescription] = useState('');
  const [disableQty, setDisableQty] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [isFinalSale, setIsFinalSale] = useState(false);
  const [isFloorModel, setIsFloorModel] = useState(false);
  const [disableExchangeItem, setDisableExchangeItem] = useState(false);
  const getDescription = (variant) => {
    const desc = variant?.attributes.find((attribute) => attribute.name === 'title');
    setDescription(desc?.value || '');
  };

  const populateCustomFields = () => {
    const isFinalSaleItem = lineItem?.custom?.fields?.final_sale;
    setIsFinalSale(isFinalSaleItem);
    const isFloorModelItem = lineItem?.custom?.fields?.floor_model;
    setIsFloorModel(isFloorModelItem);
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => setOpenSnackbar(false)}
    >
      <Close />
    </IconButton>
  );
  const buildLineItem = (item, qty) => {
    let exchangeValue = {};
    // If the quantity is equal to the remaining amount provide the remaining cent amount on the line item.
    if (item.numAvailable === qty) {
      exchangeValue = {
        ...item.taxedPrice?.totalGross,
        centAmount: item.availableCentAmount,
      };
    }
    // If quantity is less then the available amount split the total gross amount.
    else {
      exchangeValue = {
        ...item.taxedPrice?.totalGross,
        centAmount: Math.ceil(
          ((item.taxedPrice?.totalGross?.centAmount ?? 0) / (item.quantity ?? 1)) * qty,
        ),
      };
    }
    return {
      id: item.id,
      sku: item.variant?.sku,
      exchangeValue,
      name: item?.name[lang] || '',
      quantity: qty,
      storeLocation: cart?.store?.key || '',
      custom: {
        ...item.custom,
      },
    };
  };

  const updateLineItems = (item, exchangeItems) => {
    if (exchangeItems.some((exchangeItem) => exchangeItem.id === item.id)) {
      return exchangeItems.filter((exchangeItem) => exchangeItem.id !== item.id);
    }
    return [...exchangeItems, buildLineItem(item, itemQuantity)];
  };

  const parseExchangeItems = (updatedCart) => {
    const cartExchangeItems = updatedCart?.exchange_order_line_items;
    if (cartExchangeItems) {
      return JSON.parse(cartExchangeItems);
    }
    return [];
  };

  const inExchangeList = (updatedCart) => {
    const cartJSON = parseExchangeItems(updatedCart);
    const foundItem = cartJSON.some((cartItem) => cartItem.id === lineItem.id);
    setDisableQty(!foundItem);
    return foundItem;
  };

  const updateCartExchangeItems = async (item) => {
    try {
      setLoading(true);
      const updatedLineItems = updateLineItems(item, parseExchangeItems(cart));
      const updatedCart = await cartService.updateExchangeItems(cart.id, updatedLineItems);
      setCart(updatedCart);
      setChecked(inExchangeList(updatedCart));
    } catch (err) {
      setError(err.message);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const updateQuantity = async (qty) => {
    setLoading(true);
    try {
      const exchangeLineItems = parseExchangeItems(cart);

      const updatedItems = exchangeLineItems.map((item) => {
        const updatedLLineItem = buildLineItem(lineItem, qty);
        return item.id === lineItem.id ? { ...updatedLLineItem, quantity: qty } : item;
      });

      const updatedCart = await cartService.updateExchangeItems(cart.id, updatedItems);
      setCart(updatedCart);
      setItemQuantity(qty || 0);
    } catch (err) {
      setError(err.message);
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setItemQuantity(lineItem.numAvailable);
    populateCustomFields();
  }, []);

  useEffect(() => {
    getDescription(lineItem.variant);
    setPrice(convertCentToDollar(lineItem.price?.value?.centAmount ?? 0));
    setChecked(inExchangeList(cart));
  }, [cart.exchange_order_line_items, lineItem.variant]);

  useEffect(() => {
    exchangeUtils.checkForDiscountedDuplicateSkuInExchange(
      lineItem,
      lineItems,
      setDisableExchangeItem,
      parseExchangeItems(cart),
      updateCartExchangeItems,
    );
  }, [cart]);

  return (
    <Card sx={{ mt: 1, mb: 1, pt: 1 }} elevation={0}>
      <Grid container columns={16} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={16} sm={5} md={5} display="flex" alignContent="center">
          <CardMedia
            component="img"
            sx={{
              pr: 2,
              objectPosition: 'center' /* Center the image within the element */,
              height: '100%',
              width: '100%',
            }}
            src={lineItem.variant?.images[0]?.url}
            alt=""
          />
        </Grid>
        <Grid item sm={11} md={10}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              component="p"
              sx={{ fontWeight: 600 }}
              color={lineItem.numAvailable ? 'primary' : 'grayUtility'}
            >
              {lineItem.name[lang]}
            </Typography>
            <Checkbox
              checked={checked}
              disabled={loading || lineItem.numAvailable === 0 || disableExchangeItem}
              onClick={() => updateCartExchangeItems(lineItem)}
            />
          </Box>

          {lineItem.numAvailable === 0 && (
            <Chip
              size="small"
              variant="warning"
              label={copyText.Cart.CartTools.unavailableForExchange}
            />
          )}
          {isFinalSale && (
            <Chip
              sx={{ ml: lineItem.numAvailable === 0 ? 1 : 0 }}
              label={copyText.Cart.CartTools.finalSale}
              color="error"
              size="small"
            />
          )}
          {isFloorModel && (
            <Chip
              sx={{ ml: 1 }}
              label={copyText.Cart.CartTools.floorModel}
              color="error"
              size="small"
            />
          )}

          <Typography variant="subtitle2">{lineItem.variant?.sku}</Typography>
          <Typography variant="subtitle2">{description}</Typography>
          <Typography mb={2} variant="subtitle2" fontWeight="semi-bold" marginRight={1}>
            {price}
          </Typography>
          {checked && (
            <QuantitySelect
              numItem={itemQuantity}
              max={lineItem.numAvailable}
              updateQuantity={updateQuantity}
              disabled={disableQty || loading}
            />
          )}
        </Grid>
        <Snackbar
          sx={{ color: 'primary' }}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          message={error}
          action={action}
        />
      </Grid>
    </Card>
  );
};

ExchangeItem.propTypes = {
  lineItem: shape({}).isRequired,
  lineItems: arrayOf(
    shape({
      id: string,
      numAvailable: number,
    }),
  ).isRequired,
  loading: bool.isRequired,
  setLoading: func.isRequired,
};

export default ExchangeItem;
