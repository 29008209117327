import React from 'react';
import { Grid, Typography } from '@mui/material';
import * as PropTypes from 'prop-types';
import { copyText } from 'language';

const Page404 = ({ error }) => {
  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item mt={15} sm={12}>
        <Typography variant="h1" textAlign="center">
          {error} {copyText.Page404.notFound}
        </Typography>
        <Typography variant="h3" component="p" textAlign="center">
          {copyText.Page404.somethingWrong}
        </Typography>
      </Grid>
    </Grid>
  );
};

Page404.propTypes = {
  error: PropTypes.string,
};

Page404.defaultProps = {
  error: copyText.Page404.default,
};

export default Page404;
