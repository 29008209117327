import React, { useState } from 'react';
import { Route, useHistory, Switch, Redirect } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { bool, func, string, shape } from 'prop-types';
import { useIdleTimer } from 'react-idle-timer';
import { StoreContextProvider } from 'contexts';

// Import theme functions
import themeUtils from 'theme';
import cloverUtils from 'utils/clover';
import { time } from 'utils/time';

// --- Views ---
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Login from 'views/Login/Login';
import Page404 from 'views/Page404/Page404';
import CartHistory from 'views/CartHistory/CartHistory';
import CloverAccounts from 'views/CloverAccounts/CloverAccounts';
import RetailShop from 'views/Shop/RetailShop';
import OutletShop from 'views/Shop/OutletShop';
import Orders from 'views/Orders';
import Cart from 'views/Cart/Cart';
import ErrorLogs from 'views/ErrorLogs/ErrorLogs';
import Troubleshooting from 'views/Troubleshooting/Troubleshooting';
import Signage from 'public/Signage/Signage';

// --- Okta Configuration ---
import oktaConfig from 'utils/oktaConfig';

const oktaAuth = new OktaAuth(oktaConfig);

// Paths where you don't want to show header/footer
const disallowedPaths = ['/', '/pin', '/signage'];
const showPageTheme = (pathname) => {
  return !disallowedPaths.includes(pathname);
};

// --- Header Component ---
const HeaderContainer = ({ location, darkMode, setDarkMode }) => {
  const { pathname } = location;
  return showPageTheme(pathname) && <Header darkMode={darkMode} setDarkMode={setDarkMode} />;
};

HeaderContainer.propTypes = {
  location: shape({ pathname: string }).isRequired,
  darkMode: bool.isRequired,
  setDarkMode: func.isRequired,
};

// --- Footer Component ---
const FooterContainer = ({ location }) => {
  const { pathname } = location;
  return showPageTheme(pathname) && <Footer />;
};

FooterContainer.propTypes = {
  location: shape({ pathname: string }).isRequired,
};

const App = () => {
  const history = useHistory();

  // Retrieve darkMode state from sessionStorage or fallback to default false
  const initialDarkMode = sessionStorage.getItem('darkMode') === 'true';

  const [darkMode, setDarkMode] = useState(initialDarkMode);

  // Idle timeout settings
  const timeout = time.hours(5);
  const onIdle = () => {
    alert('Session timed out');
    cloverUtils.clearCloverStorage();
    oktaAuth.signOut();
  };

  useIdleTimer({ onIdle, timeout });

  // Okta restore original URI handler
  const restoreOriginalUri = async () => {
    history.replace(toRelativeUrl('/shop/retail', window.location.origin));
  };

  const theme = themeUtils.getTheme(darkMode);

  // Update dark mode state and sessionStorage

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <StoreContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <HeaderContainer
            location={window.location}
            setDarkMode={setDarkMode}
            darkMode={darkMode}
          />
          <article role="main">
            <Switch>
              {/* --- Public Pages --- */}
              <Route path="/signage" component={Signage} />

              {/* --- START Routes --- */}
              <Route exact path="/" component={Login} />
              <Route exact path="/shop">
                <Redirect to="/shop/retail" />
              </Route>
              <SecureRoute path="/shop/retail" component={RetailShop} />
              <SecureRoute path="/shop/outlet" component={OutletShop} />
              <SecureRoute path="/orders" component={Orders} />
              <SecureRoute path="/cart" component={Cart} />
              <SecureRoute path="/cart-history" component={CartHistory} />
              <SecureRoute path="/employees" component={CloverAccounts} />
              <Route exact path="/login-okta" component={LoginCallback} />
              <SecureRoute path="/error-logs" component={ErrorLogs} />
              <SecureRoute path="/troubleshooting" component={Troubleshooting} />
              {/* --- END Routes --- */}

              {/* 404 Page */}
              <SecureRoute path="*" component={Page404} />
            </Switch>
            <FooterContainer location={window.location} />
          </article>
        </ThemeProvider>
      </StoreContextProvider>
    </Security>
  );
};

export default App;
