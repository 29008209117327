import { useEffect, useState } from 'react';
import returnsAndExchanges from 'utils/returnsAndExchanges';
import toDollar from 'utils/toDollar';

const getPreTaxTotal = (selectedOrder) => {
  const [preTax, setPreTax] = useState(0);
  useEffect(() => {
    if (selectedOrder.orderNumber) {
      setPreTax(toDollar(selectedOrder.totalPrice.centAmount));
    }
  }, [selectedOrder.id]);
  return preTax;
};

const getGiftCardPayments = (orderPayment) => {
  const giftCardAmounts = (orderPayment || []).map((ele) => {
    if (ele.paymentMethodInfo.method === 'GIFT_CARD') {
      return ele.transactions.reduce((acc, transaction) => {
        if (transaction.type === 'Charge' && transaction.state === 'Success') {
          return acc + transaction.amount.centAmount;
        }
        return acc;
      }, 0);
    }
    return 0; // Return 0 if payment method is not 'GIFT_CARD'
  });

  // Sum up the gift card amounts
  const totalGiftCardAmount = giftCardAmounts.reduce((acc, amount) => acc + amount, 0);

  return totalGiftCardAmount;
};

const getTaxedTotal = (selectedOrder, orderPayment) => {
  const [taxedTotal, setTaxedTotal] = useState(0);
  useEffect(() => {
    if (selectedOrder.orderNumber) {
      const total =
        (selectedOrder?.taxedPrice?.totalGross?.centAmount ?? 0) -
        returnsAndExchanges.getExchangeCreditTotal(selectedOrder) -
        getGiftCardPayments(orderPayment);
      if (total <= 0) {
        setTaxedTotal(toDollar(0));
      } else {
        setTaxedTotal(toDollar(total));
      }
    }
  }, [selectedOrder.id, orderPayment]);
  return taxedTotal;
};

const getShipping = (selectedOrder) => {
  const [shipping, setShipping] = useState(0);
  const getShippingAmount = () => {
    if (selectedOrder.orderNumber) {
      return selectedOrder.customLineItems.reduce((prev, curr) => {
        const amount1 = prev?.money?.centAmount ? prev.money.centAmount : 0;
        const amount2 = curr?.money?.centAmount ? curr.money.centAmount : 0;
        return amount1 + amount2;
      }, 0);
    }
    return 0;
  };

  useEffect(() => {
    setShipping(toDollar(getShippingAmount()));
  }, [selectedOrder.id]);
  return shipping;
};

const getTax = (selectedOrder) => {
  const [tax, setTax] = useState(0);
  const calculateTax = (order) => {
    if (order.orderNumber) {
      setTax(
        toDollar(
          order.taxedPrice.taxPortions.reduce((prev, curr) => prev + curr.amount.centAmount, 0),
        ),
      );
    }
  };
  useEffect(() => {
    calculateTax(selectedOrder);
  }, [selectedOrder.id]);
  return tax;
};

export default {
  getPreTaxTotal,
  getTaxedTotal,
  getShipping,
  getTax,
  getGiftCardPayments,
};
