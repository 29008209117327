// These utils are to assist with the functionality of the cart total component.

import cartDiscounts from 'dataAccess/api/cart.discounts.ts';

const addToRejectedPromos = async ({
  promo,
  rejectedPromos,
  setCart,
  setMessage,
  cartId,
  setLoading,
}) => {
  try {
    setLoading(true);
    const updatedCart = await cartDiscounts.markDiscountOrPromoRejected({
      cartId,
      rejectedPromos: [...rejectedPromos, promo],
    });

    setCart(updatedCart);
    setMessage('Promo code removed');
  } catch (err) {
    setMessage('Error removing promo code. Please try again.');
  } finally {
    setLoading(false);
  }
};

const removeFromRejectedPromos = async ({
  cartId,
  setMessage,
  promo,
  setCart,
  setLoading,
  rejectedPromos,
}) => {
  try {
    setLoading(true);
    const updatedRejectedPromos = rejectedPromos?.filter(
      (rejectedPromo) => rejectedPromo?.id !== promo?.id,
    );
    const updatedCart = await cartDiscounts.markDiscountOrPromoRejected({
      cartId,
      rejectedPromos: updatedRejectedPromos,
    });
    setCart(updatedCart);
    setMessage('Promo code restored');
  } catch (err) {
    setMessage('An error occurred in restoring promo code. Please try again.');
  } finally {
    setLoading(false);
  }
};

const checkRejectedPromos = (promo, rejectedPromos) => {
  return rejectedPromos.some((rejectedPromo) => rejectedPromo.id === promo.id);
};

export default { addToRejectedPromos, removeFromRejectedPromos, checkRejectedPromos };
