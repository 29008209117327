import { useEffect } from 'react';
import { Switch, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { bool, func } from 'prop-types';

const DarkModeToggle = ({ darkMode, setDarkMode }) => {
  // Handle switch toggle
  const handleChange = (e) => {
    e.stopPropagation();
    const isChecked = !darkMode;

    // Store the value in sessionStorage
    sessionStorage.setItem('darkMode', isChecked);
    setDarkMode(isChecked); // Update the local state
  };

  // Initialize the dark mode state from sessionStorage
  useEffect(() => {
    const storedDarkMode = sessionStorage.getItem('darkMode') === 'true'; // Convert to boolean
    setDarkMode(storedDarkMode);
  }, []); // Only run on initial render

  return (
    <MenuItem onClick={handleChange}>
      <ListItemIcon>
        <Switch
          sx={{ m: -1 }}
          size="small"
          checked={darkMode} // Controlled component with darkMode state
        />
      </ListItemIcon>
      <ListItemText primary="Dark Mode" />
    </MenuItem>
  );
};

DarkModeToggle.propTypes = {
  darkMode: bool.isRequired,
  setDarkMode: func.isRequired,
};

export default DarkModeToggle;
