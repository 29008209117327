import React from 'react';
import { Box, Divider, Grid, List, ListItem, Typography, Paper } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { copyText } from 'language';
import { useCart } from 'contexts';
import ClearCart from 'components/ClearCart/ClearCart';

const CustomerInfoBox = () => {
  const cart = useCart();
  const lineHeight = 1;

  return (
    <Paper elevation={0}>
      <Grid marginBottom={4} container display="flex" flexDirection="column">
        <Grid item display="flex" paddingTop={3} alignItems="center" justifyContent="space-between">
          <Typography paddingLeft={2} alignItems="center" variant="h5" component="h2">
            {copyText.Cart.CartTools.customerInfo}
          </Typography>
        </Grid>
        <List sx={{ pt: 0 }}>
          {!cart?.shippingAddress && cart?.customerEmail && (
            <List>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="p" component="strong" fontSize={14} sx={{ lineHeight }}>
                  {copyText.Cart.CartTools.customerName}
                </Typography>
                <Typography fontSize={14} sx={{ lineHeight }}>
                  {`${cart?.draft_first_name}`}
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="p" component="strong" fontSize={14} sx={{ lineHeight }}>
                  {copyText.Cart.CartTools.customerEmail}
                </Typography>
                <Typography fontSize={14} sx={{ lineHeight }}>
                  {`${cart?.customerEmail}` || copyText.App.missing}
                </Typography>
              </ListItem>
            </List>
          )}
          {cart?.shippingAddress ? (
            <List>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="p" component="strong" fontSize={14} sx={{ lineHeight }}>
                  {copyText.Cart.CartTools.customerName}
                </Typography>
                <Typography fontSize={14} sx={{ lineHeight }}>
                  {`${cart?.shippingAddress.firstName} ${cart?.shippingAddress.lastName}`}
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="p" component="strong" fontSize={14} sx={{ lineHeight }}>
                  {copyText.Cart.CartTools.streetAddressOnly}
                </Typography>
                <Typography fontSize={14} sx={{ lineHeight }}>
                  {cart?.shippingAddress.streetName}
                </Typography>
              </ListItem>
              {cart?.shippingAddress?.additionalStreetInfo && (
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography fontSize={14} sx={{ lineHeight }} variant="p" component="strong">
                    {copyText.Cart.CartTools.additionalStreetInfo}
                  </Typography>
                  <Typography fontSize={14} sx={{ lineHeight }}>
                    {cart?.shippingAddress.additionalStreetInfo}
                  </Typography>
                </ListItem>
              )}
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography fontSize={14} sx={{ lineHeight }} variant="p" component="strong">
                  {copyText.Cart.CartTools.cityStateZip}
                </Typography>
                <Typography fontSize={14} sx={{ lineHeight }}>
                  {`${cart?.shippingAddress.city}, ${cart?.shippingAddress.state} ${cart?.shippingAddress.postalCode}`}
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography fontSize={14} sx={{ lineHeight }} variant="p" component="strong">
                  {copyText.Cart.CartTools.email}
                </Typography>
                <Typography fontSize={14} sx={{ lineHeight }}>
                  {cart?.customerEmail}
                </Typography>
              </ListItem>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography fontSize={14} sx={{ lineHeight }} variant="p" component="strong">
                  {copyText.Cart.CartTools.phone}
                </Typography>
                <Typography fontSize={14} sx={{ lineHeight }}>
                  {cart?.shippingAddress.phone}
                </Typography>
              </ListItem>
            </List>
          ) : (
            <Box sx={{ pl: 2, pt: 1, display: 'flex' }}>
              <HighlightOffIcon color="error" sx={{ mr: 1 }} />
              <Typography>{copyText.Cart.CartTools.noShippingInfoAdded}</Typography>
            </Box>
          )}
        </List>
        <Divider sx={{ mt: 1, mx: 2 }} />
        <ClearCart />
      </Grid>
    </Paper>
  );
};

export default CustomerInfoBox;
