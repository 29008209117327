import React, { useState, useRef } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { useReactToPrint } from 'react-to-print';
import copyText from 'language/enUS';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import TextLoader from 'components/TextLoader';
import OrderConfirmation from 'components/OrderSummaryCard/OrderConfirmation';
import utils from './utils';

const ReceiptModal = ({ open, order, closeModal, loadOrder, isReprint }) => {
  const componentRef = useRef();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: '{ padding: 5px }',
  });

  return (
    <Dialog scroll="body" open={open} fullWidth>
      <DialogContent
        sx={{
          textAlign: 'center',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <OrderConfirmation
          order={order}
          printReceiptMessage={message}
          setPrintReceiptMessage={setMessage}
          openReceiptSnackbar={openSnackbar}
          setOpenReceiptSnackbar={setOpenSnackbar}
          loadOrder={loadOrder}
          isReprint={isReprint}
          componentRef={componentRef}
        />
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex">
          <Button
            sx={{ display: 'block', displayPrint: 'none' }}
            onClick={() =>
              utils.printThermalReceipt({ setLoading, setMessage, setOpenSnackbar, order })
            }
          >
            <TextLoader
              loading={loading}
              text={copyText.Orders.Actions.printThermalReceipt}
              size={20}
            />
          </Button>
        </Box>
        <Box display="flex">
          <Button
            variant="outlined"
            sx={{ display: 'block', displayPrint: 'none', mr: 2 }}
            onClick={closeModal}
          >
            {copyText.App.close}
          </Button>
          <Button
            variant="contained"
            sx={{ display: 'block', displayPrint: 'none' }}
            onClick={handlePrint}
          >
            {copyText.App.print}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ReceiptModal.propTypes = {
  open: bool.isRequired,
  order: shape({
    customLineItems: arrayOf(shape({})).isRequired,
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
      totalNet: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    lineItems: arrayOf(shape({})).isRequired,
    orderNumber: string.isRequired,
    createdAt: string.isRequired,
  }).isRequired,
  closeModal: func.isRequired,
  loadOrder: func.isRequired,
  isReprint: bool,
};

ReceiptModal.defaultProps = {
  isReprint: false,
};

export default ReceiptModal;
