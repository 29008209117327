import { Box, Typography, List, ListItem, Divider, IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { arrayOf, number, shape, string, bool, func } from 'prop-types';
import copyText from 'language/enUS';
import { lang } from 'language';
import convertCentToDollar from 'utils/convertCentToDollar';
import { useOktaAuth } from '@okta/okta-react';
import logo from 'media/images/purple-logo.svg';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import PickListItem from 'components/OrderSummaryCard/PickListItem';
import ExchangeItems from 'components/OrderSummaryCard/ExchangeItems';
import utils from 'components/ReceiptModal/utils';

const OrderConfirmation = ({
  order,
  printReceiptMessage,
  setPrintReceiptMessage,
  openReceiptSnackbar,
  setOpenReceiptSnackbar,
  componentRef,
  showHeader,
  loadOrder,
  isReprint,
}) => {
  const [address, setAddress] = useState('');
  const [storeName, setStoreName] = useState('');
  const [shippingTotals, setShippingTotals] = useState('');
  const [taxTotals, setTaxTotals] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [refundAmount, setRefundAmount] = useState(null);
  const [promoCodeDiscounts, setPromoCodeDiscounts] = useState([]);
  const [recyclingFeeTotal, setRecyclingFeeTotal] = useState(null);
  const [exchangeAmount, setExchangeAmount] = useState(0);
  const [date, setDate] = useState('');
  const [agentName, setAgentName] = useState('');
  const [payments, setPayments] = useState([]);
  const { oktaAuth } = useOktaAuth();

  const receiptStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    mt: 2,
    p: 0,
  };

  const checkIsFinalSale = (lineItem) => {
    return lineItem?.custom?.fields?.final_sale ?? false;
  };

  const checkIsFloorModel = (lineItem) => {
    return lineItem?.custom?.fields?.floor_model ?? false;
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() =>
        utils.handleSnackbarClose({
          setOpenSnackbar: setOpenReceiptSnackbar,
          setMessage: setPrintReceiptMessage,
        })
      }
    >
      <CloseIcon />
    </IconButton>
  );

  useEffect(() => {
    utils.populateStoreData({ order, setAddress, setStoreName });
    utils.getPromoCodeDiscounts({ order, setPromoCodeDiscounts });
    setShippingTotals(convertCentToDollar(financialCalculators.getShippingTotals(order)));
    setTaxTotals(convertCentToDollar(financialCalculators.getTax(order)));
    setTotalPrice(convertCentToDollar(financialCalculators.getTotalPrice(order)));
    utils.getAgent({ oktaAuth, setAgentName });
    setRecyclingFeeTotal(convertCentToDollar(financialCalculators.getRecyclingFee(order)));
    utils.populateTime({ setDate, order });
    utils.getPayments({ order, setPayments });
    setExchangeAmount(utils.calculateExchangeAmount({ order }));
  }, [order]);

  useEffect(() => {
    utils.populateTotalPrice({ order, setTotalPrice, setRefundAmount });
  }, [exchangeAmount]);

  return (
    <Box ref={componentRef}>
      {showHeader && (
        <Typography variant="h5" sx={{ displayPrint: 'none', mb: 2 }}>
          {copyText.Cart.PaymentResponse.orderCreated}!
        </Typography>
      )}
      <Box display="none" displayPrint="block">
        <img src={logo} alt={copyText.Header.logo.alt} style={{ height: '3rem' }} />
      </Box>
      <Typography variant="h6">{storeName ?? ''}</Typography>
      <Typography>{address?.streetName ?? ''}</Typography>
      <Typography>{address?.streetNumber ?? ''}</Typography>
      <Typography>
        {`${address?.city ?? ''},
                ${address?.state ?? ''}`}
      </Typography>
      <Typography marginTop={4} variant="h6">
        {copyText.App.orderNumber} {order?.orderNumber}
      </Typography>
      <List>
        {(order.is_exchange_order || order?.custom?.fields?.is_exchange_order) && (
          <>
            <Divider sx={{ mt: 2 }} />
            <ExchangeItems order={order} loadOrder={loadOrder} />
          </>
        )}
        <ListItem sx={receiptStyles}>
          <Typography variant="p" component="strong" align="left">
            {copyText.Cart.receipt.purchaseItems}
          </Typography>
          <Typography variant="p" component="strong" align="right">
            {copyText.App.price}
          </Typography>
        </ListItem>
        <Divider />

        {order?.lineItems?.map((lineItem) => {
          return (
            <ListItem key={lineItem?.id} sx={receiptStyles}>
              <Box>
                <Typography variant="p" component="strong" align="left">
                  {lineItem?.distributionChannel?.obj?.key ?? ''} {lineItem?.name[lang]}
                </Typography>
                {checkIsFinalSale(lineItem) && (
                  <Typography
                    variant="p"
                    sx={{ ml: 1 }}
                    component="strong"
                    color="error"
                    align="left"
                  >
                    {copyText.Cart.CartTools.finalSale}
                  </Typography>
                )}
                {checkIsFloorModel(lineItem) && (
                  <Typography
                    variant="p"
                    sx={{ ml: 1 }}
                    component="strong"
                    color="error"
                    align="left"
                  >
                    {copyText.Cart.CartTools.floorModel}
                  </Typography>
                )}
                {utils.getShippingMethod({ lineItem, order }) === 'Pick Up' ? (
                  <PickListItem lineItem={lineItem} order={order} />
                ) : (
                  <>
                    <Typography align="left" display="flex">
                      {lineItem?.name[lang]} x {lineItem?.quantity}
                    </Typography>
                    <Typography display="flex" align="left">
                      {utils.getShippingMethod({ lineItem, order })}
                    </Typography>
                  </>
                )}
                <Typography variant="p">
                  {copyText.Cart.receipt.sku}: {lineItem?.variant?.sku}
                </Typography>
              </Box>
              <Typography align="right">{utils.getLineItemPrice(lineItem)}</Typography>
            </ListItem>
          );
        })}
        <Divider sx={{ mt: 2 }} />
        {/* --- Gift Cards --- */}
        {order?.giftCards?.map((giftCard) => {
          return (
            <ListItem sx={receiptStyles} key={giftCard?.giftCardNumber}>
              <Typography variant="p" align="left">
                {copyText.Cart.receipt.giftCardXX}
                {utils.getGiftCardNumber(giftCard)}
              </Typography>
              <Typography align="right">-{utils.getGiftCardAmount(giftCard)}</Typography>
            </ListItem>
          );
        })}

        {/* --- Discount Codes --- */}
        {promoCodeDiscounts?.map((discountCode) => {
          return (
            <ListItem key={discountCode?.id} sx={receiptStyles}>
              <Box>
                <Typography variant="p" component="strong">
                  {discountCode?.method}
                </Typography>
                <Typography>{discountCode?.displayName}</Typography>
                <Typography>{discountCode?.couponCode}</Typography>
              </Box>
              <Typography>-{discountCode?.centAmount}</Typography>
            </ListItem>
          );
        })}

        {payments?.map((payment) => {
          return (
            <ListItem sx={receiptStyles} key={payment.externalPaymentId}>
              <Typography variant="p" align="left">
                {payment.paymentType} {copyText.Cart.receipt.endingIn}
                {payment.last4}
              </Typography>
              <Typography variant="p" align="right">
                {convertCentToDollar(payment.amount)}
              </Typography>
            </ListItem>
          );
        })}
        <ListItem sx={receiptStyles}>
          <Typography align="left">{copyText.Cart.CartTools.shipping}</Typography>
          <Typography align="right">{shippingTotals}</Typography>
        </ListItem>
        {recyclingFeeTotal && (
          <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
            <Typography>{copyText.Cart.CartTools.recyclingFee}</Typography>
            <Typography align="right">{recyclingFeeTotal}</Typography>
          </ListItem>
        )}
        <ListItem sx={receiptStyles}>
          <Typography align="left">{copyText.Cart.CartTools.tax}</Typography>
          <Typography align="right">{taxTotals}</Typography>
        </ListItem>
        {(order?.custom?.fields?.is_exchange_order || order.is_exchange_order) && (
          <>
            <ListItem sx={receiptStyles}>
              <Typography align="left">{copyText.Cart.receipt.subTotal}</Typography>
              <Typography align="right"> {utils.calculateSubtotal(order)}</Typography>
            </ListItem>
            <ListItem sx={receiptStyles}>
              <Typography align="left">{copyText.Cart.receipt.exchangeCredit}</Typography>
              <Typography align="right">- {convertCentToDollar(exchangeAmount)}</Typography>
            </ListItem>
          </>
        )}
        <Divider sx={{ mt: 2 }} />
        <ListItem sx={receiptStyles}>
          <Typography variant="p" component="strong" align="left">
            {copyText.Cart.receipt.totalCaps}
          </Typography>
          <Typography variant="p" component="strong" align="right">
            {totalPrice}
          </Typography>
        </ListItem>
        {refundAmount && (
          <>
            <Divider sx={{ mt: 2 }} />
            <ListItem sx={receiptStyles}>
              <Typography variant="p" component="strong" align="left">
                {copyText.Cart.receipt.refundCaps}
              </Typography>
              <Typography variant="p" component="strong" align="right">
                {`(${refundAmount})`}
              </Typography>
            </ListItem>
          </>
        )}
        {!order?.custom?.fields?.is_exchange_order && !order.is_exchange_order && (
          <ExchangeItems order={order} loadOrder={loadOrder} />
        )}
        <Divider sx={{ mt: 2 }} />
        <ListItem sx={receiptStyles}>
          <Typography variant="p" component="strong" align="right">
            {copyText.Cart.receipt.soldTo}
          </Typography>
        </ListItem>
        <ListItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
            marginTop: 2,
            padding: 0,
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography variant="p" component="strong" fontSize={20}>
              {`${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`}
            </Typography>
            <Typography fontSize={14}>{order?.shippingAddress?.streetName}</Typography>
            <Typography fontSize={14}>{order?.shippingAddress?.additionalStreetInfo}</Typography>
            <Typography variant="p" fontSize={14}>
              {`${order?.shippingAddress?.city}, ${order?.shippingAddress?.state} ${order?.shippingAddress?.postalCode}`}
            </Typography>
          </Box>
          <Box
            marginTop={4}
            marginBottom={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography variant="p" component="strong">
              {copyText.App.date}: {date}
            </Typography>
            {!isReprint && (
              <Typography variant="p" component="strong" data-testid="receipt_agent_name">
                {copyText.Cart.receipt.soldBy}: {agentName}
              </Typography>
            )}
          </Box>
        </ListItem>
        <Divider />
        <ListItem
          sx={{
            display: 'flex',
            flexDirection: 'column',
            margin: 0,
            marginTop: 2,
            padding: 0,
          }}
        >
          <Box
            marginTop={2}
            marginBottom={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Typography>{copyText.App.returnWarrantyPolicy}</Typography>
            <Typography>{copyText.App.pleaseVisitUrl}</Typography>
            <Typography>{copyText.App.returnsURL}</Typography>
          </Box>
          <Divider />

          <Typography variant="p" component="strong">
            {copyText.App.customerSupportCenter}
          </Typography>
          <Typography variant="p" component="strong">
            {copyText.App.customerSupportPhone}
          </Typography>
        </ListItem>
      </List>
      <Snackbar
        sx={{ color: 'primary' }}
        open={openReceiptSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenReceiptSnackbar(false)}
        message={printReceiptMessage}
        action={action}
      />
    </Box>
  );
};

OrderConfirmation.propTypes = {
  order: shape({
    customLineItems: arrayOf(shape({})),
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number,
      }),
      totalNet: shape({
        centAmount: number,
      }),
    }),
    lineItems: arrayOf(shape({})),
    orderNumber: string,
    createdAt: string,
  }),
  printReceiptMessage: string.isRequired,
  setPrintReceiptMessage: func.isRequired,
  openReceiptSnackbar: bool.isRequired,
  setOpenReceiptSnackbar: func.isRequired,
  componentRef: shape({}),
  showHeader: bool,
  loadOrder: func,
  isReprint: bool,
};

OrderConfirmation.defaultProps = {
  order: {},
  componentRef: {},
  showHeader: false,
  loadOrder: () => {},
  isReprint: false,
};

export default OrderConfirmation;
