// Function that determines whether an 's' should be added to the
// end of product count stipulations (ie cart items)
const pluralizeProducts = (numItems) => {
  if (numItems > 1 || numItems === 0) {
    return true;
  }
  return false;
};

export default pluralizeProducts;
