import wellsFargoUtils from 'utils/wellsFargoUtils';

const RETURNS = 'Return';

const getLineItemRefundAmount = (lineItemId, orderInfo) => {
  if (orderInfo?.returnInfo?.length > 0) {
    const returnInfo = orderInfo.returnInfo;
    const returnItems = returnInfo.map((returnObj) => returnObj.items);
    const returnItemsFlat = returnItems.flat();
    const refundedAmount = returnItemsFlat.reduce((acc, returnItem) => {
      if (returnItem.lineItemId === lineItemId && returnItem?.custom?.fields?.centAmount) {
        return acc + returnItem.custom.fields.centAmount;
      }
      return acc;
    }, 0);
    return refundedAmount;
  }
  return 0;
};

const getQtyAvail = (lineItemInfo, orderInfo) => {
  if (!wellsFargoUtils.validateWellsFargo(orderInfo)) {
    return 0;
  }
  const returnInfo = orderInfo.returnInfo;
  const returnItems = returnInfo.map((returnObj) => returnObj.items);
  const returnItemsFlat = returnItems.flat();
  const qtyAvailable = returnItemsFlat.reduce((acc, returnItem) => {
    if (returnItem.lineItemId === lineItemInfo.id) {
      return acc - returnItem.quantity;
    }
    return acc;
  }, lineItemInfo.quantity);
  return qtyAvailable;
};

const getItemReturnInfo = (returnInfo, lineItemId, setReturnItemArray) => {
  const returnItems = returnInfo.map((returnObj) => returnObj.items);
  const returnItemsFlat = returnItems.flat();
  const itemReturnInfo = returnItemsFlat.filter(
    (returnItem) =>
      returnItem.lineItemId === lineItemId && returnItem?.custom?.fields?.type === RETURNS,
  );
  setReturnItemArray(itemReturnInfo);
  return itemReturnInfo;
};

const validateButton = (items, setDisable) => {
  const numItems = items.reduce((acc, curr) => {
    return acc + curr.quantity;
  }, 0);
  setDisable(numItems === 0);
};

const removeFromReturnItems = (id, returnItems, setReturnItems) => {
  const items = returnItems.filter((item) => item.lineItemId !== id);
  setReturnItems(items);
  return items;
};

const addToTotalRefund = (returnObj, returnItems, setReturnItems) => {
  const items = returnItems.slice(); // Create a copy of the array
  const existingItem = items.find((returnItem) => returnItem.lineItemId === returnObj.lineItemId);

  if (existingItem) {
    // Update the existing item in the new array
    const index = items.indexOf(existingItem);
    items[index] = returnObj;
  } else {
    // Push the new item to the new array
    items.push(returnObj);
  }

  setReturnItems(items);
  return items;
};

const handleInput = ({
  e,
  item,
  returnItems,
  setReturnItems,
  selectedOrder,
  setQuantityErrorId,
  setDisable,
}) => {
  const { sku } = item.variant;
  let items = [...returnItems];
  const availableQuantity = getQtyAvail(item, selectedOrder);
  const inputValue = e.target.value;

  if (inputValue === '' || inputValue === '0') {
    // When value is empty or 0, remove item from returnItems array.
    setQuantityErrorId('');
    items = removeFromReturnItems(item.id, returnItems, setReturnItems);
  } else if (inputValue <= availableQuantity && inputValue >= 0) {
    // Validate that value is not larger than quantity on order and also greater than or equal to 0.
    setQuantityErrorId('');
    const itemObj = { sku, lineItemId: item.id, quantity: inputValue };
    items = addToTotalRefund(itemObj, returnItems, setReturnItems);
  } else {
    // If value is not correct, report an error and reset the wrong value.
    setQuantityErrorId(item.id);
    e.target.value = '';
  }
  validateButton(items, setDisable);
};

const checkForDiscountedDuplicateSku = (
  row,
  selectedOrder,
  returnItems,
  setReturnItems,
  setDisableReturnQuantity,
) => {
  const isItemReturned = selectedOrder?.returnInfo?.find((returnItem) =>
    returnItem?.items?.find(
      (item) => item?.lineItemId === row?.id && item?.quantity === row?.quantity,
    ),
  );
  if (isItemReturned) {
    return setDisableReturnQuantity(true);
  }
  const duplicateLineItem = selectedOrder?.lineItems?.find(
    (lineItem) =>
      lineItem?.variant?.sku === row?.variant?.sku &&
      lineItem?.id !== row?.id &&
      row?.taxedPrice?.totalGross?.centAmount > lineItem?.taxedPrice?.totalGross?.centAmount,
  );
  const isDuplicateLineItemReturned = selectedOrder?.returnInfo?.find((returnItem) =>
    returnItem?.items?.find((item) => item?.lineItemId === duplicateLineItem?.id),
  );
  if (isDuplicateLineItemReturned) {
    return setDisableReturnQuantity(false);
  }
  const isDuplicateLineItemInReturnItems = returnItems?.find(
    (returnItem) => returnItem?.lineItemId === duplicateLineItem?.id,
  );
  if (isDuplicateLineItemInReturnItems) {
    return setDisableReturnQuantity(false);
  }
  const isRowInReturnItems = returnItems?.find((returnItem) => returnItem?.lineItemId === row?.id);
  if (duplicateLineItem && !isDuplicateLineItemInReturnItems && isRowInReturnItems) {
    removeFromReturnItems(row.id, returnItems, setReturnItems);
  }
  if (duplicateLineItem) {
    return setDisableReturnQuantity(true);
  }
  return setDisableReturnQuantity(false);
};

export default {
  getLineItemRefundAmount,
  getQtyAvail,
  getItemReturnInfo,
  handleInput,
  checkForDiscountedDuplicateSku,
};
