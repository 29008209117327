import * as React from 'react';
import { Container } from '@mui/material';
import { element, oneOfType, arrayOf } from 'prop-types';

const FullWidth = ({ children }) => {
  return <Container sx={{ mt: 12, mb: 14 }}>{children}</Container>;
};

FullWidth.propTypes = {
  children: oneOfType([element, arrayOf(element)]),
};

FullWidth.defaultProps = {
  children: null,
};

export default FullWidth;
