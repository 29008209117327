import toDollar from './toDollar';

const validateDiscountAmount = (lineItem, value) => {
  if (value <= 0) {
    return false;
  }
  const dollarAmount = toDollar(lineItem.price.value.centAmount);
  return dollarAmount >= value;
};

export default validateDiscountAmount;
