import financialCalculators from 'utils/financialCalculators/financialCalculators';

const checkCartPendingPayments = ({ setShowPendingPaymentWarning, cart, openCheckoutStepper }) => {
  setShowPendingPaymentWarning(false);
  if (cart) {
    const pendingPayments = financialCalculators.parsePendingPayments(cart);
    if (pendingPayments.length > 0 && !openCheckoutStepper) {
      setShowPendingPaymentWarning(true);
    } else {
      setShowPendingPaymentWarning(false);
    }
  }
};

export default {
  checkCartPendingPayments,
};
