import { Grid } from '@mui/material';
import React, { useState } from 'react';
import CustomerInfoBox from 'components/CustomerInfoBox/CustomerInfoBox';
import CartFinancials from './components/CartFinancials/CartFinancials';
import CheckoutStepper from './components/CheckoutStepper/CheckoutStepper';

const RightColumn = () => {
  const [checkRefund, setCheckRefund] = useState(false);

  return (
    <Grid item xs={16} sm={7} md={6} sx={{ mt: 4 }}>
      <CustomerInfoBox />
      <CartFinancials checkRefund={checkRefund} setCheckRefund={setCheckRefund} />
      <CheckoutStepper />
    </Grid>
  );
};

export default RightColumn;
