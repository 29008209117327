import axios from 'dataAccess/middleware/axiosInstance';
import { logError } from 'utils/errorUtils';
import getAccessToken from 'utils/getAccessToken';

/**
 * getPriceBySku - Gets the price of an item based on the sku
 * @param sku the CT product sku
 * @returns the price for a product given the sku
 * @link https://onpurple.github.io/carts-ms/#/paths/~1{sku}~1catalog-promo-price/get
 */
const getPriceBySku = async (sku: string) => {
  const hasRequiredParams = !!sku;
  const oktaToken = getAccessToken();

  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${sku}/catalog-promo-price`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const response = await axios.get(uri, config);
      return response;
    } catch (error) {
      logError({
        method: 'getPriceBySku',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.products',
      });
    }
  }

  throw new Error('Get price by sku requires: sku');
};

/**
 * getProductVariants - Gets the varients for a given product
 * @param productId the CT product id
 * @returns an array of variants
 * @link https://onpurple.github.io/carts-ms/#/paths/~1product~1{productId}~1variants/get
 */
const getProductVariants = async (productId: string) => {
  const hasRequiredParams = !!productId;
  const oktaToken = getAccessToken();
  if (hasRequiredParams) {
    try {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/product/${productId}/variants`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const response = await axios.get(uri, config);
      return response.data;
    } catch (error) {
      logError({
        method: 'getProductVariants',
        errorInfo: error,
        message: error?.message,
        source: 'api/cart.products',
      });
    }
  }

  throw new Error('Get product variants requires: productId');
};

export default {
  getPriceBySku,
  getProductVariants,
};
