import React from 'react';
import { Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { copyText } from 'language';
import { string } from 'prop-types';

const CustomerDetails = (props) => {
  const { name, phone, email, address } = props;
  return (
    <Grid container key="CustomerDetails" direction="column">
      <Grid item>
        <Typography variant="subHeader" component="h1">
          {copyText.Orders.CustomerDetails.customerDetails}
        </Typography>
      </Grid>
      <Grid item>
        <List sx={{ pt: 0, pb: 0 }}>
          <ListItem sx={{ pt: 0, pb: 0 }} disableGutters>
            <ListItemText
              primary={<Typography>{copyText.Orders.CustomerDetails.customerName}</Typography>}
              secondary={<Typography sx={{ fontWeight: 'bold' }}>{name}</Typography>}
            />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0 }} disableGutters>
            <ListItemText
              primary={<Typography>{copyText.Orders.CustomerDetails.phone}</Typography>}
              secondary={<Typography sx={{ fontWeight: 'bold' }}>{phone}</Typography>}
            />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0 }} disableGutters>
            <ListItemText
              primary={<Typography>{copyText.Orders.CustomerDetails.customerEmail}</Typography>}
              secondary={<Typography sx={{ fontWeight: 'bold' }}>{email}</Typography>}
            />
          </ListItem>
          <ListItem sx={{ pt: 0, pb: 0 }} disableGutters>
            <ListItemText
              primary={<Typography>{copyText.Orders.CustomerDetails.shippingAddress}</Typography>}
              secondary={<Typography sx={{ fontWeight: 'bold' }}>{address}</Typography>}
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default CustomerDetails;

CustomerDetails.propTypes = {
  name: string,
  phone: string,
  email: string,
  address: string,
};

CustomerDetails.defaultProps = {
  name: copyText.Orders.CustomerDetails.notAvailable,
  phone: copyText.Orders.CustomerDetails.notAvailable,
  email: copyText.Orders.CustomerDetails.notAvailable,
  address: copyText.Orders.CustomerDetails.notAvailable,
};
