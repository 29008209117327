import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Box, ListItemIcon } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { copyText } from 'language';
import cloverUtils from 'utils/clover';
import storeUtils from 'utils/storeLocation';
import { Logout } from '@mui/icons-material';

const LogoutButton = () => {
  const { oktaAuth } = useOktaAuth();
  const logout = async () => {
    cloverUtils.clearCloverStorage();
    storeUtils.clearLocationStorage();
    oktaAuth.signOut();
  };

  return (
    <Box>
      <MenuItem onClick={logout}>
        <ListItemIcon>
          <Logout />
        </ListItemIcon>
        {copyText.Logout.logoutBtn}
      </MenuItem>
    </Box>
  );
};

export default LogoutButton;
