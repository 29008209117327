import { logError } from 'utils/errorUtils';
import axiosInstance from 'dataAccess/middleware/axiosInstance';

const getStores = async (brickStores: boolean) => {
  const uri = `${process.env.REACT_APP_MS_URL}/stores/?where=custom(fields(available = true))&perPage=100`;
  try {
    const result = await axiosInstance.get(uri);
    let results = result?.data?.results ?? [];
    if (brickStores) {
      // filter out virtual store locations
      results = results?.filter((store) => store?.supplyChannels?.length > 0);
    }
    return results;
  } catch (error) {
    logError({
      method: 'getStores',
      errorInfo: error,
      message: error?.message,
      source: 'api/stores',
    });
    return false;
  }
};

export default { getStores };
