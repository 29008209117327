import { CircularProgress } from '@mui/material';
import { bool, number, string, oneOfType } from 'prop-types';

const TextLoader = ({ text, loading, size = 10 }) => {
  if (loading) {
    return <CircularProgress size={size} />;
  }
  return text;
};

TextLoader.propTypes = {
  text: oneOfType([string, number]).isRequired,
  loading: bool.isRequired,
  size: number.isRequired,
};
export default TextLoader;
