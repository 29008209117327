import { Grid, Skeleton, Typography } from '@mui/material';
import { func, shape } from 'prop-types';

import { copyText } from 'language';

import VariantSelect from './VariantSelect';

/**
 * Variants:
 * - size
 * - color
 * - pillow-size
 * - pillow-height
 * - duvet-type
 * - gender
 * - depth
 * - feel
 */

const Variants = ({ variants, formData, handleChange }) => {
  return variants ? (
    <Grid item xs={4}>
      {variants.feel && variants.feel.length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.feel}
          value={formData.feel}
          options={variants.feel}
          onChange={(e) => handleChange(e, 'feel')}
          labelId="feel-select-label"
          selectId="feel-select"
        />
      )}
      {variants.size && variants.size.length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.size}
          value={formData.size}
          options={variants.size}
          onChange={(e) => handleChange(e, 'size')}
          labelId="size-select-label"
          selectId="size-select"
        />
      )}
      {variants.color && variants.color.length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.color}
          value={formData.color}
          options={variants.color}
          onChange={(e) => handleChange(e, 'color')}
          labelId="color-select-label"
          selectId="color-select"
        />
      )}
      {variants['pillow-size'] && variants['pillow-size'].length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.pillow_size}
          value={formData['pillow-size']}
          options={variants['pillow-size']}
          onChange={(e) => handleChange(e, 'pillow-size')}
          labelId="pillow-size-select-label"
          selectId="pillow-size-select"
        />
      )}
      {variants['pillow-height'] && variants['pillow-height'].length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.pillow_height}
          value={formData['pillow-height']}
          options={variants['pillow-height']}
          onChange={(e) => handleChange(e, 'pillow-height')}
          labelId="pillow-height-select-label"
          selectId="pillow-height-select"
        />
      )}
      {variants['duvet-type'] && variants['duvet-type'].length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.duvet_type}
          value={formData['duvet-type']}
          options={variants['duvet-type']}
          onChange={(e) => handleChange(e, 'duvet-type')}
          labelId="duvet-type-select-label"
          selectId="duvet-type-select"
        />
      )}
      {variants.gender && variants.gender.length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.gender}
          value={formData.gender}
          options={variants.gender}
          onChange={(e) => handleChange(e, 'gender')}
          labelId="gender-select-label"
          selectId="gender-select"
        />
      )}
      {variants.depth && variants.depth.length > 1 && (
        <VariantSelect
          label={copyText.Shop.PDP.variants.depth}
          value={formData.depth}
          options={variants.depth}
          onChange={(e) => handleChange(e, 'depth')}
          labelId="depth-select-label"
          selectId="depth-select"
        />
      )}
    </Grid>
  ) : (
    <Grid item xs={4} data-testid="variant_skeleton_loader">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ fontSize: '1.2rem' }}>
            <Skeleton />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
            <Skeleton />
          </Typography>
          <Typography variant="p">
            <Skeleton />
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="p" component="h4" sx={{ fontSize: '1rem' }}>
            <Skeleton />
          </Typography>
          <Typography variant="p">
            <Skeleton />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

Variants.propTypes = {
  variants: shape({}).isRequired,
  formData: shape({}).isRequired,
  handleChange: func.isRequired,
};

export default Variants;
