import attribution from 'dataAccess/api/attribution.ts';

const getCurrentSalesAgent = ({ cart }) => {
  if (!cart) {
    return null;
  }
  return cart?.salesAssociateId ?? cart?.custom?.fields?.salesAssociateId ?? null;
};

const handleUpdate = async ({
  cart,
  newValue,
  agents,
  setSelectedAgent,
  setEditMode,
  setMessage,
}) => {
  try {
    if (newValue === null) {
      return;
    }
    const updatedCart = await attribution.setAgentAttribution(cart.id, newValue.email);
    const updatedAgent = agents.find((a) => a.email === updatedCart.salesAssociateId);
    setSelectedAgent(updatedAgent);
    setEditMode(false); // Exit edit mode after updating
  } catch (err) {
    setMessage(err.message);
  }
};

const fetchAgents = async ({ setAgents, setLoading, setMessage, setLoadingSuccess }) => {
  try {
    setLoading(true);
    setLoadingSuccess(true);
    const result = await attribution.getAgents();
    setAgents(result);
    setLoading(false); // Set loading to false after data is fetched
  } catch (error) {
    setMessage(JSON.stringify(error));
    setLoadingSuccess(false);
  } finally {
    setLoading(false);
  }
};

export default {
  handleUpdate,
  fetchAgents,
  getCurrentSalesAgent,
};
