import React from 'react';
import { number } from 'prop-types';
import { Skeleton, Grid, Box } from '@mui/material';

const SkeletonGrid = ({ numItems }) => {
  return [...Array(numItems)].map((item, ind) => {
    const id = ind;
    return (
      <Grid item key={id}>
        <Box sx={{ maxWidth: 290 }}>
          <Skeleton variant="rectangular" width={290} height={240} />
        </Box>
      </Grid>
    );
  });
};

SkeletonGrid.propTypes = {
  numItems: number,
};

SkeletonGrid.defaultProps = {
  numItems: 9,
};

export default SkeletonGrid;
