import { useState } from 'react';
import { Button, Typography, Snackbar, Alert } from '@mui/material';
import { copyText } from 'language';
import { usePopulateProducts, useInventoryUpdateTime } from 'contexts';

import TextLoader from 'components/TextLoader';

const InventoryUpdateBtn = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const populateProducts = usePopulateProducts();
  const inventoryUpdateTime = useInventoryUpdateTime();
  const updateProducts = async () => {
    try {
      setLoading(true);
      await populateProducts();
      setLoading(false);
    } catch {
      setLoading(false);
      setError(true);
    }
  };

  const handleClose = () => {
    setError(false);
  };
  return (
    <>
      <Button variant="contained" disabled={loading} onClick={updateProducts} sx={{ m: 1 }}>
        <TextLoader text={copyText.Shop.PDP.inventory.update} loading={loading} size={20} />
      </Button>
      <Typography variant="subtitle2" fontWeight="bold" sx={{ m: 1, ml: 2 }}>
        {`${copyText.Shop.PDP.inventory.lastUpdate} ${inventoryUpdateTime.toLocaleTimeString(
          navigator.language,
          {
            hour: '2-digit',
            minute: '2-digit',
          },
        )}`}
      </Typography>
      <Snackbar open={error} autoHideDuration={5000} onClose={handleClose}>
        <Alert severity="error" onClose={handleClose}>
          {copyText.Shop.PDP.inventory.error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default InventoryUpdateBtn;
