import paymentsApi from 'dataAccess/api/payments.ts';
import getExchangeTotal from 'utils/CartFinancials/getExchangeTotal';
import cartService from 'dataAccess/api/cart.ts';
import discountService from 'dataAccess/api/cart.discounts.ts';
import copyText from 'language/enUS';
import convertCentToDollar from 'utils/convertCentToDollar';

/**
 * Parses a JSON string into an object. If parsing fails, it returns an empty object.
 *
 * @param {string} jsonString - A JSON string to parse.
 * @returns {object} An object representing the parsed JSON or an empty object if parsing fails.
 */
const parseJSON = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (err) {
    return {};
  }
};

/**
 * Calculates the total promotional code discounts for a cart based on its line items.
 *
 * @param {object} cart - The cart object containing line items.
 * @returns {number} The total promotional code discounts as a number.
 */
const getCartDiscounts = (cart) => {
  let totalPromoCodeDiscounts = 0;

  if (cart && cart.lineItems && cart.lineItems.length > 0) {
    cart.lineItems.forEach((lineItem) => {
      const discountsJson =
        lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.discounts_json;
      const parsedPromoCodeDiscounts = discountsJson ? JSON.parse(discountsJson)[0] : null;
      totalPromoCodeDiscounts +=
        ((parsedPromoCodeDiscounts && parsedPromoCodeDiscounts.cent_amount) || 0) *
          lineItem.quantity || 0;
    });
  }
  return totalPromoCodeDiscounts;
};

/**
 * Calculates the total recycling fees for a cart based on custom line items with the 'custom_recyclingFee' slug.
 *
 * @param {object} cart - The cart object containing custom line items.
 * @returns {number} The total recycling fees as a number.
 */
const getRecyclingFee = (cart) => {
  let recyclingFee = 0;
  cart?.customLineItems?.forEach((lineItem) => {
    if (lineItem.slug === 'custom_recyclingFee') {
      recyclingFee += lineItem.taxedPrice?.totalGross?.centAmount || 0;
    }
  });
  return recyclingFee;
};

/**
 * Calculates the total gift card amounts applied to the cart. It considers whether gift cards cover the entire cart.
 *
 * @param {object} cart - The cart object containing gift cards and taxed price information.
 * @returns {number} The total gift card amounts as a number.
 */
const getGiftCardTotals = (cart) => {
  let giftCards = 0;
  if (cart?.giftCards) {
    giftCards = cart.giftCards.reduce((acc, next) => {
      return acc + (next.amount?.centAmount || 0);
    }, 0);
  }
  const cartTotal = cart?.taxedPrice?.totalGross?.centAmount;

  if (giftCards > cartTotal) {
    return cartTotal;
  }
  return giftCards;
};

/**
 * Calculates the total shipping amounts based on custom line items with the 'custom_recyclingFee' slug.
 *
 * @param {object} cart - The cart object containing custom line items.
 * @returns {number} The total shipping amounts as a number.
 */
const getShippingTotals = (cart) => {
  let total = 0;
  cart?.customLineItems?.forEach((lineItem) => {
    if (lineItem.slug === 'IN-HOME-SETUP_PAID') {
      total += lineItem.totalPrice?.centAmount || 0;
    }
  });
  return total;
};

/**
 * Calculates the tax amount for the cart based on its taxed price information.
 *
 * @param {object} cart - The cart object containing taxed price details.
 * @returns {number} The tax amount as a number.
 */
const getTax = (cart) => {
  if (cart?.taxedPrice) {
    const totalGross = cart?.taxedPrice?.totalGross?.centAmount;
    const totalNet = cart?.taxedPrice?.totalNet?.centAmount;
    return totalGross - totalNet;
  }
  return 0;
};

/**
 * Calculates the subtotal of the cart, taking into account exchanges, gift cards, promotional discounts, and recycling fees.
 *
 * @param {object} cart - The cart object.
 * @returns {number} The subtotal amount as a number.
 */
const getSubtotal = (cart) => {
  const exchanges = getExchangeTotal(cart);
  const giftCards = getGiftCardTotals(cart);
  const promos = getCartDiscounts(cart);
  const recyclingFee = getRecyclingFee(cart);
  const shipping = getShippingTotals(cart);
  const price =
    ((cart && cart.totalPrice && cart.totalPrice.centAmount) || 0) +
    promos -
    recyclingFee -
    shipping;
  if (price - promos - giftCards - exchanges < 0) {
    return 0;
  }
  const total = price - promos - giftCards - exchanges;
  return total;
};

/**
 * Calculates the total price of the cart, considering gift cards and exchanges.
 *
 * @param {object} cart - The cart object.
 * @returns {number} The total price amount as a number.
 */
const getTotalPrice = (cart) => {
  const giftCards = getGiftCardTotals(cart);
  const exchanges = getExchangeTotal(cart);
  let total = (cart?.taxedPrice?.totalGross?.centAmount || 0) - giftCards - exchanges;
  if (total < 0) {
    total = 0;
  }
  return total;
};

/**
 * Calculates the total refund amount for an exchange order, considering gift cards and exchanges.
 *
 * @param {object} cart - The cart object, including exchange order information.
 * @returns {number} The total refund amount as a number.
 */
const getExchangeRefundTotal = (cart) => {
  if (cart?.is_exchange_order) {
    const giftCards = getGiftCardTotals(cart);
    const exchanges = getExchangeTotal(cart);
    const total = (cart?.taxedPrice?.totalGross?.centAmount || 0) - giftCards - exchanges;
    if (total > 0) {
      return 0;
    }
    return total;
  }
  return 0;
};

/**
 * Extracts pending payment data from the cart object, parsing it into an object.
 *
 * @param {object} cart - The cart object containing pending payment data.
 * @returns {object} An object representing the pending payment data.
 */
const getPendingPayments = (cart) => {
  let pendingPayments = {};

  if (cart?.custom?.fields?.pending_payments) {
    pendingPayments = parseJSON(cart.custom.fields.pending_payments);
  } else if (cart?.pending_payments) {
    pendingPayments = parseJSON(cart.pending_payments);
  }

  return pendingPayments;
};

/**
 * Parses pending payments from the cart object into an array of payment objects.
 *
 * @param {object} cart - The cart object containing pending payment data.
 * @returns {array} An array of payment objects.
 */
const parsePendingPayments = (cart) => {
  const pendingPayments = getPendingPayments(cart);
  const paymentKeys = Object.keys(pendingPayments);
  const paymentsArray = paymentKeys.map((key) => {
    return {
      paymentId: pendingPayments[key].paymentId ?? key,
      ...pendingPayments[key],
    };
  });
  return paymentsArray;
};

/**
 * Calculates the unpaid total amount based on the cart's total price and a list of payments.
 *
 * @param {object} cart - The cart object.
 * @param {array} payments - An array of payment objects.
 * @returns {number} The unpaid total amount as a number.
 */
const getUnpaidTotal = (cart, payments) => {
  const cartTotal = getTotalPrice(cart);
  let paymentsTotal = 0;
  payments.forEach((payment) => {
    paymentsTotal += payment.amount;
  });
  const unpaidTotal = cartTotal - paymentsTotal;
  return unpaidTotal;
};

const checkCustomAmount = (amount, remainingTotal) => {
  const centAmount = parseInt(amount * 100, 10);
  if (centAmount > remainingTotal || centAmount <= 0) {
    return false;
  }
  return true;
};

const updateCartWithPendingPayment = async (payment, cart) => {
  const paymentDetails = {
    id: payment?.id,
    externalPaymentId: payment?.externalPaymentId,
    amount: payment?.amount,
    paymentProvider: 'CLOVER',
    state: payment?.cardTransaction?.state ?? 'PENDING',
    paymentType: payment?.cardTransaction?.cardType,
    last4: payment?.cardTransaction?.last4 ?? payment?.last4,
  };
  const updatedCart = await paymentsApi.addPendingPayment(paymentDetails, cart.id);
  return updatedCart;
};

const checkPendingPaymentsCleared = async (cartId) => {
  const fetchedCart = await cartService.getCart(cartId);
  const pendingPayments = parsePendingPayments(fetchedCart.data);
  if (pendingPayments.length === 0) {
    return true;
  }
  return false;
};

const isDiscount = (discount) => {
  if (discount.method === 'coupon') {
    return true;
  }
  return false;
};

const getRejectedPromos = (cart) => {
  const rejected = cart?.custom?.fields?.rejected_promotions ?? cart?.rejected_promotions ?? [];

  let parsedRejected;
  try {
    parsedRejected = JSON.parse(rejected);
  } catch (error) {
    parsedRejected = rejected;
  }
  return parsedRejected;
};

const removeDiscountCode = async ({ cart, code, setCart, setErrorMessage, setLoading }) => {
  try {
    setLoading(true);
    const result = await discountService.removeDiscountFromCart(cart.id, code.coupon_code);
    setCart(result.data);
  } catch (err) {
    setErrorMessage(copyText.Cart.PromoCode.removeError);
  } finally {
    setLoading(false);
  }
};

// Helper function to parse discounts
const parseDiscounts = (lineItem) => JSON.parse(lineItem.custom?.fields?.discounts_json);

// Helper function to calculate total cumulative discount
const calculateTotalDiscount = (item, lineItems) => {
  let total = 0;
  lineItems.forEach((lineItem) => {
    const discounts = parseDiscounts(lineItem);
    discounts.forEach((discount) => {
      if (discount.id === item.id) {
        total += discount.cent_amount * lineItem.quantity || 0;
      }
    });
  });
  return convertCentToDollar(total);
};

const populateAppliedDiscountCodes = ({ cart, rejectedPromos, setDiscountCodes }) => {
  const discountCodesArray = [];

  cart?.lineItems?.forEach((lineItem) => {
    const discounts = parseDiscounts(lineItem);
    discounts.forEach((item) => {
      const existingDiscount = discountCodesArray.find((ele) => ele.id === item.id);
      const rejectedPromo = rejectedPromos.find((ele) => ele.id === item.id);
      if (!existingDiscount && !rejectedPromo) {
        discountCodesArray.push(item);
      }
    });
  });

  // calculate total discount for each discount code
  const updatedDiscountCodesArray = discountCodesArray.map((item) => {
    const updatedItem = { ...item };
    updatedItem.totalCumulativeDiscount = calculateTotalDiscount(item, cart?.lineItems);
    return updatedItem;
  });

  setDiscountCodes(updatedDiscountCodesArray);
  return updatedDiscountCodesArray;
};

const calculatePreDiscountedPrice = (cart) => {
  const price = cart?.totalPrice?.centAmount;
  const promos = getCartDiscounts(cart);
  const shipping = getShippingTotals(cart);
  const recycling = getRecyclingFee(cart);
  const total = price + promos - shipping - recycling;

  return total;
};

const populateExchangeRefund = (cart, setRefundTotal, setShowRefund) => {
  if (cart?.is_exchange_order && getExchangeRefundTotal(cart) < 0) {
    setRefundTotal(convertCentToDollar(Math.abs(getExchangeRefundTotal(cart))));
    setShowRefund(true);
  } else {
    setShowRefund(false);
  }
};

export default {
  getTotalPrice,
  getSubtotal,
  getShippingTotals,
  getCartDiscounts,
  getGiftCardTotals,
  getRecyclingFee,
  getTax,
  getExchangeRefundTotal,
  getPendingPayments,
  parsePendingPayments,
  getUnpaidTotal,
  checkCustomAmount,
  updateCartWithPendingPayment,
  checkPendingPaymentsCleared,
  isDiscount,
  getRejectedPromos,
  removeDiscountCode,
  populateAppliedDiscountCodes,
  parseDiscounts,
  calculateTotalDiscount,
  calculatePreDiscountedPrice,
  populateExchangeRefund,
};
