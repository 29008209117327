import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

import ClearCartBtn from 'components/ClearCart/components/ClearCartBtn';
import { copyText } from 'language';
import SnackbarMessage from 'components/SnackbarMessage/SnackbarMessage';

import CartPopulate from './components/CartPopulate';

const DevTools = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [message, setMessage] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const expireCloverToken = () => {
    const storage = localStorage.getItem('clover-token-storage');
    const cloverToken = storage ? JSON.parse(storage) : {};
    // always marks the token as expired
    cloverToken.authTokenExpiration = 1;
    localStorage.setItem('clover-token-storage', JSON.stringify(cloverToken));
    setMessage(copyText.DevTools.cloverTokenExpired);
  };

  const causeCloverRefreshFailure = () => {
    expireCloverToken();
    const storage = localStorage.getItem('clover-token-storage');
    const cloverToken = storage ? JSON.parse(storage) : {};
    cloverToken.refreshToken = null;
    localStorage.setItem('clover-token-storage', JSON.stringify(cloverToken));
    setMessage(copyText.DevTools.cloverRefreshFailure);
  };

  const expireOktaToken = () => {
    const oktaToken = sessionStorage.getItem('okta-token-storage')
      ? JSON.parse(sessionStorage.getItem('okta-token-storage'))
      : null;
    if (oktaToken) {
      oktaToken.accessToken.expiresAt = 0;
      oktaToken.idToken.expiresAt = 0;
      sessionStorage.setItem('okta-token-storage', JSON.stringify(oktaToken));
    }
  };
  return (
    <>
      <Button sx={{ mr: 3 }} variant="contained" onClick={handleClick}>
        {copyText.DevTools.devTools}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <CartPopulate />
        <ClearCartBtn text={copyText.Cart.CartTools.loadNewCart} />
        <MenuItem onClick={expireOktaToken}>{copyText.DevTools.expireOktaToken}</MenuItem>

        <MenuItem onClick={expireCloverToken}>{copyText.DevTools.expireCloverToken}</MenuItem>
        <MenuItem onClick={causeCloverRefreshFailure}>
          {copyText.DevTools.causeCloverRefreshFailure}
        </MenuItem>
      </Menu>
      <SnackbarMessage message={message} setMessage={setMessage} />
    </>
  );
};

export default DevTools;
