import enUS from './enUS';

const language = process.env.REACT_APP_LANGUAGE;

// TODO: Add support to dynamically change language
const copyText = {
  ...enUS,
};

export { copyText, language as lang };
