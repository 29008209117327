import React from 'react';
import { Box, Typography } from '@mui/material';
import { shape, arrayOf, string, bool } from 'prop-types';
import { copyText } from 'language';
import ReturnConditionAndReasonForm from './ReturnConditionAndReasonForm';
import ReturnCustomerInfoForm from './ReturnCustomerInfoForm';

const ReturnConfirmation = ({ formData }) => (
  <Box>
    <Typography color="primary" sx={{ fontWeight: 700, fontSize: '16px' }}>
      {copyText.Orders.Returns.returnFormConfirmation}
    </Typography>
    <ReturnConditionAndReasonForm formData={formData} readOnly />
    <ReturnCustomerInfoForm formData={formData} readOnly />
  </Box>
);

export default ReturnConfirmation;

ReturnConfirmation.propTypes = {
  formData: shape({
    condition: string,
    returnReasons: arrayOf(string),
    otherReason: string,
    physicalReturn: bool,
    agent_name: string,
    agent_email: string,
    store_code: string,
    order_number: string,
    customer_email: string,
    customer_name: string,
    is_return: bool,
    store_address_1: string,
    store_suite: string,
    store_city: string,
    store_state: string,
    store_zip: string,
    store_country: string,
  }),
};

ReturnConfirmation.defaultProps = {
  formData: [],
};
