import { addToCart } from 'utils/cart';
import shippingMethods from 'utils/shippingMethods';
import ShippingAddress from 'dataAccess/api/cart.addresses.ts';
import storeLocation from 'utils/storeLocation';

const populateStoreAddress = async ({ setLoading, setCart, cart }) => {
  try {
    setLoading(true);
    const storeData = storeLocation.getLocationStorage();
    const storeAddress = storeData.supplyChannels[0].obj.address;
    const address = {
      firstName: 'Purple',
      lastName: 'Innovation',
      streetAddress: storeAddress.streetName,
      additionalStreetInfo: storeAddress.streetNumber,
      postalCode: storeAddress.postalCode,
      city: storeAddress.city,
      state: storeAddress.state,
      country: storeAddress.country,
      email: 'noEmail@email.com',
      phone: 'None',
    };
    const result = await ShippingAddress.setShippingAddress(cart?.id, cart, address, true);
    setCart(result.response);
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

const addLineItem = async ({ sku, setLoading, cart, setCart, salesAssociateId }) => {
  setLoading(true);
  try {
    const updatedCart = await addToCart({ sku }, cart, salesAssociateId);
    const result = await shippingMethods.handleNewLineItem(updatedCart, sku);
    if (result === null) {
      setCart(updatedCart);
    } else {
      setCart(result);
    }
    setLoading(false);
  } catch (error) {
    setLoading(false);
  }
};

export default {
  populateStoreAddress,
  addLineItem,
};
