import clover from 'dataAccess/api/clover.ts';
import { lang } from 'language';
import copyText from 'language/enUS';
import convertCentToDollar from 'utils/convertCentToDollar';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import orderCreationUtils from 'utils/orderCreationUtils';
import storeLocation from 'utils/storeLocation';
import { formatCreatedAtTime } from 'utils/time';

const getPayments = ({ order, setPayments }) => {
  const paymentMethods = financialCalculators.parsePendingPayments(order);
  setPayments(paymentMethods);
};

const getAgent = ({ oktaAuth, setAgentName }) => {
  const name = oktaAuth.authStateManager?._authState?.idToken?.claims?.name;
  if (name) {
    setAgentName(name);
  }
};

const handleSnackbarClose = ({ setOpenSnackbar, setMessage }) => {
  setOpenSnackbar(false);
  setMessage('');
};

const printThermalReceipt = async ({ setLoading, setMessage, setOpenSnackbar, order }) => {
  try {
    setLoading(true);
    if (order.pending_payments) {
      await orderCreationUtils.sendSplitPaymentReceipts(order);
    } else {
      await clover.printReceipt(null, order, setMessage);
    }
    setMessage(copyText.Orders.Actions.printReceiptSuccess);
  } catch (error) {
    setMessage(copyText.Orders.Actions.printReceiptFailure);
  } finally {
    setOpenSnackbar(true);

    setLoading(false);
  }
};

const getGiftCardAmount = (giftCard) => {
  return convertCentToDollar(giftCard?.amount?.centAmount ?? 0);
};

const getGiftCardNumber = (giftCard) => {
  if (giftCard && giftCard.giftCardNumber) {
    const { giftCardNumber } = giftCard;
    return giftCardNumber.slice(giftCardNumber.length - 4);
  }
  return '';
};

const getLineItemPrice = (lineItem) => {
  const lineItemPrice = lineItem?.totalPrice?.centAmount ?? 0;
  let discounts = [];
  if (lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.discounts_json) {
    discounts = JSON.parse(lineItem.custom.fields.discounts_json);
  }
  const sumWithInitial = discounts.reduce(
    (accumulator, discount) => accumulator + (discount?.cent_amount ?? 0),
    lineItemPrice,
  );
  return convertCentToDollar(sumWithInitial);
};

const getShippingMethod = ({ lineItem, order }) => {
  const result = order?.customLineItems?.filter((customLineItem) => {
    return customLineItem?.custom?.fields?.lineItemsIds?.includes(lineItem.id);
  });
  return result[0]?.custom?.fields?.netsuite_shipping_name ?? '';
};

const getGiftCardTotals = (order) => {
  let giftCards = 0;

  if (order && order.giftCards && Array.isArray(order.giftCards)) {
    giftCards = order?.giftCards.reduce((acc, next) => {
      const amount = next?.amount?.centAmount ?? 0;
      return acc + amount;
    }, 0);
  }

  return giftCards;
};

const calculateExchangeAmount = ({ order }) => {
  let exchangeTotalAmount = 0;
  if (order?.custom?.fields?.is_exchange_order || order.is_exchange_order) {
    const exchangeLineItems = JSON.parse(
      order?.custom?.fields?.exchange_order_line_items || order?.exchange_order_line_items,
    );
    exchangeTotalAmount = exchangeLineItems.reduce((acc, item) => {
      if (item?.exchangeValue?.centAmount) {
        return item.exchangeValue.centAmount + acc;
      }
      return acc;
    }, 0);
  }
  return exchangeTotalAmount;
};

const getPromoCodeDiscounts = ({ order, setPromoCodeDiscounts }) => {
  const totalPromoCodeDiscounts = [];
  order?.lineItems?.forEach((lineItem) => {
    if (lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.discounts_json) {
      const parsedPromoCodeDiscounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
      if (parsedPromoCodeDiscounts) {
        const lineItemDiscounts = parsedPromoCodeDiscounts.map((discount) => {
          const discountAmount = discount?.cent_amount ? discount.cent_amount : 0;
          return {
            id: discount?.id ?? '',
            centAmount: convertCentToDollar(discountAmount * lineItem.quantity) ?? '',
            couponCode: discount?.coupon_code ?? '',
            method: discount?.method ?? '',
            displayName: discount?.display_name ?? '',
          };
        });
        totalPromoCodeDiscounts.push(...lineItemDiscounts);
      }
    }
  });
  setPromoCodeDiscounts(totalPromoCodeDiscounts);
};

const calculateTotalPrice = (order) => {
  const giftCards = getGiftCardTotals(order);
  const exchangeAmount = calculateExchangeAmount({ order });
  const price = order?.taxedPrice?.totalGross?.centAmount ?? 0;
  return price - giftCards - exchangeAmount;
};

const populateTotalPrice = ({ order, setTotalPrice, setRefundAmount }) => {
  const total = calculateTotalPrice(order);
  if (total < 0) {
    setTotalPrice(convertCentToDollar(0));
    setRefundAmount(convertCentToDollar(Math.abs(total)));
  } else {
    setTotalPrice(convertCentToDollar(total));
  }
};

const populateStoreData = async ({ order, setAddress, setStoreName }) => {
  const hasStoreKey = order && order.store && order.store?.key;
  if (hasStoreKey) {
    const storeData = await storeLocation.getStoreByKey(order?.store?.key);
    setAddress(storeData?.supplyChannels[0]?.obj?.address ?? '');
    setStoreName(storeData?.name[lang] ?? '');
  } else {
    setAddress(storeLocation.getStoreAddress());
    setStoreName(storeLocation.getLocationStorage()?.name[lang] ?? '');
  }
};

const populateTime = ({ setDate, order }) => {
  setDate(formatCreatedAtTime(order.createdAt));
};

const calculateSubtotal = (order) => {
  const giftCards = getGiftCardTotals(order);
  const price = order?.taxedPrice?.totalGross?.centAmount ?? 0;
  return convertCentToDollar(price - giftCards);
};

export default {
  getPayments,
  getAgent,
  handleSnackbarClose,
  printThermalReceipt,
  getGiftCardAmount,
  getGiftCardNumber,
  getLineItemPrice,
  getShippingMethod,
  getGiftCardTotals,
  getPromoCodeDiscounts,
  calculateTotalPrice,
  populateTotalPrice,
  populateStoreData,
  populateTime,
  calculateSubtotal,
  calculateExchangeAmount,
};
