import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Grid, Typography, ImageListItem, CircularProgress } from '@mui/material';

import VisaIcon from 'media/images/visaBlue.png';
import MastercardIcon from 'media/images/mc_symbol.svg';
import DiscoverLogo from 'media/images/discoverLogo.png';
import { copyText } from 'language';

const ShowPayment = ({ orderPayment }) => {
  const getCardType = (brand) => {
    let card;
    if (brand === 'visa') {
      card = VisaIcon;
    } else if (brand === 'mastercard') {
      card = MastercardIcon;
    } else if (brand === 'discover') {
      card = DiscoverLogo;
    } else {
      card = 'american express';
    }
    return card;
  };
  const isTransferPayment = (transactions) => {
    let isTransfer = false;
    transactions.forEach((transaction) => {
      if (transaction?.custom?.fields?.type === 'Transfer') {
        isTransfer = true;
      }
    });
    return isTransfer;
  };

  if (orderPayment) {
    return orderPayment.map((payment) => {
      if (
        payment?.paymentMethodInfo?.method !== 'GIFT_CARD' &&
        payment?.custom?.fields?.maskedCard
      ) {
        return (
          <Grid key={payment?.id} container alignItems="center">
            <Grid item>
              <ImageListItem sx={{ width: 60, mr: 1 }}>
                <img
                  src={getCardType(payment?.custom?.fields?.cardBrand)}
                  alt={copyText.Orders.CustomerDetails.creditCard.alt}
                />
              </ImageListItem>
            </Grid>
            <Grid item>
              <Typography>
                {copyText.Orders.CustomerDetails.endingIn}
                {payment?.custom?.fields?.maskedCard}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      if (
        payment?.paymentMethodInfo?.method !== 'GIFT_CARD' &&
        !isTransferPayment(payment?.transactions)
      ) {
        return (
          <Grid key={payment?.id} container>
            <Grid item>
              <Typography sx={{ fontWeight: 'bold' }}>
                {payment?.paymentMethodInfo?.method?.replace('_', ' ')}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      if (
        payment?.paymentMethodInfo?.paymentInterface === 'CHARGEAFTER' &&
        !isTransferPayment(payment?.transactions)
      ) {
        return (
          <Grid key={payment?.id} container>
            <Grid item>
              <Typography sx={{ fontWeight: 'bold' }}>
                {payment?.paymentMethodInfo?.paymentInterface?.replace('_', ' ')}
              </Typography>
            </Grid>
          </Grid>
        );
      }
      return null;
    });
  }

  return <CircularProgress />;
};

ShowPayment.propTypes = {
  orderPayment: arrayOf(
    shape({ id: string, paymentMethodInfo: shape({}), custom: shape({ fields: shape({}) }) }),
  ).isRequired,
};

export default ShowPayment;
