import { Receipt } from '@mui/icons-material';
import { Button } from '@mui/material';
import ReceiptModal from 'components/ReceiptModal/ReceiptModal';
import { useSelectedOrder } from 'contexts/orders';
import { useHistory } from 'react-router-dom';
import { copyText } from 'language';
import React, { useState } from 'react';

const ReprintReceipts = () => {
  const selectedOrder = useSelectedOrder();
  const history = useHistory();
  const [openReprint, setOpenReprint] = useState(false);

  const handleReprint = async () => {
    setOpenReprint(true);
  };

  const loadOrder = (orderNumber) => {
    setOpenReprint(false);
    history.push({ pathname: `/orders/order/${orderNumber}` });
  };

  return (
    selectedOrder && (
      <>
        <Button startIcon={<Receipt />} variant="contained" color="primary" onClick={handleReprint}>
          {copyText.Orders.Actions.viewReceipt}
        </Button>
        <ReceiptModal
          open={openReprint}
          showHeader={false}
          order={selectedOrder}
          closeModal={() => setOpenReprint(false)}
          loadOrder={loadOrder}
          isReprint
        />
      </>
    )
  );
};

export default ReprintReceipts;
