import locationUtil from 'utils/storeLocation';
import { shape, arrayOf, string, object, func, bool } from 'prop-types';
import {
  Select,
  MenuItem,
  DialogContent,
  DialogActions,
  Alert,
  Typography,
  FormControl,
  InputLabel,
  ListItemText,
} from '@mui/material';
import { copyText, lang } from 'language';
import ClearCartBtn from 'components/ClearCart/components/ClearCartBtn';
import { useEffect, useState } from 'react';
import DialogTemplate from 'components/DialogTemplate/DialogTemplate';

const SelectLocation = ({
  selectedStore,
  setSelectedStore,
  storeList,
  updateSelectedStore,
  openModal,
  closeModal,
  message,
  setMessage,
}) => {
  const [cancelDisabled, setCancelDisabled] = useState(false);
  const handleClose = (event, reason) => {
    // Disable closing modal from clicking backdrop
    if (reason && reason === 'backdropClick') {
      return;
    }
    if (selectedStore && selectedStore.key) {
      updateSelectedStore(selectedStore);
      closeModal(true);
    }
  };

  const handleSelectedStore = (store) => {
    setSelectedStore(store);
  };

  const handleCancel = async () => {
    if (selectedStore) {
      const prevStore = await locationUtil.getLocationStorage();
      setSelectedStore(prevStore);
      closeModal(true);
    }
  };

  const checkDisableCancel = async () => {
    const prevStore = await locationUtil.getLocationStorage();
    if (prevStore) {
      setCancelDisabled(false);
    } else {
      setCancelDisabled(true);
    }
  };

  useEffect(() => {
    checkDisableCancel();
  }, [openModal]);

  return (
    <DialogTemplate
      fullWidth
      open={openModal}
      setOpen={closeModal}
      noButton
      onClose={handleCancel}
      closeDisabled={cancelDisabled}
      message={message}
      setMessage={setMessage}
      dialogTitle={copyText.Location.selectLocation}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <FormControl fullWidth sx={{ mt: 3 }}>
          <InputLabel id="store-select">{copyText.Location.selectStore}</InputLabel>
          <Select
            id="store-select"
            value={selectedStore?.id || ''}
            label={copyText.Location.selectStore}
          >
            {locationUtil.sortStoresAlphabetical(storeList).map((store) => (
              <MenuItem
                key={store?.id}
                onClick={() => handleSelectedStore(store)}
                value={store?.id}
              >
                <ListItemText primary={`${store?.key}-${store?.name[lang]}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Alert severity="warning" sx={{ mt: 3 }}>
          <Typography>{copyText.Cart.CartTools.cartsAssociatedToStores}</Typography>
          <Typography>{copyText.Cart.CartTools.storeChangeWarning}</Typography>
        </Alert>
      </DialogContent>
      <DialogActions>
        <ClearCartBtn
          preEvent={() => updateSelectedStore(selectedStore)}
          onComplete={handleClose}
          disabled={!selectedStore}
          text={copyText.App.chooseStore}
        />
      </DialogActions>
    </DialogTemplate>
  );
};

SelectLocation.propTypes = {
  selectedStore: shape({ id: string }),
  setSelectedStore: func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  storeList: arrayOf(shape({ id: string, name: object })),
  updateSelectedStore: func.isRequired,
  openModal: bool,
  closeModal: func.isRequired,
  message: string.isRequired,
  setMessage: func.isRequired,
};

SelectLocation.defaultProps = {
  selectedStore: {
    id: null,
  },
  storeList: [{ id: null, name: { 'en-US': '' } }],
  openModal: false,
};

export default SelectLocation;
