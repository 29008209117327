import { React, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Grid, Typography, CardMedia, Skeleton, Snackbar } from '@mui/material';
import { useParams } from 'react-router-dom';

import { copyText, lang } from 'language';
import { useProductDetailsMap, useSetProductDetailsMap, useSelectedShop } from 'contexts';

import Page404 from 'views/Page404/Page404';
import ShopHeader from 'views/Shop/components/ShopHeader/ShopHeader';
import AddToCartBtn from 'components/AddToCartBtn/AddToCartBtn';
import InventoryDialog from 'components/InventorySelector/InventorySelector';
import ViewTransition from 'components/Transitions/ViewTransition';

import ShippingDetails from './components/ShippingDetails';
import Variants from './components/VariantsBlock';

import {
  populateProductDetails,
  populateProduct,
  populatePricing,
  populateAttributes,
  populateAvailability,
  populateVariants,
  getSkuByAttributes,
  populateForm,
} from './PDP.utils';

const PDP = () => {
  const { authState } = useOktaAuth();
  const { categoryId, productId, variantSku } = useParams();
  const productDetailsMap = useProductDetailsMap();
  const setProductDetailsMap = useSetProductDetailsMap();
  const shopSelected = useSelectedShop();

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const [attributes, setAttributes] = useState();
  const [availability, setAvailability] = useState();
  const [variantPrice, setVariantPrice] = useState();
  const [selectedVariant, setSelectedVariant] = useState();
  const [variants, setVariants] = useState();
  const [formData, setFormData] = useState({});
  const [showMissingOptionSnackbar, setShowMissingOptionSnackbar] = useState(false);

  const outletChannelKey = 'OUTLET';
  const channelKey = shopSelected === 'outlet' ? outletChannelKey : null;

  useEffect(() => {
    populateProductDetails({
      categoryId,
      productId,
      setProductDetailsMap,
    });
    return () => {
      setLoading(false);
    };
  }, [authState]);

  useEffect(() => {
    populateProduct({
      categoryId,
      productId,
      productDetailsMap,
      setProduct,
      setSelectedVariant,
      variantSku,
    });
    return () => {
      setLoading(false);
    };
  }, [productDetailsMap]);

  useEffect(() => {
    populateAvailability({ product, setAvailability });
    populateVariants({ product, variantSku, setSelectedVariant, setVariants });
  }, [product]);

  useEffect(() => {
    populatePricing({ variant: selectedVariant, channelKey, setVariantPrice });
    populateAttributes({ variant: selectedVariant, setAttributes });
    populateForm({ product, selectedVariant, setFormData });
  }, [selectedVariant]);

  const handleChangeSelection = (e, key) => {
    const value = e.target.value;
    const data = { ...formData, [key]: value };

    const sku = getSkuByAttributes({ product, attributes: data });
    if (!sku) {
      setShowMissingOptionSnackbar(true);
    }
    if (sku) {
      populateVariants({ product, variantSku: sku, setSelectedVariant, setVariants });
    }
  };

  if (!product && !loading) {
    return <Page404 error={copyText.Shop.Variants.error} />;
  }

  return (
    <>
      <ShopHeader />
      <ViewTransition>
        <Grid container spacing={4}>
          {/* Heading */}
          <Grid item xs={12}>
            <Typography component="h2" variant="h4">
              {product ? product?.name[lang] : <Skeleton width="50%" />}
            </Typography>
            <Typography component="p" color="secondary">
              {selectedVariant ? selectedVariant?.sku : <Skeleton width="50%" />}
            </Typography>
            <Typography component="p">
              {attributes ? attributes.title : <Skeleton width="50%" />}
            </Typography>
          </Grid>

          {/* Details */}
          <Grid item xs={12} md={4}>
            {selectedVariant ? (
              <CardMedia
                component="img"
                image={selectedVariant?.images[0]?.url}
                alt={`${selectedVariant?.images[0]?.label} ${copyText.Shop.PDP.imgAlt}`}
              />
            ) : (
              <Skeleton height={240} width={240} />
            )}
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container display="flex" alignItems="center">
              <Grid item xs={12} sm={4} md={4}>
                <Typography sx={{ fontWeight: 600, fontSize: 18 }} component="span">
                  {variantPrice || <Skeleton width="50%" />}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: { xs: 3, sm: 0 } }}>
                {selectedVariant && <InventoryDialog product={selectedVariant} />}
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="p">
                  {attributes ? attributes?.subTitle : <Skeleton width="50%" />}
                </Typography>
              </Grid>

              {variants && (
                <Variants
                  variants={variants}
                  formData={formData}
                  handleChange={handleChangeSelection}
                  selectedSku={selectedVariant?.sku}
                />
              )}

              {attributes && <ShippingDetails attributes={attributes} />}

              <Grid item xs={12} justifyContent="flex-start">
                {selectedVariant ? (
                  <AddToCartBtn details={{ sku: selectedVariant.sku, attributes, availability }} />
                ) : (
                  <Skeleton height={40} width={100} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ViewTransition>
      <Snackbar
        open={showMissingOptionSnackbar}
        message={copyText.Shop.PDP.errors.missingOption}
        autoHideDuration={6000}
        onClose={() => setShowMissingOptionSnackbar(false)}
      />
    </>
  );
};

export default PDP;
