import { Button, Typography, MenuItem, ListItemIcon, CircularProgress } from '@mui/material';
import { AddShoppingCart } from '@mui/icons-material';
import TextLoader from 'components/TextLoader';
import { useSetAddToCartLoading, useSetCart } from 'contexts';
import { bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import { clearCart } from 'utils/cart';
import { useOktaAuth } from '@okta/okta-react';
import { copyText } from 'language';

const ClearCartBtn = ({
  disabled,
  text,
  onComplete,
  preEvent,
  setMessage,
  variant,
  textColor,
  menuItem,
}) => {
  const [loading, setLoading] = useState(false);
  const { oktaAuth } = useOktaAuth();

  const setCart = useSetCart();
  const setAddToCartLoading = useSetAddToCartLoading();
  if (menuItem) {
    return (
      <MenuItem
        onClick={() =>
          clearCart({
            preEvent,
            oktaAuth,
            onComplete,
            setLoading,
            setMessage,
            setCart,
            setAddToCartLoading,
          })
        }
      >
        <ListItemIcon>
          {loading ? <CircularProgress size={20} /> : <AddShoppingCart />}
        </ListItemIcon>
        <Typography variant="inherit"> {copyText.Cart.CartTools.loadNewCart}</Typography>
      </MenuItem>
    );
  }
  return (
    <Button
      sx={{ m: 1 }}
      disabled={loading || disabled}
      variant={variant ?? 'contained'}
      onClick={() =>
        clearCart({
          preEvent,
          oktaAuth,
          onComplete,
          setLoading,
          setMessage,
          setCart,
          setAddToCartLoading,
        })
      }
    >
      <Typography
        sx={{ pr: 1, pl: 1, pt: 1, pb: 1 }}
        color={textColor ?? 'white'}
        variant="h3"
        component="span"
      >
        <TextLoader text={text ?? ''} loading={loading} size={14} />
      </Typography>
    </Button>
  );
};

ClearCartBtn.propTypes = {
  text: string,
  disabled: bool,
  preEvent: func,
  onComplete: func,
  setMessage: func,
  variant: string,
  textColor: string,
  menuItem: bool,
};

ClearCartBtn.defaultProps = {
  text: '',
  preEvent: null,
  disabled: false,
  onComplete: null,
  setMessage: null,
  variant: 'contained',
  textColor: 'white',
  menuItem: false,
};

export default ClearCartBtn;
