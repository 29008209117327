import { logError } from 'utils/errorUtils';

// --- Capture event related errors & React component errors ---
window.addEventListener(
  'error',
  (event) => {
    if (event.type === 'error') {
      const eventInfo = {
        alt: event.target?.alt,
        baseUri: event.target?.baseUri,
        className: event.target?.className,
        currentSrc: event.target?.currentSrc,
        localName: event.target?.localName,
        nodeValue: event.target?.attributes?.src?.nodeValue,
      };
      const errorInfo = JSON.stringify(eventInfo);
      logError({
        errorInfo,
        method: event.target?.localName,
        source: event.target?.currentSrc,
        message: event.type,
        pathName: window.location.pathname,
      });
    }
  },
  true,
);

// --- Override the console.error function ---
// Store the original console.error function
const originalConsoleError = console.error; // eslint-disable-line no-console
// eslint-disable-next-line no-console
console.error = (template, kind, type, stack) => {
  let output;
  // Case: manually throw console.error(error);
  if (!template && !kind && !type && !stack) {
    output = 'console.error() was called without any arguments';

    logError({
      errorInfo: output,
      source: 'console.error',
      method: 'look at errorInfo',
      message: 'look at errorInfo',
      pathName: window.location.pathname,
    });
  }

  // Case: Dynamically catch console errors
  if (kind && type && stack) {
    output = template.replace('%s', kind).replace('%s', type).replace('%s', stack);

    logError({
      errorInfo: output,
      source: 'console.error',
      method: kind,
      message: type,
      pathName: window.location.pathname,
    });
  }

  // Case: catch console errors with only one argument
  if (!output && (kind || type || stack)) {
    output = template;
    if (kind || type || stack) {
      output = output.replace('%s', kind || type || stack);
    }

    logError({
      errorInfo: output,
      source: 'console.error',
      method: 'look at errorInfo',
      message: 'look at errorInfo',
      pathName: window.location.pathname,
    });
  }

  originalConsoleError.call(console, output);
};

// --- Capture errors during user interactions ---
window.onerror = (message, source, lineno, colno, errorInfo) => {
  logError({
    message,
    source,
    errorInfo,
    method: 'window.onerror',
    pathName: window.location.pathname,
    lineno,
    colno,
  });
};
