import React, { useState } from 'react';
import { Chip, IconButton, ListItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { number, shape, string, func } from 'prop-types';
import convertCentToDollar from 'utils/convertCentToDollar';
import pendingPaymentUtils from 'utils/pendingPaymentUtils';
import DeleteIcon from '@mui/icons-material/Delete';
import { useCart, useSetCart } from 'contexts';
import TextLoader from 'components/TextLoader';
import { copyText } from 'language';

const PendingPayment = ({ payment, setDisableRepeat, setShowVoidError, setShowCancelWarning }) => {
  const cart = useCart();
  const setCart = useSetCart();
  const [loading, setLoading] = useState(false);
  return (
    <ListItem
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        mb: 1,
      }}
    >
      <Box>
        <Typography>
          {payment.paymentType} {payment.paymentType !== 'CASH' && `XXXX${payment.last4}`}
        </Typography>
        <Typography variant="subtitle2">
          {copyText.App.id}: {payment.paymentId ?? payment.chargeId}
        </Typography>
        <Typography variant="subtitle2">
          {copyText.App.provider}: {payment.paymentProvider}
        </Typography>
        <Typography variant="subtitle2">
          {copyText.Cart.CheckoutButtons.authorizedAmount}: {convertCentToDollar(payment.amount)}
        </Typography>
      </Box>
      <Box>
        <Chip label={payment.state} />
        <TextLoader loading={loading} text="" size={20} />
        {!loading && cart?.cartState === 'Active' && (
          <IconButton
            sx={{ ml: 1, fontSize: '10px' }}
            size="small"
            onClick={() =>
              pendingPaymentUtils.cancelSinglePayment({
                payment,
                setCart,
                cartId: cart.id,
                setLoading,
                setDisableRepeat,
                setShowVoidError,
                setShowCancelWarning,
              })
            }
          >
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </ListItem>
  );
};

PendingPayment.propTypes = {
  payment: shape({
    paymentType: string.isRequired,
    state: string.isRequired,
    amount: number.isRequired,
    last4: string,
  }),
  setDisableRepeat: func.isRequired,
  setShowVoidError: func.isRequired,
  setShowCancelWarning: func.isRequired,
};
PendingPayment.defaultProps = {
  payment: {
    last4: null,
  },
};

export default PendingPayment;
