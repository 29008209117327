import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  IconButton,
  Snackbar,
} from '@mui/material';
import copyText from 'language/enUS';
import { lang } from 'language';
import { useEffect, useState } from 'react';
import convertCentToDollar from 'utils/convertCentToDollar';
import { useOktaAuth } from '@okta/okta-react';
import logo from 'media/images/purple-logo.svg';
import { arrayOf, bool, func, number, shape, string } from 'prop-types';
import TextLoader from 'components/TextLoader';
import PickListItem from 'components/OrderSummaryCard/PickListItem';
import CloseIcon from '@mui/icons-material/Close';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import utils from './utils';

const SplitPaymentReceiptModal = ({ open, order, closeModal, showHeader }) => {
  const [address, setAddress] = useState('');
  const [storeName, setStoreName] = useState('');
  const [shippingTotals, setShippingTotals] = useState('');
  const [taxTotals, setTaxTotals] = useState('');
  const [totalPrice, setTotalPrice] = useState('');
  const [promoCodeDiscounts, setPromoCodeDiscounts] = useState([]);
  const [recyclingFeeTotal, setRecyclingFeeTotal] = useState(null);
  const [date, setDate] = useState('');
  const [message, setMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState('');
  const [payments, setPayments] = useState([]);
  const { oktaAuth } = useOktaAuth();

  const receiptStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    m: 0,
    mt: 2,
    p: 0,
  };

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => utils.handleSnackbarClose({ setOpenSnackbar, setMessage })}
    >
      <CloseIcon />
    </IconButton>
  );

  useEffect(() => {
    utils.populateStoreData({ order, setAddress, setStoreName });
    utils.getPromoCodeDiscounts({ order, setPromoCodeDiscounts });
    setShippingTotals(convertCentToDollar(financialCalculators.getShippingTotals(order)));
    setTaxTotals(convertCentToDollar(financialCalculators.getTax(order)));
    setTotalPrice(convertCentToDollar(financialCalculators.getTotalPrice(order)));
    utils.getAgent({ oktaAuth, setAgentName });
    setRecyclingFeeTotal(convertCentToDollar(financialCalculators.getRecyclingFee(order)));
    utils.populateTime({ setDate, order });
    utils.getPayments({ order, setPayments });
  }, [order]);

  const formatDialogTitle = () => {
    return (
      <DialogTitle>
        {showHeader && (
          <Typography variant="h5" sx={{ displayPrint: 'none' }}>
            {copyText.Cart.PaymentResponse.orderCreated}!
          </Typography>
        )}
        <Box display="none" displayPrint="block">
          <img src={logo} alt={copyText.Header.logo.alt} style={{ height: '3rem' }} />
        </Box>
      </DialogTitle>
    );
  };
  useEffect(() => {
    utils.populateTotalPrice({ order, setTotalPrice });
  }, []);
  return (
    <Dialog scroll="body" open={open} fullWidth>
      {formatDialogTitle()}
      <DialogContent
        sx={{
          textAlign: 'center',
          alignContent: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h6">{storeName ?? ''}</Typography>
        <Typography>{address?.streetName ?? ''}</Typography>
        <Typography>{address?.streetNumber ?? ''}</Typography>
        <Typography>
          {`${address?.city ?? ''},
                  ${address?.state ?? ''}`}
        </Typography>
        <Typography marginTop={4} variant="h6">
          {copyText.App.orderNumber} {order?.orderNumber}
        </Typography>
        <List>
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="left">
              {copyText.Cart.receipt.purchaseItems}
            </Typography>
            <Typography variant="p" component="strong" align="right">
              {copyText.App.price}
            </Typography>
          </ListItem>
          <Divider />

          {order?.lineItems?.map((lineItem) => {
            return (
              <ListItem key={lineItem?.id} sx={receiptStyles}>
                <Box>
                  <Typography variant="p" component="strong" align="left">
                    {lineItem?.name[lang]}
                  </Typography>
                  {utils.getShippingMethod({ lineItem, order }) === 'Pick Up' ? (
                    <PickListItem lineItem={lineItem} order={order} />
                  ) : (
                    <>
                      <Typography align="left" display="flex">
                        {lineItem?.name[lang]} x {lineItem?.quantity}
                      </Typography>
                      <Typography display="flex" align="left">
                        {utils.getShippingMethod({ lineItem, order })}
                      </Typography>
                    </>
                  )}
                  <Typography variant="p">
                    {copyText.Cart.receipt.sku}: {lineItem?.variant?.sku}
                  </Typography>
                </Box>
                <Typography align="right">{utils.getLineItemPrice(lineItem)}</Typography>
              </ListItem>
            );
          })}
          <Divider sx={{ mt: 2 }} />
          {/* --- Gift Cards --- */}
          {order?.giftCards?.map((giftCard) => {
            return (
              <ListItem sx={receiptStyles} key={giftCard?.giftCardNumber}>
                <Typography variant="p" align="left">
                  {copyText.Cart.receipt.giftCardXX}
                  {utils.getGiftCardNumber(giftCard)}
                </Typography>
                <Typography align="right">-{utils.getGiftCardAmount(giftCard)}</Typography>
              </ListItem>
            );
          })}

          {/* --- Discount Codes --- */}
          {promoCodeDiscounts?.map((discountCode) => {
            return (
              <ListItem key={discountCode?.id} sx={receiptStyles}>
                <Box>
                  <Typography variant="p" component="strong">
                    {discountCode?.method}
                  </Typography>
                  <Typography>{discountCode?.displayName}</Typography>
                  <Typography>{discountCode?.couponCode}</Typography>
                </Box>
                <Typography>-{discountCode?.centAmount}</Typography>
              </ListItem>
            );
          })}

          {payments?.map((payment) => {
            return (
              <ListItem sx={receiptStyles} key={payment.externalPaymentId}>
                <Typography variant="p" align="left">
                  {payment.paymentType} {copyText.Cart.receipt.endingIn}
                  {payment.last4}
                </Typography>
                <Typography variant="p" align="right">
                  {convertCentToDollar(payment.amount)}
                </Typography>
              </ListItem>
            );
          })}
          <ListItem sx={receiptStyles}>
            <Typography align="left">{copyText.Cart.CartTools.shipping}</Typography>
            <Typography align="right">{shippingTotals}</Typography>
          </ListItem>
          {recyclingFeeTotal && (
            <ListItem sx={{ p: 0, justifyContent: 'space-between' }}>
              <Typography>{copyText.Cart.CartTools.recyclingFee}</Typography>
              <Typography align="right">{recyclingFeeTotal}</Typography>
            </ListItem>
          )}
          <ListItem sx={receiptStyles}>
            <Typography align="left">{copyText.Cart.CartTools.tax}</Typography>
            <Typography align="right">{taxTotals}</Typography>
          </ListItem>
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="left">
              {copyText.Cart.receipt.totalCaps}
            </Typography>
            <Typography variant="p" component="strong" align="right">
              {totalPrice}
            </Typography>
          </ListItem>
          <Divider sx={{ mt: 2 }} />
          <ListItem sx={receiptStyles}>
            <Typography variant="p" component="strong" align="right">
              {copyText.Cart.receipt.soldTo}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              marginTop: 2,
              padding: 0,
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
              <Typography variant="p" component="strong" fontSize={20}>
                {`${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`}
              </Typography>
              <Typography fontSize={14}>{order?.shippingAddress?.streetName}</Typography>
              <Typography fontSize={14}>{order?.shippingAddress?.additionalStreetInfo}</Typography>
              <Typography variant="p" fontSize={14}>
                {`${order?.shippingAddress?.city}, ${order?.shippingAddress?.state} ${order?.shippingAddress?.postalCode}`}
              </Typography>
            </Box>
            <Box
              marginTop={4}
              marginBottom={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography variant="p" component="strong">
                {copyText.App.date}: {date}
              </Typography>
              <Typography variant="p" component="strong">
                {copyText.Cart.receipt.soldBy}: {agentName}
              </Typography>
            </Box>
          </ListItem>
          <Divider />
          <ListItem
            sx={{
              display: 'flex',
              flexDirection: 'column',
              margin: 0,
              marginTop: 2,
              padding: 0,
            }}
          >
            <Box
              marginTop={2}
              marginBottom={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <Typography>{copyText.App.returnWarrantyPolicy}</Typography>
              <Typography>{copyText.App.pleaseVisitUrl}</Typography>
              <Typography>{copyText.App.returnsURL}</Typography>
            </Box>
            <Divider />

            <Typography variant="p" component="strong">
              {copyText.App.customerSupportCenter}
            </Typography>
            <Typography variant="p" component="strong">
              {copyText.App.customerSupportPhone}
            </Typography>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box display="flex">
          <Button
            sx={{ display: 'block', displayPrint: 'none' }}
            onClick={() =>
              utils.printThermalReceipt({
                setLoading,
                setMessage,
                setOpenSnackbar,
                order,
              })
            }
          >
            <TextLoader
              loading={loading}
              text={copyText.Orders.Actions.printThermalReceipt}
              size={20}
            />
          </Button>
        </Box>
        <Box display="flex">
          <Button
            variant="outlined"
            sx={{ display: 'block', displayPrint: 'none', mr: 2 }}
            onClick={closeModal}
          >
            {copyText.App.close}
          </Button>
          <Button
            variant="contained"
            sx={{ display: 'block', displayPrint: 'none' }}
            onClick={() => window.print()}
          >
            {copyText.App.print}
          </Button>
        </Box>
      </DialogActions>
      <Snackbar
        sx={{ color: 'primary' }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={message}
        action={action}
      />
    </Dialog>
  );
};

SplitPaymentReceiptModal.propTypes = {
  showHeader: bool,
  open: bool.isRequired,
  order: shape({
    customLineItems: arrayOf(shape({})).isRequired,
    taxedPrice: shape({
      totalGross: shape({
        centAmount: number.isRequired,
      }).isRequired,
      totalNet: shape({
        centAmount: number.isRequired,
      }).isRequired,
    }).isRequired,
    lineItems: arrayOf(shape({})).isRequired,
    orderNumber: string.isRequired,
    createdAt: string.isRequired,
  }).isRequired,
  closeModal: func.isRequired,
};

SplitPaymentReceiptModal.defaultProps = {
  showHeader: true,
};

export default SplitPaymentReceiptModal;
