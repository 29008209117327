import { Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { copyText } from 'language';
import { useEffect, useState } from 'react';
import { func, shape, string, number } from 'prop-types';
import { useCart, useSetCart } from 'contexts';
import ShippingAddressForm from './ShippingAddressForm';
import SendApplication from './SendApplication';
import chargeAfterUtils from '../chargeAfterUtils';

const ChargeAfterApply = ({
  applicationDetails,
  setApplicationDetails,
  state,
  setState,
  setErrorMessage,
  errorMessage,
  setOpenSnackbar,
  setSnackbarMessage,
  activeStep,
  setActiveStep,
}) => {
  const [loading, setLoading] = useState(false);
  const cart = useCart();
  const setCart = useSetCart();
  useEffect(() => {
    if (state === 'initial-verified') {
      setActiveStep(1);
    }
  }, [state]);
  return (
    <Stepper activeStep={activeStep} orientation="vertical">
      <Step>
        <StepLabel>{copyText.Cart.CartTools.ChargeAfter.verifyContactInfoAndShipping}</StepLabel>
        <StepContent>
          <ShippingAddressForm
            loading={loading}
            applicationDetails={applicationDetails}
            setApplicationDetails={setApplicationDetails}
            proceed={() =>
              chargeAfterUtils.handleUpdateState({
                setErrorMessage,
                cartId: cart?.id,
                setLoading,
                setCart,
                setActiveStep,
                newStep: 1,
                newState: 'initial-verified',
                setState,
              })
            }
          />
        </StepContent>
      </Step>
      <Step>
        <StepLabel>{copyText.Cart.CartTools.ChargeAfter.createApplication}</StepLabel>

        <StepContent>
          <SendApplication
            loading={loading}
            setLoading={setLoading}
            goBack={() =>
              chargeAfterUtils.handleUpdateState({
                setErrorMessage,
                cartId: cart?.id,
                setCart,
                setState,
                setLoading,
                setActiveStep,
                newStep: 0,
                newState: 'initial',
              })
            }
            setState={setState}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            applicationDetails={applicationDetails}
            setOpenSnackbar={setOpenSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setApplicationDetails={setApplicationDetails}
          />
        </StepContent>
      </Step>
    </Stepper>
  );
};

ChargeAfterApply.propTypes = {
  applicationDetails: shape({}).isRequired,
  setApplicationDetails: func.isRequired,
  state: string.isRequired,
  setState: func.isRequired,
  setErrorMessage: func.isRequired,
  errorMessage: string.isRequired,
  setOpenSnackbar: func.isRequired,
  setSnackbarMessage: func.isRequired,
  activeStep: number.isRequired,
  setActiveStep: func.isRequired,
};

export default ChargeAfterApply;
