import { CircularProgress, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { string } from 'prop-types';
import orders from 'dataAccess/api/orders.ts';
import React, { useEffect, useState } from 'react';
import { copyText } from 'language';
import returnsAndExchanges from 'utils/returnsAndExchanges';
import ExchangeItem from './ExchangeItem';

const ExchangeItems = ({ orderId }) => {
  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [originalorderNumber, setOriginalOrderNumber] = useState('');
  const [originalOrder, setOriginalOrder] = useState({});
  const getOriginalOrder = async () => {
    const result = await orders.getOrderById(orderId);
    return result;
  };

  const populateOriginalOrder = async () => {
    const order = await getOriginalOrder();
    setOriginalOrder(order);
  };
  const getOriginalLineItems = async () => {
    const order = await getOriginalOrder();
    setOriginalOrderNumber(originalOrder.orderNumber);
    setLineItems(returnsAndExchanges.checkLineItemAvailability(order));
  };

  useEffect(() => {
    populateOriginalOrder();
    getOriginalLineItems();
  }, []);

  return (
    <>
      <Box sx={{ mb: 1, mt: 4 }} display="flex" alignItems="center">
        <Typography variant="h5" sx={{ mr: 2 }}>
          #{originalorderNumber} {copyText.Orders.Actions.exchangeItems}
        </Typography>
        {loading && <CircularProgress size={20} />}
      </Box>
      <Divider />
      {lineItems?.map((lineItem) => {
        return (
          <ExchangeItem
            lineItem={lineItem}
            lineItems={lineItems}
            loading={loading}
            setLoading={setLoading}
            originalOrder={originalOrder}
          />
        );
      })}
    </>
  );
};

ExchangeItems.propTypes = {
  orderId: string.isRequired,
};

export default ExchangeItems;
