import { useEffect, useState } from 'react';
import { bool, func, shape } from 'prop-types';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';

import { copyText } from 'language';
import usStateCode2Char from 'utils/UsStateCodes';
import TextLoader from 'components/TextLoader';
import { formatPhoneNumber } from 'utils/formFieldFormatting';

import chargeAfterUtils from '../chargeAfterUtils';

const ShippingAddressForm = ({ applicationDetails, setApplicationDetails, proceed, loading }) => {
  const [validApplicationDetails, setValidApplicationDetails] = useState(false);
  const [invalidField, setInvalidField] = useState([]);
  const [invalidFieldMessage, setInvalidFieldMessage] = useState('');

  useEffect(() => {
    setValidApplicationDetails(chargeAfterUtils.validateAppDetails(applicationDetails));
  }, [applicationDetails]);

  return (
    <>
      <Grid
        container
        columns={16}
        mt={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={16} sm={7.5}>
          <TextField
            data-testid="ca-shipping-address-form-first-name"
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.firstName}
            value={applicationDetails?.firstName}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                item: 'firstName',
                change: e.target.value,
              })
            }
          />
        </Grid>
        <Grid item xs={16} sm={7.5}>
          <TextField
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.lastName}
            value={applicationDetails?.lastName}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                item: 'lastName',
                change: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={16} mt={3} display="flex" justifyContent="space-between">
        <Grid item xs={16} sm={7.5}>
          <TextField
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.email}
            value={applicationDetails?.email}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                item: 'email',
                change: e.target.value,
                setInvalidField,
                setInvalidFieldMessage,
              })
            }
            error={invalidField.includes('email')}
            helperText={invalidField.includes('email') ? invalidFieldMessage : ''}
          />
        </Grid>
        <Grid item xs={16} sm={7.5}>
          <TextField
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.phoneNumber}
            value={applicationDetails?.mobilePhoneNumber}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                item: 'mobilePhoneNumber',
                change: formatPhoneNumber(e.target.value),
              })
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={16} mt={3}>
        <Grid item xs={16}>
          <TextField
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.line1}
            value={applicationDetails?.shippingAddress?.line1}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                section: 'shippingAddress',
                item: 'line1',
                change: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={16} mt={3}>
        <Grid item xs={16}>
          <TextField
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.line2}
            value={applicationDetails?.shippingAddress?.line2}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                section: 'shippingAddress',
                item: 'line2',
                change: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={16} mt={3}>
        <Grid item xs={16}>
          <TextField
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.city}
            value={applicationDetails?.shippingAddress?.city}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                section: 'shippingAddress',
                item: 'city',
                change: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid container columns={16} mt={3} display="flex" justifyContent="space-between">
        <Grid item xs={16} sm={7.5}>
          <FormControl fullWidth sx={{ alignItems: 'center' }}>
            <InputLabel id="app-state-select">{copyText.Cart.CartTools.state}</InputLabel>
            <Select
              size="small"
              labelId="app-state-select"
              value={applicationDetails?.shippingAddress?.state}
              label={copyText.Cart.CartTools.state}
              placeholder={copyText.Cart.CartTools.state}
              fullWidth
            >
              {usStateCode2Char.map((code) => {
                return (
                  <MenuItem
                    onClick={() =>
                      chargeAfterUtils.updateApplication({
                        setApplicationDetails,
                        section: 'shippingAddress',
                        item: 'state',
                        change: code,
                      })
                    }
                    value={code}
                    key={code}
                  >
                    {code}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>{' '}
        </Grid>
        <Grid item xs={16} sm={7.5}>
          <TextField
            size="small"
            fullWidth
            placeholder={copyText.Cart.CartTools.ChargeAfter.postalCode}
            value={applicationDetails?.shippingAddress?.zipCode}
            onChange={(e) =>
              chargeAfterUtils.updateApplication({
                setApplicationDetails,
                section: 'shippingAddress',
                item: 'zipCode',
                change: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>
      <Grid container display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Grid item xs={16} display="flex" justifyContent="center">
          <Button
            variant="contained"
            disabled={loading || !validApplicationDetails || invalidField.length > 0}
            onClick={proceed}
          >
            <TextLoader
              loading={loading}
              size={20}
              text={copyText.Cart.CartTools.ChargeAfter.correctInfo}
            />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

ShippingAddressForm.propTypes = {
  loading: bool.isRequired,
  applicationDetails: shape({}).isRequired,
  setApplicationDetails: func.isRequired,
  proceed: func.isRequired,
};

export default ShippingAddressForm;
