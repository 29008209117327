import paymentService from 'dataAccess/api/payments.ts';
import cartService from 'dataAccess/api/cart.ts';
import copyText from 'language/enUS';

const cancelSinglePayment = async ({
  payment,
  setCart,
  cartId,
  setLoading,
  setDisableRepeat,
  setShowVoidError,
  setShowCancelWarning,
}) => {
  setDisableRepeat(true);
  setLoading(true);
  try {
    const response = await paymentService.voidPayment({
      paymentId: payment.paymentId,
      cartId,
      paymentProvider: payment.paymentProvider,
    });

    if (response?.value?.error) {
      throw new Error(copyText.Cart.CheckoutButtons.failedToVoidPayments);
    }
    const updatedCart = await cartService.getCart(cartId);
    setCart(updatedCart.data);
    setDisableRepeat(false);
    setLoading(false);
  } catch {
    setShowVoidError(true);
    setShowCancelWarning(true);
    setLoading(false);
  }
};

export default { cancelSinglePayment };
