import clover from 'dataAccess/api/clover.ts';
import copyText from 'language/enUS';
import cloverUtils from 'utils/clover';
import financialCalculators from 'utils/financialCalculators/financialCalculators';
import orderCreationUtils from 'utils/orderCreationUtils';
import { createCart } from 'utils/cart';
import { addCartToHistory, updateProcessedCart } from 'utils/cartHistory';
import cartService from 'dataAccess/api/cart.ts';
import paymentService from 'dataAccess/api/payments.ts';
import orders from 'dataAccess/api/orders.ts';
import errCodes from 'utils/errCodes';
import convertDollarToCent from 'utils/convertDollarToCent';

function handleCancelWarning({ cart, payments, setShowCancelWarning, setShowCheckout }) {
  if (cart?.cartState === 'Active' && payments.length > 0) {
    setShowCancelWarning(true);
  } else {
    setShowCheckout(false);
  }
}

function setCartAndPayments({ updatedCart, setCart, setPayments, setStatus }) {
  if (updatedCart) {
    const pendingPayments = updatedCart?.data?.custom?.fields?.pending_payments;

    if (pendingPayments) {
      const pendingPaymentsArray = JSON.parse(pendingPayments);
      setPayments(Object.values(pendingPaymentsArray));
    }
    setCart(updatedCart.data);
    setStatus(copyText.Cart.CheckoutButtons.cartUpdated);
  } else {
    setStatus(copyText.Cart.CheckoutButtons.paymentReceivedUpdatingCart);
    throw new Error(copyText.Cart.CheckoutButtons.failedToFindPendingPayments);
  }
}

async function handleSuccessfulPayment({ payment, cart, setCart, setPayments, setStatus }) {
  try {
    const result = await financialCalculators.updateCartWithPendingPayment(payment, cart);
    if (!result?.error) {
      setCartAndPayments({ updatedCart: result, setCart, setPayments, setStatus });
    } else {
      throw new Error();
    }
    // eslint-disable-next-line no-use-before-define
  } catch (err) {
    throw new Error(copyText.Cart.CheckoutButtons.failedToFindPendingPayments);
  }
}

async function handlePaymentResponse({
  payment,
  setStatus,
  setCart,
  setPayments,
  cart,
  setMessage,
}) {
  if (payment) {
    switch (payment.status) {
      case 200: {
        await handleSuccessfulPayment({
          payment: payment?.data?.payment,
          setCart,
          setPayments,
          setStatus,
          cart,
        });

        await clover.displayWelcome({ setMessage });
        break;
      }
      case 209: {
        setStatus(copyText.Cart.CheckoutButtons.paymentCancelled);
        setTimeout(async () => {
          setStatus(copyText.Cart.CheckoutButtons.readyForPayment);
          await clover.displayWelcome({ setMessage });
        }, 3000);
        break;
      }
      case 400: {
        setTimeout(async () => {
          setStatus(copyText.Cart.CheckoutButtons.readyForPayment);
          await clover.displayWelcome({ setMessage });
        }, 3000);
        break;
      }
      default: {
        setStatus(copyText.Cart.CheckoutButtons.unknownPaymentError);
        break;
      }
    }
  } else {
    setStatus(copyText.Cart.CheckoutButtons.unknownPaymentError);
    throw new Error(copyText.Cart.CheckoutButtons.unknownPaymentError);
  }
}

async function processPayment({
  cart,
  paymentAmount,
  setStatus,
  setLoading,
  setCart,
  setPayments,
  setShowOrderCreationFailure,
  setShowCancelWarning,
}) {
  try {
    setLoading(true);
    setShowCancelWarning(false);
    setShowOrderCreationFailure(false);
    if (!cart || !paymentAmount) {
      throw new Error(copyText.Cart.CheckoutButtons.unknownPaymentError);
    }
    if (cloverUtils.tokenExpired(cloverUtils.getCloverAccessToken())) {
      await cloverUtils.refreshCloverToken({ setMessage: setStatus });
    }
    setStatus(copyText.Cart.CheckoutButtons.awaitingResponse);

    const payment = convertDollarToCent(paymentAmount);
    const result = await clover.paymentRequest({
      cartId: cart.id,
      paymentTotal: payment,
      manualEntry: true,
      splitPayments: true,
    });

    await handlePaymentResponse({
      payment: result,
      setStatus,
      setPayments,
      setCart,
      cart,
      setMessage: setStatus,
    });
  } catch (err) {
    setStatus(err.message);
  } finally {
    setLoading(false);
  }
}

const handleError = async ({ setShowOrderCreationFailure, error, setOrderCreationError }) => {
  setShowOrderCreationFailure(true);
  setOrderCreationError(error);
};

const clearUnprocessedCart = async ({
  setCart,
  setLoading,
  setShow,
  setCustomPayment,
  setShowCancelWarning,
  setShowVoidError,
  salesAssociateId,
}) => {
  setLoading(true);
  try {
    const newCart = await createCart(salesAssociateId);
    setCart(newCart);
    addCartToHistory(newCart);
  } catch (err) {
    throw new Error(err.message);
  }
  setLoading(false);
  setShow(false);
  setCustomPayment(false);
  setShowCancelWarning(false);
  setShowVoidError(false);
};

const handleCancel = async ({
  setShowCheckout,
  setVoidLoading,
  setCustomPayment,
  setShowCancelWarning,
  setShowVoidError,
  setCart,
  cart,
  setShowPendingPaymentWarning,
}) => {
  setVoidLoading(true);
  const pendingPayments = financialCalculators.getPendingPayments(cart);
  if (pendingPayments && cart.cartState === 'Active') {
    try {
      const paymentKeys = Object.keys(pendingPayments);
      const responses = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const paymentKey of paymentKeys) {
        // eslint-disable-next-line no-await-in-loop
        const response = await paymentService.voidPayment({
          paymentId: pendingPayments[paymentKey].paymentId ?? paymentKey,
          cartId: cart.id,
          paymentProvider: pendingPayments[paymentKey].paymentProvider,
        });
        responses.push(response);
      }
      const failedResponses = responses.filter((response) => {
        if (response?.value?.error) {
          return response;
        }
        return false;
      });
      if (failedResponses.length > 0) {
        throw new Error(copyText.Cart.CheckoutButtons.failedToVoidPayments);
      }
    } catch {
      setShowVoidError(true);
      setVoidLoading(false);
      return;
    }
  }

  const updatedCart = await cartService.getCart(cart.id);
  setCart(updatedCart.data);
  setVoidLoading(false);
  setShowVoidError(false);
  setShowCheckout(false);
  setCustomPayment(false);
  setShowCancelWarning(false);
  setShowPendingPaymentWarning(false);
};

const handleCustomAmountInput = ({
  setPaymentAmount,
  amount,
  setCheckoutDisabled,
  setCustomAmountError,
  remainingTotal,
}) => {
  setPaymentAmount(amount);
  const validAmount = financialCalculators.checkCustomAmount(amount, remainingTotal);
  setCheckoutDisabled(!validAmount);
  setCustomAmountError(!validAmount ? copyText.Cart.CheckoutButtons.invalidAmount : '');
};

const handlePayInFull = ({
  setCustomPayment,
  setCheckoutDisabled,
  setPaymentAmount,
  cart,
  payments,
}) => {
  if (cart && payments) {
    setPaymentAmount(financialCalculators.getUnpaidTotal(cart, payments) / 100);
    setCustomPayment(false);
    setCheckoutDisabled(false);
  }
};

const cloverValidation = ({ setShowMissingCloverWarning }) => {
  const cloverDevice = cloverUtils.getSessionCloverDevice();
  setShowMissingCloverWarning(!cloverDevice || cloverDevice === 'select');
};

const clearCart = async ({ setCart, setStatus, cart, setDisableRepeat, salesAssociateId }) => {
  try {
    updateProcessedCart(cart.id);
    const newCart = await createCart(salesAssociateId);
    setCart(newCart);
    setDisableRepeat(false);
    addCartToHistory(newCart);
  } catch (err) {
    setStatus(err.message);
  }
};

const handleCloseReceipt = ({ setShow, setShowReceipt }) => {
  setShow(false);
  setShowReceipt(false);
};

const handleSuccessfulOrderCreation = async ({
  setStatus,
  setShowReceipt,
  setNewOrder,
  setDisableRepeat,
  setCart,
  order,
  cart,
  salesAssociateId,
}) => {
  setStatus(copyText.Cart.CheckoutButtons.orderCreated);
  setNewOrder(order.data);
  setShowReceipt(true);
  try {
    await orderCreationUtils.sendSplitPaymentReceipts(order.data);
  } catch (err) {
    setStatus(copyText.Cart.CheckoutButtons.failedToPrintReceipt);
  }
  setDisableRepeat(true);
  await clearCart({ setCart, setStatus, cart, setDisableRepeat, salesAssociateId });
};

const handleOrderCreationFailure = async ({ error, setCart, setStatus, onError, cart }) => {
  setStatus(error?.response?.data?.errors[0]?.title);
  try {
    const updatedCart = await cartService.getCart(cart.id);
    if (updatedCart.data) {
      setCart(updatedCart.data);
    }
  } catch (err) {
    setStatus(copyText.Cart.CheckoutButtons.failedToUpdateCart);
  } finally {
    onError(error);
  }
};

const handleOrderCreation = async ({
  setLoadingOrder,
  setStatus,
  cart,
  setCart,
  setNewOrder,
  setDisableRepeat,
  setShowReceipt,
  onError,
  salesAssociateId,
}) => {
  try {
    setLoadingOrder(true);
    setStatus(copyText.Cart.CheckoutButtons.creatingOrder);
    const result = await orders.createOrderFromCartMultiplePayments(cart.id);
    if (result.status === 200) {
      handleSuccessfulOrderCreation({
        setStatus,
        setShowReceipt,
        setNewOrder,
        setDisableRepeat,
        order: result,
        cart,
        setCart,
        salesAssociateId,
      });
    } else {
      throw new Error(copyText.Cart.CheckoutButtons.orderCreationFailed);
    }
  } catch (err) {
    await handleOrderCreationFailure({ error: err, setCart, setStatus, onError, cart });
  } finally {
    setLoadingOrder(false);
  }
};

const getErrorAction = (errCode) => {
  const errorMessages = {
    [errCodes.NON_LEASABLE_ITEMS]: copyText.Cart.CheckoutButtons.errCodesNonLeasableItems,
    [errCodes.SHIPPING_METHOD_ERROR]: copyText.Cart.CheckoutButtons.errCodesShippingMethodError,
    [errCodes.NO_SHIPPING_ADDRESS]: copyText.Cart.CheckoutButtons.errCodesNoShippingAddress,
    [errCodes.GIFT_CARD_BALANCE_NOT_ENOUGH]: copyText.Cart.CheckoutButtons.errCodesGiftCardBalance,
    [errCodes.CT_PAYMENT_UPDATE_FAILURE]:
      copyText.Cart.CheckoutButtons.errCodesPaymentUpdateFailure,
    [errCodes.PAYMENT_CAPTURE_FAILURE]: copyText.Cart.CheckoutButtons.errCodesPaymentCaptureFailure,
    [errCodes.PAYMENT_RETRIEVAL_FAILURE]:
      copyText.Cart.CheckoutButtons.errCodesPaymentRetrievalFailure,
    [errCodes.CT_PAYMENT_UPDATED_FAILED_VOID_SUCCESS]:
      copyText.Cart.CheckoutButtons.errCodesPaymentCaptureFailureSuccessfulVoid,
  };

  return errorMessages[errCode] || copyText.Cart.CheckoutButtons.errCodesUnknownError;
};
const showCheckout = ({ setShow }) => {
  if (window.location.href.includes('pendingPayments=true')) {
    setShow(true);
  }
};

const parseChargeId = (errorDetails) => {
  const match = errorDetails.match(/chargeId:([^;]+)/);
  return match && match[1] ? match[1].trim() : null;
};

const parsePaymentId = (errorDetails) => {
  const match = errorDetails.match(/paymentId:([^;]+)/);
  return match && match[1] ? match[1].trim() : null;
};

const parseState = (errorDetails) => {
  const match = errorDetails.match(/state:([^;]+)/);
  return match && match[1] ? match[1].trim() : null;
};

const checkErrorResponseForVoid = async ({ cart, error, setCart, setMessage }) => {
  if (error?.response?.data?.errors[0]?.code === 'ORD-016') {
    try {
      // payment voided, CT needs to be updated
      const chargeId = parseChargeId(error?.response?.data?.errors[0]?.meta?.detail);
      const updatedCart = await paymentService.deletePendingPayment({ chargeId, cartId: cart?.id });
      setCart(updatedCart);
    } catch (err) {
      setMessage(err.message);
    }
  }
  if (error?.response?.data?.errors[0]?.code === 'ORD-012') {
    try {
      // payment couldn't be voided, update CT with captured payment details so that void or retry order creation is possible.
      const chargeId = parseChargeId(error?.response?.data?.errors[0]?.meta?.detail);
      const paymentId = parsePaymentId(error?.response?.data?.errors[0]?.meta?.detail);
      const state = parseState(error?.response?.data?.errors[0]?.meta?.detail);
      const updatedCart = await paymentService.updatePendingPayment({
        cartId: cart?.id,
        chargeId,
        paymentId,
        state,
      });
      setCart(updatedCart?.data);
    } catch (err) {
      setMessage(err.message);
    }
  }
};

export default {
  handleCancelWarning,
  setCartAndPayments,
  handleSuccessfulPayment,
  handlePaymentResponse,
  processPayment,
  handleError,
  handleCancel,
  handleCustomAmountInput,
  handlePayInFull,
  cloverValidation,
  checkErrorResponseForVoid,
  clearCart,
  clearUnprocessedCart,
  addCartToHistory,
  handleOrderCreation,
  handleSuccessfulOrderCreation,
  handleOrderCreationFailure,
  handleCloseReceipt,
  getErrorAction,
  showCheckout,
};
