import axios from 'dataAccess/middleware/axiosInstance';
import { logError } from 'utils/errorUtils';
import getAccessToken from 'utils/getAccessToken';

const getAgents = async () => {
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_PIM_BASE_URL}/okta/users?dept=402 Showrooms`;
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.get(uri, config);
    return response.data;
  } catch (error) {
    logError({
      method: 'getAgents',
      errorInfo: error,
      message: error?.message,
      source: 'api/attribution',
    });
    throw error;
  }
};
const setAgentAttribution = async (cartId: string, agentId: string) => {
  try {
    const oktaToken = getAccessToken();
    const uri = `${process.env.REACT_APP_CART_MS_URL}/carts/${cartId}/attribution`;
    const reqBody = { salesAssociateId: agentId };
    const config = {
      headers: {
        role: 'agent',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oktaToken}`,
      },
    };
    const response = await axios.post(uri, reqBody, config);

    return response.data;
  } catch (error) {
    logError({
      method: 'setAgentAttribution',
      errorInfo: error,
      message: error?.message,
      source: 'api/attribution',
    });
    throw error;
  }
};

export default {
  setAgentAttribution,
  getAgents,
};
