const getDescription = (variant) => {
  const desc = variant.attributes.find((attr) => attr.name === 'title');
  const colorDesc = variant.attributes.find((attr) => attr.name === 'color');
  let description = desc?.value ?? '';
  if (colorDesc && colorDesc.value && colorDesc?.value?.label) {
    description = `${desc?.value} | ${colorDesc?.value?.label}`;
  }
  return description;
};

export default {
  getDescription,
};
