import OrdersService from 'dataAccess/api/orders.ts';
import lineItemFormatters from 'utils/lineItems';
import { lang } from 'language';
import copyText from 'language/enUS';
import PaymentService from 'dataAccess/api/payments.ts';
import convertCentToDollar from './convertCentToDollar';

const calculateExchangeAmount = (order) => {
  let exchangeTotalAmount = 0;
  if (order?.custom?.fields?.is_exchange_order || order.is_exchange_order) {
    const exchangeLineItems = JSON.parse(
      order?.custom?.fields?.exchange_order_line_items || order.exchange_order_line_items,
    );
    exchangeTotalAmount = exchangeLineItems.reduce((acc, item) => {
      if (item?.exchangeValue?.centAmount) {
        return item.exchangeValue.centAmount + acc;
      }
      return acc;
    }, 0);
  }

  return exchangeTotalAmount;
};

const calculateOrderDiscounts = (order) => {
  let totalPromoCodeDiscounts = 0;
  order?.lineItems?.forEach((lineItem) => {
    if (lineItem.custom && lineItem.custom.fields && lineItem.custom.fields.discounts_json) {
      const parsedPromoCodeDiscounts = JSON.parse(lineItem.custom?.fields?.discounts_json);
      if (parsedPromoCodeDiscounts) {
        totalPromoCodeDiscounts = parsedPromoCodeDiscounts.reduce((acc, discount) => {
          if (discount?.cent_amount) {
            return discount.cent_amount + acc;
          }
          return acc;
        }, 0);
      }
    }
  });
  return totalPromoCodeDiscounts;
};

const calculateItemDiscounts = (item) => {
  const discounts = item?.custom?.fields?.discounts_json
    ? JSON.parse(item.custom?.fields?.discounts_json)
    : [];
  if (discounts.length > 0) {
    const result = discounts.reduce((acc, discount) => {
      return acc + discount.cent_amount;
    }, 0);
    return convertCentToDollar(result);
  }
  return convertCentToDollar(0);
};

const formatLineItems = (order) => {
  return order.lineItems.map((item) => {
    return {
      name: item.name[lang],
      qty: item.quantity,
      size: lineItemFormatters.getDescription(item?.variant),
      price: convertCentToDollar(item.price.value.centAmount),
      promo: calculateItemDiscounts(item),
      total: convertCentToDollar(item.totalPrice.centAmount),
      image: item.variant?.images[0]?.url,
      finalSale: item?.custom?.fields?.final_sale || false,
    };
  });
};

const getExchangeItemData = (exchangeItems, originalOrder) => {
  return exchangeItems.map((item) => {
    const ogItemData = originalOrder.lineItems.filter((ogItem) => {
      return ogItem.id === item.id;
    });
    if (ogItemData.length) {
      return {
        name: item.name,
        qty: item.quantity,
        size: lineItemFormatters.getDescription(ogItemData[0]?.variant),
        price: convertCentToDollar(ogItemData[0].price.value.centAmount),
        promo: calculateItemDiscounts(ogItemData[0]),
        total: convertCentToDollar(ogItemData[0].price.value.centAmount * item.quantity),
        image: ogItemData[0]?.variant?.images[0]?.url,
        finalSale: ogItemData[0]?.custom?.fields?.final_sale || false,
        floorModel: ogItemData[0]?.custom?.fields?.floor_model || false,
      };
    }
    return {
      name: item.name,
      qty: item.quantity,
      total: convertCentToDollar(item.exchangeValue.centAmount),
    };
  });
};

const calculateExchangeSubtotal = (exchangeItems, originalOrder) => {
  const total = exchangeItems.reduce((acc, item) => {
    const ogItemData = originalOrder.lineItems.filter((ogItem) => {
      return ogItem.id === item.id;
    });
    if (ogItemData[0]?.taxedPrice?.totalNet?.centAmount) {
      return acc + ogItemData[0].taxedPrice.totalNet.centAmount * ogItemData[0].quantity;
    }
    return acc;
  }, 0);
  return convertCentToDollar(total);
};

const calculateExchangeTaxTotal = (exchangeItems, originalOrder) => {
  const total = exchangeItems.reduce((acc, item) => {
    const ogItemData = originalOrder.lineItems.filter((ogItem) => {
      return ogItem.id === item.id;
    });
    if (ogItemData[0]?.taxedPrice) {
      return (
        acc +
        (ogItemData[0].taxedPrice.totalGross.centAmount -
          ogItemData[0].taxedPrice.totalNet.centAmount) *
          ogItemData[0].quantity
      );
    }
    return acc;
  }, 0);
  return convertCentToDollar(total);
};

const getPaymentInfo = async (order) => {
  // Retrieves the payment calls to get payment info.
  const payments = order?.paymentInfo?.payments || [];
  const paymentArray = payments.map(async (payment) => {
    return PaymentService.getPaymentById(payment.id);
  });
  // Retrieves the expanded payment data from ct, for each one of the payments.
  const paymentResponse = await Promise.all(paymentArray);
  const newPaymentArray = paymentResponse.map((payment) => payment.payment);
  return newPaymentArray;
};

const getLast4 = async (order) => {
  // SUNSET: (Adrian Rivera) We need to make this work with split transactions once they are working.
  const payments = await getPaymentInfo(order);
  return payments[0]?.custom?.fields?.cardlast4Numbers;
};

const checkIfEven = (newOrderTotal, exchangeCredit) => {
  if (newOrderTotal && exchangeCredit) {
    return newOrderTotal - exchangeCredit === 0;
  }
  return false;
};

const checkForRefund = (newOrderTotal, exchangeCredit) => {
  if (newOrderTotal && exchangeCredit) {
    return newOrderTotal - exchangeCredit < 0;
  }
  return false;
};

const checkForCharge = (newOrderTotal, exchangeCredit) => {
  if (newOrderTotal && exchangeCredit) {
    return newOrderTotal - exchangeCredit > 0;
  }
  return false;
};

const calculateExchangeOrderTotal = (newOrderTotal, exchangeCredit) => {
  if (newOrderTotal && exchangeCredit) {
    return convertCentToDollar(Math.abs(newOrderTotal - exchangeCredit));
  }
  return convertCentToDollar(0);
};

const calculateExchangeOrderTax = (order) => {
  return convertCentToDollar(
    order.taxedPrice.totalGross.centAmount - order.taxedPrice.totalNet.centAmount,
  );
};

const sendExchangeEmail = async (order, checkRefund = false) => {
  let exchangeItems =
    order?.custom?.fields?.exchange_order_line_items || order.exchange_order_line_items;
  exchangeItems = exchangeItems ? JSON.parse(exchangeItems) : [];
  const originalOrderNumber =
    order?.custom?.fields?.original_order_number || order?.original_order_number;
  const originalOrder = await OrdersService.getByOrderNumber(originalOrderNumber);
  const date = new Date(order?.createdAt).toDateString();
  const exchangeInfo = getExchangeItemData(exchangeItems, originalOrder.order);
  const exchangeCreditAmount = calculateExchangeAmount(order);
  const evenExchange = checkIfEven(order?.taxedPrice?.totalGross?.centAmount, exchangeCreditAmount);
  const hasRefund = checkForRefund(order?.taxedPrice?.totalGross?.centAmount, exchangeCreditAmount);
  const exchangeDue = checkForCharge(
    order?.taxedPrice?.totalGross?.centAmount,
    exchangeCreditAmount,
  );
  const last4Card = await getLast4(order);

  const emailBody = {
    agentEmail: order?.createdBy?.externalUserId,
    customerEmail: order?.customerEmail,
    originalOrderNumber:
      order?.custom?.fields?.original_order_number || order?.original_order_number,
    exchangeOrderNumber: order?.orderNumber,
    exchangeDate: date,
    exchangeItems: exchangeInfo,
    returnSubtotal: calculateExchangeSubtotal(exchangeItems, originalOrder.order),
    returnTaxes: calculateExchangeTaxTotal(exchangeItems, originalOrder.order),
    returnTotal: convertCentToDollar(exchangeCreditAmount),
    subtotal: convertCentToDollar(order?.totalPrice?.centAmount),
    orderTotal: convertCentToDollar(order?.taxedPrice?.totalGross?.centAmount),
    tax: calculateExchangeOrderTax(order),
    discounts: calculateOrderDiscounts(order),
    customerFirstName: order?.shippingAddress?.firstName,
    newItems: formatLineItems(order),
    evenExchange,
    exchangeDue,
    exchangeSubtotal: calculateExchangeOrderTotal(
      order?.taxedPrice?.totalGross?.centAmount,
      exchangeCreditAmount,
    ),
    exchangeTax: calculateExchangeOrderTax(order),
    exchangeTotal: exchangeCreditAmount,
    amountDue: calculateExchangeOrderTotal(
      order?.taxedPrice?.totalGross?.centAmount,
      exchangeCreditAmount,
    ),
    hasRefund,
    refundCheck: checkRefund,
    refundCard: checkRefund ? false : hasRefund,
    chargeCard: exchangeDue,
    storeKey: order?.store?.storeKey,
    customerAddress: `${order?.shippingAddress?.streetName} ${order?.shippingAddress?.city}, ${order?.shippingAddress?.state} ${order?.shippingAddress?.postalCode}`,
    customerName: `${order?.shippingAddress?.firstName} ${order?.shippingAddress?.lastName}`,
    last4Card,
  };

  const { status } = await OrdersService.sendExchangeEmail(order.id, emailBody);

  const message = status?.success
    ? copyText.Orders.EmailModal.emailSent
    : copyText.Orders.EmailModal.sendError;

  return message;
};

export default sendExchangeEmail;
