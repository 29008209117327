import { Close } from '@mui/icons-material';
import { Alert, Collapse, IconButton } from '@mui/material';
import { bool, string } from 'prop-types';
import React, { useState } from 'react';

const AppBanner = ({ isRemovable, message, severity }) => {
  const [open, setOpen] = useState(true);
  return (
    <Collapse in={open} sx={{ mb: 2 }}>
      <Alert
        severity={severity}
        action={
          isRemovable && (
            <IconButton onClick={() => setOpen(false)} color="inherit" size="small">
              <Close />
            </IconButton>
          )
        }
      >
        {message}
      </Alert>
    </Collapse>
  );
};

AppBanner.propTypes = {
  isRemovable: bool,
  message: string,
  severity: string,
};

AppBanner.defaultProps = {
  isRemovable: false,
  message: '',
  severity: 'warning',
};

export default AppBanner;
