const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const AUTH_ID = process.env.REACT_APP_OKTA_AUTH_ID;
const CALLBACK_PATH = '/login-okta';
const ISSUER = `https://onpurple.okta.com/oauth2/${AUTH_ID}`;

const HOST = window.location.host;
const PROTOCOL = window.location.protocol;
const REDIRECT_URI = `${PROTOCOL}//${HOST}${CALLBACK_PATH}`;
const TOKEN_MANAGER = {
  storage: 'sessionStorage',
  autoRenew: true,
};

const config = {
  issuer: ISSUER,
  clientId: CLIENT_ID,
  redirectUri: REDIRECT_URI,
  pkce: true,
  tokenManager: TOKEN_MANAGER,
};

export default config;
