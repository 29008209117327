import { Fade } from '@mui/material';
import { node } from 'prop-types';
import React, { useEffect, useState } from 'react';

const ViewTransition = ({ children }) => {
  const [showTransition, setShowTransition] = useState(false);

  useEffect(() => {
    setShowTransition(true);
  }, []);

  return (
    <Fade data-testid="view-transition-wrapper" in={showTransition} timeout={500}>
      {children}
    </Fade>
  );
};

ViewTransition.propTypes = {
  children: node.isRequired,
};

export default ViewTransition;
