import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import FullWidth from 'components/layouts/FullWidth';
import ScrollToTop from 'utils/scrollToTop';
import Page404 from 'views/Page404/Page404';
import { useSetSelectedShop, useIsOutlet } from 'contexts';

import Categories from './views/Categories/Categories';
import Products from './views/Products/Products';
// SUNSET: Remove Variants view.
// import Variants from './views/Variants/Variants';
import PDP from './views/NewPDP/PDP';

const OutletShop = () => {
  const location = useLocation();
  const { pathname } = location;
  const setSelectedShop = useSetSelectedShop();
  const isOutlet = useIsOutlet();
  const baseUrl = '/shop/outlet';

  useEffect(() => {
    setSelectedShop('outlet');
  }, []);

  /**
   * --- Hierarchy ---
   * Category -- "/shop/outlet"
   * |__ Products -- "/shop/outlet/category/:categoryId"
   * |   |__ PDP -- "/shop/outlet/category/:categoryId/product/:productId"
   */

  return (
    <FullWidth>
      <ScrollToTop>
        <Switch>
          {isOutlet && (
            <>
              <Route
                exact
                path={`${baseUrl}/category/:categoryId/product/:productId`}
                component={PDP}
              />
              {/* SUNSET: Remove Variants view. */}
              {/* <Route
                exact
                path={`${baseUrl}/category/:categoryId/product/:productId`}
                component={Variants}
              /> */}
              <Route exact path={`${baseUrl}/category/:categoryId`} component={Products} />
              <Route exact path={baseUrl} render={() => <Categories key={pathname} />} />
            </>
          )}
          <Route path="*" component={Page404} />
        </Switch>
      </ScrollToTop>
    </FullWidth>
  );
};

export default OutletShop;
