import React, { useState } from 'react';
import { MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { shape, func, arrayOf } from 'prop-types';

import { copyText } from 'language';
import { filterProductsByTitle } from '../Products.utils';

const ProductFilter = ({ products, setProducts }) => {
  const [filter, setFilter] = useState('all');

  const updateFilter = (searchValue) => {
    if (products) {
      const searchMap = {
        used: 'used',
        factorySeconds: 'factory second',
        retailOnly: 'retail',
        floorModels: 'floor model',
      };

      const filteredProducts = searchMap[searchValue]
        ? filterProductsByTitle({ products, searchValue: searchMap[searchValue] })
        : products;

      setProducts(filteredProducts);
      setFilter(searchValue);
    }
  };

  const handleChange = (event) => {
    const searchValue = event.target.value;
    updateFilter(searchValue);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="product-filter-label">{copyText.Shop.Products.filterProducts}</InputLabel>
      <Select
        labelId="product-filter-label"
        id="product-filter"
        value={filter}
        label={copyText.Shop.Products.filterProducts}
        onChange={handleChange}
      >
        <MenuItem value="all">{copyText.Shop.Products.filters.all}</MenuItem>
        <MenuItem value="retailOnly">{copyText.Shop.Products.filters.retailOnly}</MenuItem>
        <MenuItem value="used">{copyText.Shop.Products.filters.used}</MenuItem>
        <MenuItem value="factorySeconds">{copyText.Shop.Products.filters.factorySeconds}</MenuItem>
        <MenuItem value="floorModels">{copyText.Shop.Products.filters.floorModels}</MenuItem>
      </Select>
    </FormControl>
  );
};

ProductFilter.propTypes = {
  products: arrayOf(shape({})),
  setProducts: func.isRequired,
};

ProductFilter.defaultProps = {
  products: [],
};

export default ProductFilter;
