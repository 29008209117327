import orders from 'dataAccess/api/orders.ts';

const getOriginalOrder = async (orderId) => {
  const result = await orders.getOrderById(orderId);
  return result;
};

const getOriginalOrderShippingAddressEmail = async ({ orderId }) => {
  const order = await getOriginalOrder(orderId);
  return order?.shippingAddress?.email;
};

const checkForDiscountedDuplicateSkuInExchange = (
  lineItem,
  lineItems,
  setDisableExchangeItem,
  exchangeOrderLineItems,
  updateCartExchangeItems,
) => {
  const duplicateLineItem = lineItems?.find(
    (lItem) =>
      lItem?.variant?.sku === lineItem?.variant?.sku &&
      lItem?.id !== lineItem?.id &&
      lineItem?.taxedPrice?.totalGross?.centAmount > lItem?.taxedPrice?.totalGross?.centAmount,
  );
  const isDuplicateLineItemInExchangeLineItems = exchangeOrderLineItems?.find(
    (exchangeItem) => exchangeItem?.id === duplicateLineItem?.id,
  );
  if (isDuplicateLineItemInExchangeLineItems) {
    setDisableExchangeItem(false);
    return;
  }
  const duplicateLineItemHasNoneAvailable = duplicateLineItem?.numAvailable === 0;
  if (duplicateLineItemHasNoneAvailable) {
    setDisableExchangeItem(false);
    return;
  }
  const isExchangeItemInExchangeLineItems = exchangeOrderLineItems?.find(
    (exchangeItem) => exchangeItem?.id === lineItem?.id,
  );
  if (
    duplicateLineItem &&
    !isDuplicateLineItemInExchangeLineItems &&
    isExchangeItemInExchangeLineItems
  ) {
    updateCartExchangeItems(lineItem);
  }
  if (duplicateLineItem) {
    setDisableExchangeItem(true);
    return;
  }
  setDisableExchangeItem(false);
};

export default {
  getOriginalOrder,
  getOriginalOrderShippingAddressEmail,
  checkForDiscountedDuplicateSkuInExchange,
};
