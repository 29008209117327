import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { node } from 'prop-types';

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

ScrollToTop.propTypes = {
  children: node.isRequired,
};

export default ScrollToTop;
