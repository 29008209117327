import axios from 'dataAccess/middleware/axiosInstance';
import { logError } from 'utils/errorUtils';
import formValidation from 'utils/formValidation';
import getAccessToken from 'utils/getAccessToken';

const { isValidPhoneNumber, isValidEmail } = formValidation;

const logChargeAfterDetails = async ({ cartId, chargeAfterDetails }) => {
  const hasRequiredParams = !!cartId;
  const oktaToken = getAccessToken();
  try {
    if (hasRequiredParams) {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/${cartId}/charge-after`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const body = {
        chargeAfterDetails,
      };

      const response = await axios.post(uri, body, config);
      // TODO: Revome this logger after debugging
      logError({
        method: 'logChargeAfterDetails',
        errorInfo: response,
        message: 'logChargeAfterDetails call was successful!',
        source: 'api/chargeAfer',
      });
      return response;
    }
    throw new Error('logChargeAfterDetails requires: cartId, chargeAfterDetails');
  } catch (error) {
    logError({
      method: 'logChargeAfterDetails',
      errorInfo: error,
      message: error?.message,
      source: 'api/chargeAfer',
    });
    throw error;
  }
};

const pollForAppComplete = async ({ cartId }) => {
  const hasRequiredParams = !!cartId;
  const oktaToken = getAccessToken();
  try {
    if (hasRequiredParams) {
      const uri = `${process.env.REACT_APP_MS_URL}/orders/${cartId}/charge-after/poll`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };
      const body = {
        sessionId: null,
        extraPayload: {
          multiPay: false,
        },
      };
      const response = await axios.post(uri, body, config);
      return response;
    }
    throw new Error('pollForAppComplete requires: cartId');
  } catch (error) {
    logError({
      method: 'pollForAppComplete',
      errorInfo: error,
      message: error?.message,
      source: 'api/chargeAfer',
    });
    throw error;
  }
};

const sendApplication = async ({ applicationDetails, cartId }) => {
  const hasRequiredParams = !!cartId;
  const oktaToken = getAccessToken();
  try {
    if (hasRequiredParams) {
      const uri = `${process.env.REACT_APP_MS_URL}/carts/charge-after/checkout`;
      const config = {
        headers: {
          role: 'agent',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${oktaToken}`,
        },
      };

      const mobilePhoneNumber = isValidPhoneNumber(applicationDetails?.mobilePhoneNumber)
        ? applicationDetails?.mobilePhoneNumber
        : undefined;
      const email = isValidEmail(applicationDetails?.email) ? applicationDetails?.email : undefined;
      const body = {
        sendUsing: applicationDetails.transmitMethod,
        cartId,
        channel: 'in_store',
        consumerDetails: {
          email,
          firstName: applicationDetails?.firstName,
          lastName: applicationDetails?.lastName,
          mobilePhoneNumber,
          shippingAddress: {
            country: 'US',
            line1: applicationDetails?.shippingAddress?.line1,
            line2: applicationDetails?.shippingAddress?.line2,
            city: applicationDetails?.shippingAddress?.city,
            state: applicationDetails?.shippingAddress?.state,
            zipCode: applicationDetails?.shippingAddress?.zipCode,
          },
        },
      };

      const response = await axios.post(uri, body, config);
      return response;
    }
    throw new Error('sendApplication requires: cartId');
  } catch (error) {
    logError({
      method: 'sendApplication',
      errorInfo: error,
      message: error?.message,
      source: 'api/chargeAfer',
    });
    throw error;
  }
};
export default {
  logChargeAfterDetails,
  pollForAppComplete,
  sendApplication,
};
